import React from 'react';
import PropTypes from 'prop-types';
import { HelpOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

function InfoIcon(props) {
  const { tooltip } = props;
  return (
    <Tooltip title={tooltip}>
      <HelpOutline color="primary" />
    </Tooltip>
  );
}

InfoIcon.propTypes = {
  tooltip: PropTypes.node.isRequired
};

export default InfoIcon;
