import React from 'react';

const Privacy = () => (
  <div id="imprint" className="container h-100-minusAppBar ">
    <div className="row">
      <div className="col-12 mt-3">
        <p>
          <strong>Datenschutzerklärung</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            Name und Kontakt des Verantwortlichen gemäß Artikel 4 Abs. 7 DSGVO
          </strong>
        </p>
        <p>Match-Flix GmbH</p>
        <p>Altstadt 26</p>
        <p>84028 Landshut</p>
        <p>
          Telefon: +49 871 430 888 44
          <br /> Telefax: +49 871 430 888 99
          <br /> E-Mail:&nbsp;
          <a href="mailto:support@match-flix.de">support@match-flix.de</a>
        </p>
        <p>
          <strong>Sicherheit und Schutz Ihrer personenbezogenen Daten</strong>
        </p>
        <p>
          Wir betrachten es als unsere vorrangige Aufgabe, die Vertraulichkeit
          der von Ihnen bereitgestellten personenbezogenen Daten zu wahren und
          diese vor unbefugten Zugriffen zu schützen. Deshalb wenden wir
          äußerste Sorgfalt und modernste Sicherheitsstandards an, um einen
          maximalen Schutz Ihrer personenbezogenen Daten zu gewährleisten.
        </p>
        <p>&nbsp;</p>
        <p>
          Als privatrechtliches Unternehmen unterliegen wir den Bestimmungen der
          europäischen Datenschutzgrundverordnung (DSGVO) und den Regelungen des
          Bundesdatenschutzgesetzes (BDSG). Wir haben technische und
          organisatorische Maßnahmen getroffen, die sicherstellen, dass die
          Vorschriften über den Datenschutz sowohl von uns, als auch von unseren
          externen Dienstleistern beachtet werden.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Begriffsbestimmungen</strong>
        </p>
        <p>
          Der Gesetzgeber fordert, dass personenbezogene Daten auf rechtmäßige
          Weise, nach Treu und Glauben und in einer für die betroffene Person
          nachvollziehbaren Weise verarbeitet werden („Rechtmäßigkeit,
          Verarbeitung nach Treu und Glauben, Transparenz“). Um dies zu
          gewährleisten, informieren wir Sie über die einzelnen gesetzlichen
          Begriffsbestimmungen, die auch in dieser Datenschutzerklärung
          verwendet werden:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>Personenbezogene Daten</strong>
          </li>
        </ol>
        <p>
          „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person (im Folgenden
          „betroffene Person“) beziehen; als identifizierbar wird eine
          natürliche Person angesehen, die direkt oder indirekt, insbesondere
          mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
          Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem
          oder mehreren besonderen Merkmalen identifiziert werden kann, die
          Ausdruck der physischen, physiologischen, genetischen, psychischen,
          wirtschaftlichen, kulturellen oder sozialen Identität dieser
          natürlichen Person sind.
        </p>
        <p>&nbsp;</p>
        <ol start="2">
          <li>
            <strong>Verarbeitung</strong>
          </li>
        </ol>
        <p>
          „Verarbeitung“ ist jeder, mit oder ohne Hilfe automatisierter
          Verfahren, ausgeführter Vorgang oder jede solche Vorgangsreihe im
          Zusammenhang mit personenbezogenen Daten wie das Erheben, das
          Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung
          oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
          Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
          Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
          das Löschen oder die Vernichtung.
        </p>
        <p>&nbsp;</p>
        <ol start="3">
          <li>
            <strong>Einschränkung der Verarbeitung</strong>
          </li>
        </ol>
        <p>
          „Einschränkung der Verarbeitung“ ist die Markierung gespeicherter
          personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung
          einzuschränken.
        </p>
        <p>&nbsp;</p>
        <ol start="4">
          <li>
            <strong>Profiling</strong>
          </li>
        </ol>
        <p>
          „Profiling“ ist jede Art der automatisierten Verarbeitung
          personenbezogener Daten, die darin besteht, dass diese
          personenbezogenen Daten verwendet werden, um bestimmte persönliche
          Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
          insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche
          Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit,
          Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person
          zu analysieren oder vorherzusagen.
        </p>
        <p>&nbsp;</p>
        <ol start="5">
          <li>
            <strong>Pseudonymisierung</strong>
          </li>
        </ol>
        <p>
          „Pseudonymisierung“ ist die Verarbeitung personenbezogener Daten in
          einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung
          zusätzlicher Informationen nicht mehr einer spezifischen betroffenen
          Person zugeordnet werden können, sofern diese zusätzlichen
          Informationen gesondert aufbewahrt werden und technischen und
          organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die
          personenbezogenen Daten nicht einer identifizierten oder
          identifizierbaren natürlichen Person zugewiesen werden können.
        </p>
        <p>&nbsp;</p>
        <ol start="6">
          <li>
            <strong>Dateisystem</strong>
          </li>
        </ol>
        <p>
          „Dateisystem“ ist jede strukturierte Sammlung personenbezogener Daten,
          die nach bestimmten Kriterien zugänglich sind, unabhängig davon, ob
          diese Sammlung zentral, dezentral oder nach funktionalen oder
          geografischen Gesichtspunkten geordnet geführt wird.
        </p>
        <p>&nbsp;</p>
        <ol start="7">
          <li>
            <strong>Verantwortlicher</strong>
          </li>
        </ol>
        <p>
          „Verantwortlicher“ ist eine natürliche oder juristische Person,
          Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten entscheidet; sind die Zwecke und Mittel dieser
          Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten
          vorgegeben, so können der Verantwortliche beziehungsweise die
          bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem
          Recht der Mitgliedstaaten vorgesehen werden.
        </p>
        <p>&nbsp;</p>
        <ol start="8">
          <li>
            <strong>Auftragsverarbeiter</strong>
          </li>
        </ol>
        <p>
          „Auftragsverarbeiter“ ist eine natürliche oder juristische Person,
          Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im
          Auftrag des Verantwortlichen verarbeitet.
          <br />{' '}
        </p>
        <ol start="9">
          <li>
            <strong>Empfänger</strong>
          </li>
        </ol>
        <p>
          „Empfänger“ ist eine natürliche oder juristische Person, Behörde,
          Einrichtung oder andere Stelle, denen personenbezogene Daten
          offengelegt werden, unabhängig davon, ob es sich bei ihr um einen
          Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten
          Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
          Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten
          jedoch nicht als Empfänger; die Verarbeitung dieser Daten durch die
          genannten Behörden erfolgt im Einklang mit den geltenden
          Datenschutzvorschriften gemäß den Zwecken der Verarbeitung.
        </p>
        <p>&nbsp;</p>
        <ol start="10">
          <li>
            <strong>Dritter</strong>
          </li>
        </ol>
        <p>
          „Dritter“ ist eine natürliche oder juristische Person, Behörde,
          Einrichtung oder andere Stelle, außer der betroffenen Person, dem
          Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter
          der unmittelbaren Verantwortung des Verantwortlichen oder des
          Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
          verarbeiten.
        </p>
        <p>&nbsp;</p>
        <ol start="11">
          <li>
            <strong>Einwilligung</strong>
          </li>
        </ol>
        <p>
          Eine „Einwilligung“ der betroffenen Person ist jede freiwillig für den
          bestimmten Fall, in informierter Weise und unmissverständlich
          abgegebene Willensbekundung in Form einer Erklärung oder einer
          sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene
          Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
          betreffenden personenbezogenen Daten einverstanden ist.
          <br />{' '}
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Rechtmäßigkeit der Verarbeitung</strong>
        </p>
        <p>
          Die Verarbeitung personenbezogener Daten ist nur rechtmäßig, wenn für
          die Verarbeitung eine Rechtsgrundlage besteht. Rechtsgrundlage für die
          Verarbeitung können gemäß Artikel 6 Abs. 1 <br /> lit. a – f DSGVO
          insbesondere sein:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            Die betroffene Person hat ihre Einwilligung zu der Verarbeitung der
            sie betreffenden personenbezogenen Daten für einen oder mehrere
            bestimmte Zwecke gegeben;
          </li>
          <li>
            die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
            betroffenen Person erfolgen;
          </li>
          <li>
            die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich, der der Verantwortliche unterliegt;
          </li>
          <li>
            die Verarbeitung ist erforderlich, um lebenswichtige Interessen der
            betroffenen Person oder einer anderen natürlichen Person zu
            schützen;
          </li>
          <li>
            die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich,
            die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
            Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
          </li>
          <li>
            die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
            Interessen oder Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten erfordern,
            überwiegen, insbesondere dann, wenn es sich bei der betroffenen
            Person um ein Kind handelt.
            <br />{' '}
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <strong>Information über die Erhebung personenbezogener Daten</strong>
        </p>
        <p>
          (1) Im Folgenden informieren wir über die Erhebung personenbezogener
          Daten bei Nutzung unserer Website. Personenbezogene Daten sind
          z.&thinsp;B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
        </p>
        <p>&nbsp;</p>
        <p>
          (2) Bei einer Kontaktaufnahme mit uns per E-Mail oder über ein
          Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre
          E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns
          gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang
          anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr
          erforderlich ist, oder die Verarbeitung wird eingeschränkt, falls
          gesetzliche Aufbewahrungspflichten bestehen.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            Erhebung personenbezogener Daten bei Besuch unserer Website
          </strong>
        </p>
        <p>
          Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich
          nicht registrieren oder uns anderweitig Informationen übermitteln,
          erheben wir nur die personenbezogenen Daten, die Ihr Browser an
          unseren Server übermittelt. Wenn Sie unsere Website betrachten
          möchten, erheben wir die folgenden Daten, die für uns technisch
          erforderlich sind, um Ihnen unsere Website anzuzeigen und die
          Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist
          Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f DSGVO):
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>IP-Adresse</li>
          <li>Datum und Uhrzeit der Anfrage</li>
          <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
          <li>Inhalt der Anforderung (konkrete Seite)</li>
          <li>Zugriffsstatus/HTTP-Statuscode</li>
          <li>jeweils übertragene Datenmenge</li>
          <li>Website, von der die Anforderung kommt</li>
          <li>Browser</li>
          <li>Betriebssystem und dessen Oberfläche</li>
          <li>Sprache und Version der Browsersoftware.</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <strong>Einsatz von Cookies</strong>
        </p>
        <p>
          (1) Zusätzlich zu den zuvor genannten Daten werden bei der Nutzung
          unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies
          handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem
          von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch
          welche der Stelle, die den Cookie setzt, bestimmte Informationen
          zufließen. Cookies können keine Programme ausführen oder Viren auf
          Ihren Computer übertragen. Sie dienen dazu, das Internetangebot
          insgesamt nutzerfreundlicher und effektiver zu machen.
        </p>
        <p>&nbsp;</p>
        <p>
          (2) Diese Website nutzt folgende Arten von Cookies, deren Umfang und
          Funktionsweise im Folgenden erläutert werden:
          <br />{' '}
        </p>
        <ul>
          <li>Transiente Cookies (dazu a.)</li>
          <li>Persistente Cookies (dazu b.).</li>
        </ul>
        <p>&nbsp;</p>
        <ol>
          <li>
            Transiente Cookies werden automatisiert gelöscht, wenn Sie den
            Browser schließen. Dazu zählen insbesondere die Session-Cookies.
            Diese speichern eine sogenannte Session-ID, mit welcher sich
            verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
            zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn
            Sie auf unsere Website zurückkehren. Die Session-Cookies werden
            gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            Persistente Cookies werden automatisiert nach einer vorgegebenen
            Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie
            können die Cookies in den Sicherheitseinstellungen Ihres Browsers
            jederzeit löschen.
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
            konfigurieren und <br />
            &thinsp;B. die Annahme von Third-Party-Cookies oder allen Cookies
            ablehnen. Sog. „Third Party Cookies“ sind Cookies, die durch einen
            Dritten gesetzt wurden, folglich nicht durch die eigentliche Website
            auf der man sich gerade befindet. Wir weisen Sie darauf hin, dass
            Sie durch die Deaktivierung von Cookies eventuell nicht alle
            Funktionen dieser Website nutzen können.
            <br />{' '}
          </li>
        </ol>
        <p>
          <strong>Weitere Funktionen und Angebote unserer Website</strong>
        </p>
        <p>
          (1) Neben der rein informatorischen Nutzung unserer Website bieten wir
          verschiedene Leistungen an, die Sie bei Interesse nutzen können. Dazu
          müssen Sie in der Regel weitere personenbezogene Daten angeben, die
          wir zur Erbringung der jeweiligen Leistung nutzen und für die die
          zuvor genannten Grundsätze zur Datenverarbeitung gelten.
        </p>
        <p>&nbsp;</p>
        <p>
          (2) Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer
          Dienstleister. Diese wurden von uns sorgfältig ausgewählt und
          beauftragt, sind an unsere Weisungen gebunden und werden regelmäßig
          kontrolliert.
        </p>
        <p>&nbsp;</p>
        <p>
          (3) Weiterhin können wir Ihre personenbezogenen Daten an Dritte
          weitergeben, wenn Aktionsteilnahmen, Gewinnspiele, Vertragsabschlüsse
          oder ähnliche Leistungen von uns gemeinsam mit Partnern angeboten
          werden. Nähere Informationen hierzu erhalten Sie bei Angabe Ihrer
          personenbezogenen Daten oder untenstehend in der Beschreibung des
          Angebotes.
        </p>
        <p>&nbsp;</p>
        <p>
          (4) Soweit unsere Dienstleister oder Partner ihren Sitz in einem Staat
          außerhalb des Europäischen Wirtschaftsraumen (EWR) haben, informieren
          wir Sie über die Folgen dieses Umstands in der Beschreibung des
          Angebotes.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Kinder</strong>
        </p>
        <p>
          Unser Angebot richtet sich grundsätzlich an Erwachsene. Personen unter
          18 Jahren sollten ohne Zustimmung der Eltern oder
          Erziehungsberechtigten keine personenbezogenen Daten an uns
          übermitteln.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Rechte der betroffenen Person</strong>
        </p>
        <p>
          <strong>(1) Widerruf der Einwilligung</strong>
        </p>
        <p>
          Sofern die Verarbeitung der personenbezogenen Daten auf einer
          erteilten Einwilligung beruht, haben Sie jederzeit das Recht, die
          Einwilligung zu widerrufen. Durch den Widerruf der Einwilligung wird
          die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
          erfolgten Verarbeitung nicht berührt.
        </p>
        <p>&nbsp;</p>
        <p>
          Für die Ausübung des Widerrufsrechts können Sie sich jederzeit an uns
          wenden.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>(2)</strong> <strong>Recht auf Bestätigung</strong>
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sie haben das Recht, von dem
          Verantwortlichen eine Bestätigung darüber zu verlangen, ob wir sie
          betreffende personenbezogene Daten verarbeiten. Die Bestätigung können
          Sie jederzeit unter den oben genannten Kontaktdaten verlangen.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>(3) Auskunftsrecht</strong>
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sofern personenbezogene Daten
          verarbeitet werden, können Sie jederzeit Auskunft über diese
          personenbezogenen Daten und über folgenden Informationen verlangen:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>die Verarbeitungszwecke;</li>
          <li>
            den Kategorien personenbezogener Daten, die verarbeitet werden;
          </li>
          <li>
            die Empfänger oder Kategorien von Empfängern, gegenüber denen die
            personenbezogenen Daten offengelegt worden sind oder noch
            offengelegt werden, insbesondere bei Empfängern in Drittländern oder
            bei internationalen Organisationen;
          </li>
          <li>
            falls möglich, die geplante Dauer, für die die personenbezogenen
            Daten gespeichert werden, oder, falls dies nicht möglich ist, die
            Kriterien für die Festlegung dieser Dauer;
          </li>
          <li>
            das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
            betreffenden personenbezogenen Daten oder auf Einschränkung der
            Verarbeitung durch den Verantwortlichen oder eines
            Widerspruchsrechts gegen diese Verarbeitung;
          </li>
          <li>
            das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
          </li>
          <li>
            wenn die personenbezogenen Daten nicht bei der betroffenen Person
            erhoben werden, alle verfügbaren Informationen über die Herkunft der
            Daten;
          </li>
          <li>
            das Bestehen einer automatisierten Entscheidungsfindung
            einschließlich Profiling gemäß Artikel 22&nbsp;Absätze 1 und 4 DSGVO
            und – zumindest in diesen Fällen – aussagekräftige Informationen
            über die involvierte Logik sowie die Tragweite und die angestrebten
            Auswirkungen einer derartigen Verarbeitung für die betroffene
            Person.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Werden personenbezogene Daten an ein Drittland oder an eine
          internationale Organisation übermittelt, so haben Sie das Recht, über
          die geeigneten Garantien gemäß Artikel 46 DSGVO im Zusammenhang mit
          der Übermittlung unterrichtet zu werden. Wir stellen eine Kopie der
          personenbezogenen Daten, die Gegenstand der Verarbeitung sind, zur
          Verfügung. Für alle weiteren Kopien, die Sie Person beantragen, können
          wir ein angemessenes Entgelt auf der Grundlage der Verwaltungskosten
          verlangen. Stellen Sie den Antrag elektronisch, so sind die
          Informationen in einem gängigen elektronischen Format zur Verfügung zu
          stellen, sofern er nichts anderes angibt. Das Recht auf Erhalt einer
          Kopie gemäß Absatz 3 darf die Rechte und Freiheiten anderer Personen
          nicht beeinträchtigen.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>(4) Recht auf Berichtigung&nbsp;&nbsp;&nbsp;</strong>
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sie haben das Recht, von uns
          unverzüglich die Berichtigung Sie betreffender unrichtiger
          personenbezogener Daten zu verlangen. Unter Berücksichtigung der
          Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung
          unvollständiger personenbezogener Daten – auch mittels einer
          ergänzenden Erklärung – zu verlangen.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>(5) Recht auf Löschung („Recht auf vergessen werden“)</strong>
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sie haben das Recht, von dem
          Verantwortlichen zu verlangen, dass Sie betreffende personenbezogene
          Daten unverzüglich gelöscht werden, und wir sind verpflichtet,
          personenbezogene Daten unverzüglich zu löschen, sofern einer der
          folgenden Gründe zutrifft:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben
            oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
          </li>
          <li>
            Die betroffene Person widerruft ihre Einwilligung, auf die sich die
            Verarbeitung gemäß Artikel 6&nbsp;Absatz 1 Buchstabe a oder Artikel
            9 Absatz 2 Buchstabe a DSGVO stützte, und es fehlt an einer
            anderweitigen Rechtsgrundlage für die Verarbeitung.
          </li>
          <li>
            Die betroffene Person legt gemäß Artikel 21 Absatz 1 DSGVO
            Widerspruch gegen die Verarbeitung ein und es liegen keine
            vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die
            betroffene Person legt gemäß Artikel 21&nbsp;Absatz 2 DSGVO
            Widerspruch gegen die Verarbeitung ein.
          </li>
          <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
          <li>
            Die Löschung der personenbezogenen Daten ist zur Erfüllung einer
            rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der
            Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.
          </li>
          <li>
            Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste
            der Informationsgesellschaft gemäß&nbsp;Artikel 8&nbsp;Absatz 1
            DSGVO erhoben.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Hat der Verantwortliche die personenbezogenen Daten öffentlich gemacht
          und ist er gemäß Absatz 1 zu deren Löschung verpflichtet, so trifft er
          unter Berücksichtigung der verfügbaren Technologie und der
          Implementierungskosten angemessene Maßnahmen, auch technischer Art, um
          für die Datenverarbeitung Verantwortliche, die die personenbezogenen
          Daten verarbeiten, darüber zu informieren, dass eine betroffene Person
          von ihnen die Löschung aller Links zu diesen personenbezogenen Daten
          oder von Kopien oder Replikationen dieser personenbezogenen Daten
          verlangt hat.
        </p>
        <p>&nbsp;</p>
        <p>
          Das Recht auf Löschung („Recht auf vergessen werden“) besteht nicht,
          soweit die Verarbeitung erforderlich ist:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
          </li>
          <li>
            zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung
            nach dem Recht der Union oder der Mitgliedstaaten, dem der
            Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer
            Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen
            wurde;
          </li>
          <li>
            aus Gründen des öffentlichen Interesses im Bereich der öffentlichen
            Gesundheit gemäß&nbsp;Artikel 9&nbsp;Absatz 2 Buchstaben h und i
            sowie&nbsp;Artikel 9&nbsp;Absatz 3 DSGVO;
          </li>
          <li>
            für im öffentlichen Interesse liegende Archivzwecke,
            wissenschaftliche oder historische Forschungszwecke oder für
            statistische Zwecke gemäß&nbsp;Artikel 89 Absatz 1 DSGVO, soweit das
            in Absatz 1 genannte Recht voraussichtlich die Verwirklichung der
            Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
            beeinträchtigt, oder
          </li>
          <li>
            zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <strong>(6) &nbsp;Recht auf Einschränkung der Verarbeitung</strong>
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sie haben das Recht, von uns die
          Einschränkung der Verarbeitung ihrer personenbezogenen Daten zu
          verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            die Richtigkeit der personenbezogenen Daten von der betroffenen
            Person bestritten wird, und zwar für eine Dauer, die es dem
            Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen
            Daten zu überprüfen,
          </li>
          <li>
            die Verarbeitung unrechtmäßig ist und die betroffene Person die
            Löschung der personenbezogenen Daten ablehnt und stattdessen die
            Einschränkung der Nutzung der personenbezogenen Daten verlangt;
          </li>
          <li>
            der Verantwortliche die personenbezogenen Daten für die Zwecke der
            Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch
            zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            benötigt, oder
          </li>
          <li>
            die betroffene Person Widerspruch gegen die Verarbeitung
            gemäß&nbsp;Artikel 21Absatz 1 DSGVO eingelegt hat, solange noch
            nicht feststeht, ob die berechtigten Gründe des Verantwortlichen
            gegenüber denen der betroffenen Person überwiegen.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Wurde die Verarbeitung gemäß den oben genannten Voraussetzungen
          eingeschränkt, so werden diese personenbezogenen Daten – von ihrer
          Speicherung abgesehen – nur mit Einwilligung der betroffenen Person
          oder zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
          oder juristischen Person oder aus Gründen eines wichtigen öffentlichen
          Interesses der Union oder eines Mitgliedstaats verarbeitet.
        </p>
        <p>&nbsp;</p>
        <p>
          Um das Recht auf Einschränkung der Verarbeitung geltend zu machen,
          kann sich die betroffene Person jederzeit an uns unter den oben
          angegebenen Kontaktdaten wenden.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>(7) Recht auf Datenübertragbarkeit</strong>
        </p>
        <p>
          Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
          Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese
          Daten einem anderen Verantwortlichen ohne Behinderung durch den
          Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
          wurden, zu übermitteln, sofern:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            die Verarbeitung auf einer Einwilligung gemäß&nbsp;Artikel 6Absatz 1
            Buchstabe a oder&nbsp;Artikel 9&nbsp;Absatz 2 Buchstabe a oder auf
            einem Vertrag gemäß&nbsp;Artikel 6&nbsp;Absatz 1 Buchstabe b DSGVO
            beruht und
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Bei der Ausübung des Rechts auf Datenübertragbarkeit gemäß Absatz 1
          haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten
          direkt von einem Verantwortlichen zu einem anderen Verantwortlichen
          übermittelt werden, soweit dies technisch machbar ist. Die Ausübung
          des Rechts auf Datenübertragbarkeit lässt das Recht auf Löschung
          („Recht auf Vergessen werden“) unberührt. Dieses Recht gilt nicht für
          eine Verarbeitung, die für die Wahrnehmung einer Aufgabe erforderlich
          ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
          Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>(8) Widerspruchsrecht</strong>
        </p>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
          personenbezogener Daten, die aufgrund von&nbsp;Artikel 6&nbsp;Absatz 1
          Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
          auch für ein auf diese Bestimmungen gestütztes Profiling. Der
          Verantwortliche verarbeitet die personenbezogenen Daten nicht mehr, es
          sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung
          nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen
          Person überwiegen, oder die Verarbeitung dient der Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
        <p>&nbsp;</p>
        <p>
          Werden personenbezogene Daten verarbeitet, um Direktwerbung zu
          betreiben, so haben SIe das Recht, jederzeit Widerspruch gegen die
          Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen
          Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die
          personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
        </p>
        <p>&nbsp;</p>
        <p>
          Im Zusammenhang mit der Nutzung von Diensten der
          Informationsgesellschaft könne Sie ungeachtet der&nbsp;Richtlinie
          2002/58/EG&nbsp;Ihr Widerspruchsrecht mittels automatisierter
          Verfahren ausüben, bei denen technische Spezifikationen verwendet
          werden.
        </p>
        <p>&nbsp;</p>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, gegen die Sie betreffende Verarbeitung Sie
          betreffender personenbezogener Daten, die zu wissenschaftlichen oder
          historischen Forschungszwecken oder zu statistischen Zwecken
          gemäß&nbsp;Artikel 89&nbsp;Absatz 1 erfolgt, Widerspruch einzulegen,
          es sei denn, die Verarbeitung ist zur Erfüllung einer im öffentlichen
          Interesse liegenden Aufgabe erforderlich.
        </p>
        <p>&nbsp;</p>
        <p>
          Das Widerspruchsrecht können Sie jederzeit ausüben, indem Sie sich an
          den jeweiligen Verantwortlichen wenden.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            (9) Automatisierte Entscheidungen im Einzelfall einschließlich
            Profiling
          </strong>
        </p>
        <p>
          Sie haben das Recht, nicht einer ausschließlich auf einer
          automatisierten Verarbeitung – einschließlich Profiling – beruhenden
          Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
          Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
          beeinträchtigt. Dies gilt nicht, wenn die Entscheidung:
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            für den Abschluss oder die Erfüllung eines Vertrags zwischen der
            betroffenen Person und dem Verantwortlichen erforderlich ist,
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
            denen der Verantwortliche unterliegt, zulässig ist und diese
            Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und
            Freiheiten sowie der berechtigten Interessen der betroffenen Person
            enthalten oder
          </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
          <li>
            mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Der Verantwortliche trifft angemessene Maßnahmen, um die Rechte und
          Freiheiten sowie die berechtigten Interessen der betroffenen Person zu
          wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer
          Person seitens des Verantwortlichen, auf Darlegung des eigenen
          Standpunkts und auf Anfechtung der Entscheidung gehört.
        </p>
        <p>&nbsp;</p>
        <p>
          Dieses Recht kann die betroffene Person jederzeit ausüben, indem sie
          sich an den jeweiligen Verantwortlichen wendet.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>(10) Recht auf Beschwerde bei einer Aufsichtsbehörde</strong>
        </p>
        <p>
          Sie haben zudem, unbeschadet eines anderweitigen
          verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, das Recht auf
          Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des
          Orts des mutmaßlichen Verstoßes, wenn die betroffene Person der
          Ansicht ist, dass die Verarbeitung der sie betreffenden
          personenbezogenen Daten gegen diese Verordnung verstößt.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>(11) Recht auf wirksamen gerichtlichen Rechtsbehelf</strong>
        </p>
        <p>
          Sie haben unbeschadet eines verfügbaren verwaltungsrechtlichen oder
          außergerichtlichen Rechtsbehelfs einschließlich des Rechts auf
          Beschwerde bei einer Aufsichtsbehörde gemäß&nbsp;
          <a href="https://dsgvo-gesetz.de/art-77-dsgvo/">Artikel 77</a>{' '}
          DSGVO&nbsp;das Recht auf einen wirksamen gerichtlichen Rechtsbehelf,
          wenn sie der Ansicht ist, dass die ihr aufgrund dieser Verordnung
          zustehenden Rechte infolge einer nicht im Einklang mit dieser
          Verordnung stehenden Verarbeitung ihrer personenbezogenen Daten
          verletzt wurden.
          <br />{' '}
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Einsatz von Google Analytics</strong>
        </p>
        <p>
          (1) Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung dieser Website
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
          auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb
          von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
          einen Server von Google in den USA übertragen und dort gekürzt. Im
          Auftrag des Betreibers dieser Website wird Google diese Informationen
          benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
          Website-Aktivitäten zusammenzustellen und um weitere mit der
          Website-Nutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Website-Betreiber zu erbringen.
        </p>
        <p>&nbsp;</p>
        <p>
          (2) Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
          IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        </p>
        <p>&nbsp;</p>
        <p>
          (3) Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch das Cookie erzeugten und
          auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem sie das unter dem folgenden Link verfügbare Browser-Plug-in
          herunterladen und installieren:{' '}
          <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          .
        </p>
        <p>&nbsp;</p>
        <p>
          (4) Diese Website verwendet Google Analytics mit der Erweiterung
          „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt
          weiterverarbeitet, eine Personenbeziehbarkeit kann damit
          ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein
          Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die
          personenbezogenen Daten damit umgehend gelöscht.
        </p>
        <p>&nbsp;</p>
        <p>
          (5) Wir nutzen Google Analytics, um die Nutzung unserer Website
          analysieren und regelmäßig verbessern zu können. Über die gewonnenen
          Statistiken können wir unser Angebot verbessern und für Sie als Nutzer
          interessanter ausgestalten. Für die Ausnahmefälle, in denen
          personenbezogene Daten in die USA übertragen werden, hat sich Google
          dem EU-US Privacy Shield unterworfen,
          https://www.privacyshield.gov/EU-US-Framework. Rechtsgrundlage für die
          Nutzung von Google Analytics ist&nbsp;Art.&nbsp;
          <a href="https://beck-online.beck.de/?typ=reference&amp;y=100&amp;a=6&amp;g=EWG_DSGVO">
            6
          </a>
          &nbsp;Abs.&nbsp;
          <a href="https://beck-online.beck.de/?typ=reference&amp;y=100&amp;a=6&amp;g=EWG_DSGVO&amp;x=1">
            1
          </a>
          &nbsp;S.&nbsp;1 lit. f DSGVO.
        </p>
        <p>&nbsp;</p>
        <p>
          (6) Informationen des Drittanbieters: Google Dublin, Google Ireland
          Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1)
          436&thinsp;1001. Nutzerbedingungen:
        </p>
        <p>&nbsp;</p>
        <p>
          http://www.google.com/analytics/terms/de.html, Übersicht zum
          Datenschutz:
          http://www.google.com/intl/de/analytics/learn/privacy.html, sowie die
          Datenschutzerklärung: http://www.google.de/intl/de/policies/privacy.
        </p>
        <p>&nbsp;</p>
        <p>
          (7) Diese Website verwendet Google Analytics zudem für eine
          geräteübergreifende Analyse von Besucherströmen, die über eine User-ID
          durchgeführt wird. Sie können in Ihrem Kundenkonto unter „Meine
          Daten“, „persönliche Daten“ die geräteübergreifende Analyse Ihrer
          Nutzung deaktivieren.
          <br />{' '}
        </p>
        <p>
          <strong>
            <br />{' '}
          </strong>
          <strong>Einbindung von Google Maps</strong>
        </p>
        <p>
          (1) Auf dieser Website nutzen wir das Angebot von Google Maps. Dadurch
          können wir Ihnen interaktive Karten direkt in der Website anzeigen und
          ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.
        </p>
        <p>&nbsp;</p>
        <p>
          (2) Durch den Besuch auf der Website erhält Google die Information,
          dass Sie die entsprechende Unterseite unserer Website aufgerufen
          haben. Zudem werden die unter&nbsp;§&nbsp;3&nbsp;dieser Erklärung
          genannten Daten übermittelt. Dies erfolgt unabhängig davon, ob Google
          ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob
          kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden
          Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit
          Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor
          Aktivierung des Buttons ausloggen. Google speichert Ihre Daten als
          Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung
          und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche
          Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer)
          zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des
          sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
          informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
          dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google
          richten müssen.
        </p>
        <p>&nbsp;</p>
        <p>
          (3) Weitere Informationen zu Zweck und Umfang der Datenerhebung und
          ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den
          Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch weitere
          Informationen zu Ihren diesbezüglichen Rechten und
          Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:
          http://www.google.de/intl/de/policies/privacy. Google verarbeitet Ihre
          personenbezogenen Daten auch in den USA und hat sich dem EU-US Privacy
          Shield unterworfen,{' '}
          <a href="https://www.privacyshield.gov/EU-US-Framework">
            https://www.privacyshield.gov/EU-US-Framework
          </a>
          .
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Auftragsverarbeiter</strong>
        </p>
        <p>
          Wir bedienen uns externen Dienstleister (Auftragsverarbeiter) z. B.
          für den Versand von Waren, Newsletter oder Zahlungsabwicklungen. Mit
          dem Dienstleister wurde eine separate Auftragsdatenverarbeitung
          geschlossen, um den Schutz Ihrer personenbezogenen Daten zu
          gewährleisten.
        </p>
      </div>
    </div>
  </div>
);

export default Privacy;
