import TEXT from '../text';
import * as UTILS from './utilFunctions';

export function formatResidence(candidate) {
  if (candidate.currentResidence && candidate.currentCountry) {
    return `${candidate.currentResidence} (${candidate.currentCountry})`;
  }
  return candidate.currentResidence || candidate.currentCountry || '';
}

export function formatAge(candidate) {
  return candidate.age && candidate.age > 1 ? candidate.age : TEXT.commonTerms.NA;
}

export function formatDuration(duration) {
  if (!duration) {
    return '';
  }
  return `${TEXT.resultPage.lastPositions.currentPosition}${UTILS.formatDuration(duration)}), `;
}
