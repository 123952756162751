import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLoading from '../../utils/useLoading';
import FileUploadField from '../../generic_components/FileUploadField';
import { uploadProfilePdf, getProfilePdfFileInfo, deleteProfilePdf } from '../../api/company';

const CompanyFiles = ({ loading }) => {
  const [fileInfo, setFileInfo] = useState();
  const [fileLoading, wrapAsyncLoading] = useLoading(true);
  React.useEffect(
    wrapAsyncLoading(async () => {
      try {
        const data = await getProfilePdfFileInfo();
        setFileInfo(data);
      } catch (err) {
        if (err.status !== 404) {
          throw err;
        }
      }
    }),
    []
  );

  const fileChanged = wrapAsyncLoading(async file => {
    if (file) {
      await uploadProfilePdf(file);
      setFileInfo({ fileName: file.name });
    } else {
      try {
        await deleteProfilePdf(file);
      } catch (err) {
        console.log(err);
      }
      setFileInfo(undefined);
    }
  });
  const downloadPdf = wrapAsyncLoading(async () => {
    if (!fileInfo) {
      return '';
    }
    const refreshedInfo = await getProfilePdfFileInfo();
    return refreshedInfo.contentUrl;
  }, true);

  return (
    <FileUploadField
      value={(fileInfo || {}).fileName}
      onChange={fileChanged}
      label="Unternehmensprofil hochladen"
      disabled={loading || fileLoading}
      onResolveFileUrl={downloadPdf}
      placeholder="Sie haben eine Beschreibung Ihres Unternehmens als Text-Datei? Laden Sie es hier hoch."
    />
  );
};

CompanyFiles.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default CompanyFiles;
