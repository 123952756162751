import * as React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import EyeIcon from '@material-ui/icons/RemoveRedEye';
import { STATUS_BUCKETS } from './StatusChart';
import DialogWrapper from '../../generic_components/DialogWrapper';
import CandidateDetails from './CandidateDetails';
import { formatDuration, formatAge, formatResidence } from '../../utils/candidate';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 400
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  }
}));

const CandidateList = ({ projectId, candidateList, loading, onDelete, onAdd, onViewPdf, filter }) => {
  const [height, setHeight] = React.useState(0);
  const listRef = React.useRef();
  React.useEffect(() => {
    if (listRef.current && listRef.current.clientHeight > height) {
      setHeight(listRef.current.clientHeight);
    }
  });
  const [selectedCandidateId, setSelectedCandidateId] = React.useState();
  const generateDossierButton = candidate => {
    return (
      <IconButton
        edge="end"
        aria-label="delete"
        title={candidate.clientViewDate ? 'Dossier ansehen' : 'Dossier ansehen (ungelesen)'}
        disabled={loading}
        onClick={() => onViewPdf(candidate.id, projectId)}
      >
        <EyeIcon color={candidate.clientViewDate ? 'primary' : 'error'} />
      </IconButton>
    );
  };
  const generateSecondaryAction = candidate => {
    let secondaryAction = '';
    switch (candidate.status) {
      case 'rejected':
        secondaryAction = (
          <IconButton edge="end" aria-label="add" title="Kandidat wieder aufnehmen" disabled={loading} onClick={() => onAdd(candidate.id)}>
            <AddIcon />
          </IconButton>
        );
        break;

      case 'added':
        secondaryAction = (
          <IconButton
            edge="end"
            aria-label="delete"
            title={candidate.status === 'added' ? 'Kandidat löschen' : 'Kandidat ablehnen'}
            disabled={loading}
            onClick={() => onDelete(candidate.id)}
          >
            <DeleteIcon />
          </IconButton>
        );
        break;
      case 'applied': // dossier case
        secondaryAction = (
          <IconButton
            edge="end"
            aria-label="delete"
            title={candidate.status === 'added' ? 'Kandidat löschen' : 'Kandidat ablehnen'}
            disabled={loading}
            onClick={() => onDelete(candidate.id)}
          >
            <NotInterestedIcon />
          </IconButton>
        );
        break;

      default:
        break;
    }
    if (candidate.allStatus.includes('applied'))
      return (
        <>
          {secondaryAction}
          {generateDossierButton(candidate)}
        </>
      );
    return secondaryAction;
  };
  const classes = useStyles();

  const groupedCandidateList = STATUS_BUCKETS.map(bucket => candidateList.filter(candidate => candidate.allStatus.includes(bucket.status)));
  // console.log('go', groupedCandidateList);
  return (
    <List innerRef={listRef} className={classes.root} subheader={<li />} style={{ minHeight: height }}>
      {candidateList.length ? (
        groupedCandidateList.map((group, idx) =>
          group.length
            ? (!filter || STATUS_BUCKETS[idx].status === filter) && (
                <li key={STATUS_BUCKETS[idx].status} className={classes.listSection}>
                  <ul className={classes.ul}>
                    <ListSubheader className="MuiListSubheader-divider">
                      {STATUS_BUCKETS[idx].label} ({group.length})
                    </ListSubheader>
                    {group.map(candidate => (
                      <ListItem button dense key={candidate.id} onClick={() => setSelectedCandidateId(candidate.id)}>
                        <ListItemText
                          primary={`${candidate.currentJob}, Alter: ${formatAge(candidate)}`}
                          secondary={`${formatDuration(candidate.currentDuration)}${formatResidence(candidate)}`}
                        />
                        <ListItemSecondaryAction>{generateSecondaryAction(candidate)}</ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </ul>
                </li>
              )
            : null
        )
      ) : (
        <Typography variant="body2" color="textSecondary">
          Keine passenden Kandidaten.
        </Typography>
      )}
      <DialogWrapper
        dialogTitle="Kandidaten-Details"
        open={!!selectedCandidateId}
        cancelButtonName="Schließen"
        maxWidth="md"
        handleClose={() => setSelectedCandidateId(undefined)}
      >
        <CandidateDetails showRatings={false} projectId={projectId} candidateId={selectedCandidateId} />
      </DialogWrapper>
    </List>
  );
};

CandidateList.propTypes = {
  projectId: PropTypes.string.isRequired,
  candidateList: PropTypes.array,
  loading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onViewPdf: PropTypes.func.isRequired,
  filter: PropTypes.string
};

CandidateList.defaultProps = {
  candidateList: [],
  loading: false,
  filter: undefined
};

export default CandidateList;
