import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, NativeSelect, InputLabel, Input, CircularProgress } from '@material-ui/core';

import useLoading from '../utils/useLoading';
import { getProjectList } from '../api/projects';

export default function ProjectSelectorDropDown({ setSelectedProject, onChange, value }) {
  const [loading, wrapAsyncLoading] = useLoading();
  const [projectList, setProjectList] = React.useState([]);

  React.useEffect(
    wrapAsyncLoading(async () => {
      setProjectList(
        (await getProjectList()).filter(entry => entry.status !== 'Erfolgreich abgeschlossen' && entry.status !== 'Storniert')
      );
    }),
    [value]
  );

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center">
          <CircularProgress /> <div className="ml-3">Liste wird geladen...</div>
        </div>
      ) : (
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="project-native-simple">
            Aufträge
          </InputLabel>
          <NativeSelect
            value={value}
            onChange={event => {
              onChange(event.target.value);
              setSelectedProject(projectList.find(entry => entry.id === event.target.value));
            }}
            input={<Input name="project" id="project-native-helper" />}
          >
            <option value="">Auftrag auswählen oder erstellen</option>
            {projectList.map(entry => (
              <option key={`${entry.id}_${entry.title}`} value={entry.id}>
                {`${entry.title}  ${
                  entry.candidateCount > 0
                    ? `(Status: ${entry.status}, Anzahl Kandidaten: ${entry.candidateCount})`
                    : `(Status: ${entry.status}, Keine Einträge)`
                }`}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      )}
    </>
  );
}

ProjectSelectorDropDown.defaultProps = {
  value: ''
};

ProjectSelectorDropDown.propTypes = {
  setSelectedProject: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};
