import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IconButton, MenuItem, Menu } from '@material-ui/core';

import TEXT from '../../text';

class AccountMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  exitMenuOnClick() {
    this.setState({ anchorEl: null });
  }

  render() {
    return (
      <>
        <IconButton
          aria-owns={this.state.anchorEl ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={event => {
            this.setState({
              anchorEl: event.currentTarget
            });
          }}
          color="inherit"
        >
          <i className="material-icons">account_circle</i>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.setState({ anchorEl: null });
          }}
        >
          <MenuItem
            component={Link}
            to="/profile"
            onClick={() => {
              this.exitMenuOnClick();
            }}
          >
            <i className="material-icons">domain</i>
            &nbsp; Unternehmensprofil
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ anchorEl: null });
              this.props.logoutApiCall();
            }}
          >
            <i className="material-icons">exit_to_app</i>
            &nbsp;
            {TEXT.navBar.logout}
          </MenuItem>
        </Menu>
      </>
    );
  }
}

AccountMenu.propTypes = {
  logoutApiCall: PropTypes.func.isRequired
};

export default AccountMenu;
