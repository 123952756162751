import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { makeStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import PdfViewerModal from './PdfViewerModal';

const useStyles = makeStyles({
  fileRow: {
    '& .hoverIcon': {
      display: 'none'
    },
    '&:hover .icon': {
      display: 'none'
    },
    '&:hover .hoverIcon': {
      display: 'inline-block'
    }
  }
});

const FileList = ({ readOnly, disabled, isNewFile, files, onDelete, onResolveFileUrl }) => {
  const classes = useStyles();
  const [isPdfViewerVisible, setIsPdfViewerVisible] = useState(false);
  const [pdfFile, setPdfFile] = useState();

  function view(file) {
    setPdfFile(file);
    setIsPdfViewerVisible(true);
  }
  return (
    <>
      <List dense>
        {(files || []).map((file, idx) => (
          <ListItem key={file.name || file} button disabled={disabled || isNewFile} className={classes.fileRow} onClick={() => view(file)}>
            <ListItemAvatar>
              <Avatar>
                <FileIcon className="icon" />
                <EyeIcon className="hoverIcon" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={file.name || file} />
            {!readOnly && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  disabled={disabled}
                  onClick={() => {
                    onDelete(file, idx);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
      {isPdfViewerVisible && (
        <PdfViewerModal
          file={pdfFile}
          isOpen={isPdfViewerVisible}
          onResolveFileUrl={onResolveFileUrl}
          onClose={() => setIsPdfViewerVisible(false)}
        />
      )}
    </>
  );
};

FileList.propTypes = {
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  files: PropTypes.array,
  isNewFile: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onResolveFileUrl: PropTypes.func.isRequired
};

FileList.defaultProps = {
  readOnly: false,
  disabled: false,
  isNewFile: false,
  files: []
};

export default FileList;
