import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './AllSearchResults.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InfiniteScroll from 'react-infinite-scroller';
import TEXT from '../text';
import SingleSearchResult from './SingleSearchResult';
import SortLabel from './SortLabel';
import * as UTILS from '../utils/utilFunctions';
import withUser from '../utils/withUser';
import MarkItemPopUp from './MarkItemPopUp';

/**
 * Displays the
 *    - Sort options
 *    - Hit count
 *    - SingleSearchResult's in pagination with the pagination controls
 * @author victor
 * @extends Component
 */
class AllSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultLimit: 10,
      markCandidatePopUpOpen: false,
      selectedUserMarkItem: {}
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.resultLimit > 10 && props.showLoadingIndicator) {
      return { ...state, resultLimit: 10 };
    }
    return state;
  }

  liftUpStateToAllSearchResults = (stateNameOrObject, value) => {
    this.setState({ [stateNameOrObject]: value });
  };

  handleShowMoreResults = () => {
    this.setState(previousState => ({ resultLimit: Math.min(this.props.resultCount || 10, previousState.resultLimit + 10) }));
  };

  /**
   * a loading indicator component if this.props.showLoadingIndicator=true or
   * a SingleSearchResult component Array if the above are false
   * @return {JSX}
   */
  renderSearchResults() {
    let result = <TableRow />;
    if (!this.props.showLoadingIndicator && this.props.results.length > 0) {
      result = this.props.results.slice(0, this.state.resultLimit).map((singleResult, index) => {
        const mappedAge = singleResult.age && singleResult.age > 1 ? singleResult.age : TEXT.commonTerms.NA;
        return (
          <React.Fragment key={singleResult.ID}>
            <TableRow>
              <td>
                <SingleSearchResult
                  index={index}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  liftUpStateToApp={this.props.liftUpStateToApp}
                  liftUpStateToAllSearchResults={this.liftUpStateToAllSearchResults}
                  selectedProjectHandler={this.props.selectedProjectHandler}
                  {...singleResult}
                  projectStatus={(this.props.projectCandidates.find(c => c.id === singleResult.ID) || {}).status}
                  isAddedToOtherProject={this.props.otherProjectCandidateIDs.includes(singleResult.ID)}
                  age={mappedAge}
                />
              </td>
            </TableRow>
            {index > 0 && index % 10 === 0 && (
              <TableRow>
                <td>
                  <div className="m-2 d-flex" style={{ borderTop: '1px solid #ddd' }}>
                    <div className="flex-shrink-0 flex-grow-1">
                      Ergebnis {index + 1} bis {Math.min(index + 10, this.props.results.length)} von {this.props.results.length}
                    </div>
                    <div>
                      <a
                        href="#top"
                        onClick={ev => {
                          ev.preventDefault();
                          this.props.scrollParentRef.current.scrollTo(0, 0);
                        }}
                      >
                        Zum Anfang
                      </a>
                    </div>
                  </div>
                </td>
              </TableRow>
            )}
          </React.Fragment>
        );
      });
    } else if (!this.props.showLoadingIndicator && this.props.results.length === 0) {
      result = (
        <TableRow>
          <h4 className="no-result-info">{TEXT.resultPage.noResults}</h4>
        </TableRow>
      );
    }
    return result;
  }

  renderPaginationControls() {
    return (
      <TableRow>
        <TablePagination
          count={this.props.results.length}
          rowsPerPage={this.state.rowsPerPage}
          rowsPerPageOptions={[10, 30, 50]}
          labelRowsPerPage={TEXT.resultPage.labelRowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} ${TEXT.resultPage.labelPaginationFromTo1} ${to} ${TEXT.resultPage.labelPaginationFromTo2} ${count}`
          }
        />
      </TableRow>
    );
  }

  renderSortButtons() {
    const sortButtons = [];
    Object.values(TEXT.resultPage.sortingOptions).forEach(optionString => {
      if (!this.props.disableDistanceSorting || optionString !== 'Entfernung') {
        sortButtons.push(
          <SortLabel
            key={optionString}
            isActive={optionString === this.props.sortBy}
            direction={this.props.sortDescending}
            label={optionString}
            onClick={this.props.sortResults}
          />
        );
      }
    });
    return sortButtons;
  }

  render() {
    return (
      <div id="top" className="AllSearchResults">
        <div className="d-flex justify-content-between p-2">
          <div className="">{this.renderSortButtons()}</div>
          <div className="pr-2">{`${TEXT.resultPage.amountOfHits} ${this.props.resultCount}`}</div>
        </div>
        <div className="m-2 text-left">
          <div className="p-2 mt-1 text-left cl-teal result-bar">{TEXT.resultPage.results}</div>
        </div>
        <MarkItemPopUp
          open={this.state.markCandidatePopUpOpen}
          handleClose={() => this.setState({ markCandidatePopUpOpen: false })}
          item={this.state.selectedUserMarkItem}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          markListPopupType="candidate"
        />
        <Table>
          <TableBody>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.handleShowMoreResults}
              hasMore={(this.props.resultCount || 0) > this.state.resultLimit && !this.props.showLoadingIndicator}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              useWindow={false}
              getScrollParent={() => this.props.scrollParentRef.current}
            >
              {this.renderSearchResults()}
            </InfiniteScroll>
          </TableBody>
        </Table>
      </div>
    );
  }
}

AllSearchResults.defaultProps = {
  role: []
};

AllSearchResults.propTypes = {
  showLoadingIndicator: PropTypes.bool.isRequired,
  resultCount: PropTypes.number.isRequired,
  sortResults: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDescending: PropTypes.bool.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      isAvailable: PropTypes.bool,
      currentResidence: PropTypes.string,
      currentCountry: PropTypes.string,
      age: PropTypes.string,
      lastChange: PropTypes.string,
      ID: PropTypes.string.isRequired,
      picture: PropTypes.string,
      jobs: PropTypes.arrayOf(
        PropTypes.shape({
          jobText: PropTypes.string,
          description: PropTypes.string,
          company: PropTypes.string,
          duration: PropTypes.number,
          branches: PropTypes.arrayOf(PropTypes.string),
          ID: PropTypes.string.isRequired
        })
      ),
      score: PropTypes.number.isRequired,
      willingnessToChange: PropTypes.number.isRequired,
      distance: PropTypes.number.isRequired,
      willingnessToRelocate: PropTypes.string,
      brand: PropTypes.array
    })
  ).isRequired,
  projectCandidates: PropTypes.arrayOf(PropTypes.object).isRequired,
  otherProjectCandidateIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: PropTypes.object.isRequired,
  role: PropTypes.array,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  disableDistanceSorting: PropTypes.func.isRequired,
  selectedProjectHandler: PropTypes.object.isRequired,
  scrollParentRef: PropTypes.object.isRequired
};

export default withUser(AllSearchResults);
