import { Slider, FormControl, InputLabel, Box, FormHelperText } from '@material-ui/core';
import * as React from 'react';
import PropTypes from 'prop-types';

const SliderField = ({ readOnly, value, onChange, label, error, errorMessage, ...sliderProps }) => {
  // performance optimization: lift up value only when mouse is released
  const [tmpValue, setTmpValue] = React.useState();
  const { min, max, step, marks, valueLabelFormat } = sliderProps;

  return (
    <FormControl fullWidth>
      <InputLabel shrink required error={error}>
        {label}
      </InputLabel>
      <Box mx={2} mt={2}>
        <Slider
          valueLabelDisplay="auto"
          style={{ marginBottom: 10 }}
          min={min}
          max={max}
          step={step}
          marks={marks}
          valueLabelFormat={valueLabelFormat}
          value={tmpValue === undefined ? value : tmpValue}
          onChange={(ev, newValue) => setTmpValue(newValue)}
          onChangeCommitted={() => {
            onChange(tmpValue);
            setTmpValue(undefined);
          }}
          disabled={readOnly}
        />
      </Box>
      {error && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

SliderField.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
};

SliderField.defaultProps = {
  readOnly: false,
  value: false,
  error: false,
  errorMessage: ''
};

export default React.memo(SliderField);
