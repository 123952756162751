import React from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useLoading from '../../utils/useLoading';
import { addCandidateToProject, removeCandidateFromProject, getProjectById } from '../../api/projects';

import TEXT from '../../text';

function generateButtonText(isProjectSelected, removeReady) {
  let buttonText = '';
  if (isProjectSelected) {
    if (removeReady) {
      buttonText = 'Aus Interviewliste entfernen';
    } else {
      buttonText = TEXT.resultPage.addToProject;
    }
  } else {
    buttonText = 'Auftrag auswählen';
  }
  return buttonText;
}

function generateOnClick(isProjectSelected, removeReady, cbObject) {
  let onClick;
  if (isProjectSelected) {
    if (removeReady) {
      onClick = cbObject.remove;
    } else {
      onClick = cbObject.add;
    }
  } else {
    onClick = cbObject.default;
  }
  return onClick;
}

const AddToProjectButton = ({ selectedProjectHandler, candidateId, score, willingnessToChange, isAdded }) => {
  const [saving, wrapAsyncSaving] = useLoading();
  const isProjectSelected = selectedProjectHandler.selectedProject && Object.keys(selectedProjectHandler.selectedProject).length > 0;
  const { enqueueSnackbar } = useSnackbar();

  const addCandidateToProjectApiCall = wrapAsyncSaving(async (projectId, potentialCandidateId) => {
    try {
      await addCandidateToProject(projectId, potentialCandidateId, score, willingnessToChange);
      const project = await getProjectById(projectId);
      selectedProjectHandler.updateProjectDataHandler(project);
      enqueueSnackbar('Kandidat wurde zur Interviewliste hinzugefügt', { variant: 'success' });
      selectedProjectHandler.setMark(potentialCandidateId, true);
      if (project.candidateCount === 10) {
        selectedProjectHandler.openCandidateCountNotification();
      }
    } catch (err) {
      if (err.status === 409) {
        enqueueSnackbar('Kandidat ist im Auftrag vorhanden', { variant: 'warning' });
        selectedProjectHandler.setMark(potentialCandidateId, true);
      } else if (err.status === 422) {
        enqueueSnackbar('Es können max. 30 Kandidaten zu einer Interviewliste hinzugefügt werden', { variant: 'warning' });
      } else {
        throw err;
      }
    }
  });

  const removeCandidateFromProjectApiCall = wrapAsyncSaving(async (projectId, potentialCandidateId) => {
    try {
      await removeCandidateFromProject(projectId, potentialCandidateId);
      const project = await getProjectById(projectId);
      selectedProjectHandler.updateProjectDataHandler(project);
      enqueueSnackbar('Kandidat wurde vom Auftrag entfernt', { variant: 'success' });
      selectedProjectHandler.setMark(potentialCandidateId, false);
    } catch (err) {
      if (err.status === 404) {
        enqueueSnackbar('Kandidat ist nicht im Auftrag vorhanden', { variant: 'warning' });
        selectedProjectHandler.setMark(potentialCandidateId, false);
      } else if (err.status === 422) {
        enqueueSnackbar('Der Kandidat kann im aktuellen Status nicht entfernt/abgelehnt werden', { variant: 'warning' });
      } else {
        throw err;
      }
    }
  });

  const onClick = generateOnClick(isProjectSelected, isAdded, {
    add: () => addCandidateToProjectApiCall(selectedProjectHandler.selectedProject.id, candidateId),
    remove: () => removeCandidateFromProjectApiCall(selectedProjectHandler.selectedProject.id, candidateId),
    default: selectedProjectHandler.openSelectorHandler
  });

  const buttonText = generateButtonText(isProjectSelected, isAdded);

  return (
    <div className="row">
      <div className="col-12  mt-3 mb-1">
        {saving ? (
          <div className="d-flex align-items-center">
            <CircularProgress />{' '}
          </div>
        ) : (
          <Button
            className="button-more-info"
            variant="contained"
            color={isProjectSelected ? 'secondary' : 'textSecondary'}
            onClick={onClick}
            fullWidth
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

AddToProjectButton.propTypes = {
  selectedProjectHandler: PropTypes.object.isRequired,
  candidateId: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  willingnessToChange: PropTypes.number.isRequired,
  isAdded: PropTypes.bool.isRequired
};

export default AddToProjectButton;
