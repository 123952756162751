import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@material-ui/core';
import OverflowIconMenu from '../../generic_components/OverflowIconMenu';
import ConfirmationModal from '../../generic_components/ConfirmationModal';

function ProjectActions({ loading, status, onBookingOptions, onSearch, onEdit, onUseTemplate,
   onUpdateStatus}) {
  const theme = useTheme();
  const [isStornoConfirmationOpen, setIsStornoConfirmationOpen] = useState(false);

  const widthLg = useMediaQuery(theme.breakpoints.up('lg'));
  const widthMd = useMediaQuery(theme.breakpoints.up('md'));

  const actions = [
    {
      title: status === 'In Bearbeitung' ? 'Weitere Kandidaten finden' : 'Jetzt Kandidaten finden',
      color: 'primary',
      variant: 'contained',
      hidden: status === 'Abgeschlossen',
      priority: 4,
      handler: onSearch
    },
    {
      title: status === 'Abgeschlossen' ? 'Anzeigen' : 'Bearbeiten',
      color: 'primary',
      priority: 3,
      handler: onEdit
    },
    {
      title: 'Als Vorlage verwenden',
      priority: 1,
      handler: onUseTemplate
    },
    {
      title: 'Kandidaten freigeben',
      disabled: loading,
      hidden: status !== 'Noch nicht freigegeben' && status !== 'In Bearbeitung',
      priority: 2,
      handler: () => onUpdateStatus('In Bearbeitung')
    },
    {
      title: 'Auftrag stornieren',
      disabled: loading,
      hidden: status === 'Abgeschlossen',
      handler: () => setIsStornoConfirmationOpen(true)
    },
    {
      title: 'Buchungsoptionen',
      disabled: loading,
      hidden: status === 'Abgeschlossen',
      handler:  onBookingOptions
    }
  ].filter(a => !a.hidden);

  return (
    <>
      <ConfirmationModal
        open={isStornoConfirmationOpen}
        handleClose={() => setIsStornoConfirmationOpen(false)}
        buttonPrimaryAction={() => onUpdateStatus('Storniert')}
        headlineText="Stornieren?"
        descriptionText="Wollen Sie den Auftrag wirklich stornieren?"
        contentText="Dies kann nicht rückgängig gemacht werden. Alle bereits angesprochenen Kandidaten erhalten ein Absage."
        icon="warning"
      />
      

      {/* eslint-disable-next-line no-nested-ternary */}
      <OverflowIconMenu max={widthLg ? 5 : widthMd ? 3 : 2} actions={actions} />{' '}
    </>
  );
}
ProjectActions.propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.string.isRequired,
  onBookingOptions: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onUseTemplate: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
};

ProjectActions.defaultProps = {
  loading: false,
};

export default ProjectActions;
