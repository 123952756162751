import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Drawer, Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import withUser from '../../utils/withUser';

const linksBottomMenu = [
  {
    to: '/imprint',
    text: 'Impressum',
    materialIcon: 'info'
  },
  {
    to: '/privacy',
    text: 'Datenschutz',
    materialIcon: 'info'
  }
];

const linksGeneralMenu = [
  {
    to: '/profile',
    text: 'Unternehmensprofil',
    materialIcon: 'domain'
  },
  {
    to: '/myprojects',
    text: 'Meine Aufträge',
    materialIcon: 'folder_shared'
  },
  {
    to: '/search',
    text: 'Semantische Suche',
    materialIcon: 'search'
  },
  {
    to: '/markLists',
    text: 'Merklisten',
    materialIcon: 'list'
  },
  {
    to: '/notifications',
    text: 'Benachrichtigungen',
    materialIcon: 'notification_important'
  }
];

class MainMenuDrawer extends PureComponent {
  renderMenuItems(itemList) {
    return itemList
      .map(entry => {
        if (!entry.hide && !entry.isAdminLink) {
          return (
            <ListItem key={entry.text} button component={Link} to={entry.to} color="inherit" onClick={this.props.onCloseMainMenu}>
              <ListItemIcon>
                <i className="material-icons">{entry.materialIcon}</i>
              </ListItemIcon>
              <ListItemText primary={entry.text} />
            </ListItem>
          );
        }
        return undefined;
      })
      .filter(entry => entry !== undefined);
  }

  render() {
    return (
      <>
        <Drawer open={this.props.open} onClose={this.props.onCloseMainMenu}>
          <List>{this.renderMenuItems(linksGeneralMenu)}</List>
          <Divider />
          <List>{this.renderMenuItems(linksBottomMenu)}</List>
        </Drawer>
      </>
    );
  }
}

MainMenuDrawer.propTypes = {
  onCloseMainMenu: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default withUser(MainMenuDrawer);
