import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CircularProgressbar from 'react-circular-progressbar';
import TEXT from '../text';

class WillingnessToChangeCircle extends PureComponent {
  renderText() {
    return <div className="label" dangerouslySetInnerHTML={{ __html: TEXT.resultPage.willingnessToChange }} />;
  }

  render() {
    return (
      <div>
        {this.props.textAboveCircle ? this.renderText() : ''}
        <div className="progressbar">
          <CircularProgressbar
            percentage={this.props.willingnessToChange}
            text={`${Math.floor(this.props.willingnessToChange)}%`}
            strokeWidth={10}
          />
        </div>
        {!this.props.textAboveCircle ? this.renderText() : ''}
      </div>
    );
  }
}
WillingnessToChangeCircle.defaultProps = {
  textAboveCircle: true
};

WillingnessToChangeCircle.propTypes = {
  willingnessToChange: PropTypes.number.isRequired,
  textAboveCircle: PropTypes.bool
};

export default WillingnessToChangeCircle;
