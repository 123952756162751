import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import searchSupportIcon from './icons/bookingOptionSearchSupport.svg';
import activeSearchIcon from './icons/bookingOptionActiveSearch.svg';
import advertIcon from './icons/bookingOptionAdvert.svg';
import searchSupportIconDisabled from './icons/bookingOptionSearchSupportDisabled.svg';
import activeSearchIconDisabled from './icons/bookingOptionActiveSearchDisabled.svg';
import advertIconDisabled from './icons/bookingOptionAdvertDisabled.svg';

import './BookingOptionIconsStyles.css';

const BookingOptionIcons = ({ bookingOptions, onClick, allButtonsDisabled }) => {
    function renderIcon(enabled, iconEnabled, iconDisabled, name) {
        return (
            <Button disabled={allButtonsDisabled} className="booking-option-icon" onClick={onClick} style={{textTransform: 'none'}}>
                <div className={enabled ? 'booking-option-enabled' : 'booking-option-disabled'}>
                    {enabled ? <img src={iconEnabled} alt={`icon ${name} aktiv`} /> : <img src={iconDisabled} alt={`icon ${name} inaktiv`} />}
                    <p>{name}</p>
                </div>
            </Button>
        )
    }

    let icons = [
        renderIcon(bookingOptions.searchSupport, searchSupportIcon, searchSupportIconDisabled, 'Search Service'),
        renderIcon(bookingOptions.activeSearch, activeSearchIcon, activeSearchIconDisabled, 'Active Sourcing'),
        renderIcon(bookingOptions.advert, advertIcon, advertIconDisabled, 'Anzeigenbundle')
    ]

    // add keys
    icons = icons.map((option, index) => {
        return {
            ...option,
            key: `bookingOption${index}`
        };
    })
      
    return (
        <>
        {icons}
        </>
    )
}

BookingOptionIcons.propTypes = {
    bookingOptions: PropTypes.object,
    onClick: PropTypes.func,
    allButtonsDisabled: PropTypes.bool
};

BookingOptionIcons.defaultProps = {
    bookingOptions: {
        searchSupport: false,
        activeSearch: false,
        advert: false
    },
    onClick: () => {},
    allButtonsDisabled: false
}

export default BookingOptionIcons;