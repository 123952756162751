import { useState } from 'react';
import useErrorHandler from './useErrorHandler';

export default function useLoading(initial) {
  const handleError = useErrorHandler();
  const [loading, setLoading] = useState(initial);
  return [
    loading,
    (cb, returnPromise) => (...args) => {
      setLoading(true);
      const result = Promise.resolve(cb(...args))
        .catch(handleError)
        .finally(() => setLoading(false));
      if (returnPromise) {
        return result;
      }
      return undefined; // Nur für Linter
    }
  ];
}
