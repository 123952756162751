import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';

export default function NotificationCategory({ title, children }) {
  return (
    <Grid container spacing={1} item xs={12}>
      <Grid item xs={12}>
        <Box pb={1}>
          <Typography variant="h6" component="h6">
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box pb={2}>{children}</Box>
      </Grid>
    </Grid>
  );
}

NotificationCategory.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};
