import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import validator from 'validator';

import CompanyFiles from './CompanyFiles';

export default function CompanyInformation({ profileData, loading, update, setProfileData }) {
  const [editable, setEditable] = useState(false);
  const [textFieldErrorText, setTextFieldErrorText] = useState('');

  const MINIMUM_CHARS = 255;

  const saveProfile = async () => {
    await update(profileData);
    setEditable(false);
  };

  const isCompanyTextFieldValid = () => {
    const isValid = validator.isLength(profileData.description, MINIMUM_CHARS);
    if (!isValid)
      setTextFieldErrorText(
        `Der Text sollte mindestens ${MINIMUM_CHARS} Zeichen lang sein um Ihnen eine bessere Chance bei Kandidaten zu geben`
      );
    else setTextFieldErrorText('');
    return isValid;
  };

  const generateHelperText = () => {
    if (textFieldErrorText.length > 0)
      return `${textFieldErrorText} (${
        profileData.description ? profileData.description.length : 0
      } von mindestens ${MINIMUM_CHARS} Zeichen)`;
    return `Bitte beschreiben Sie ihr Unternehmen (${
      profileData.description ? profileData.description.length : 0
    } von mindestens ${MINIMUM_CHARS} Zeichen)`;
  };

  return (
    <form
      className="mt-3"
      onSubmit={ev => {
        ev.preventDefault();
        if (isCompanyTextFieldValid()) saveProfile();
      }}
    >
      <TextField
        label="Beschreibung"
        InputLabelProps={{ shrink: true }}
        multiline
        rows={4}
        rowsMax={16}
        fullWidth
        InputProps={editable ? undefined : { readOnly: true, disableUnderline: true }}
        placeholder="Was macht Ihr Unternehmen? Was ist ihr Produkt? Ihre Mission? Worauf legen Sie wert?
        Wie viele Mitarbeiter haben Sie? An welchen Standorten sind Sie vertreten? 
        Warum sollte ein Kandidat zu Ihrem Unternehmen wechseln? Wir erwarten keinen Roman. Wir benötigen nur einen kurzen überzeugenden Text, mit dem wir Kandidaten von Ihnen begeistern können. 
        "
        error={textFieldErrorText.length > 0}
        helperText={generateHelperText()}
        FormHelperTextProps={{ error: textFieldErrorText.length > 0 }}
        value={profileData.description}
        onChange={ev => setProfileData({ ...profileData, description: ev.target.value })}
      />
      <div className="text-right mt-3">
        {editable ? (
          <>
            <Button
              style={{ marginRight: '1em' }}
              onClick={ev => {
                ev.preventDefault();
                setEditable(false);
              }}
            >
              Abbrechen
            </Button>
            <Button type="submit" color="primary" variant="contained" disabled={loading}>
              Speichern
            </Button>
          </>
        ) : (
          <Button
            color="primary"
            disabled={loading}
            onClick={ev => {
              ev.preventDefault();
              setEditable(true);
            }}
          >
            Bearbeiten
          </Button>
        )}
      </div>
      <CompanyFiles profileData={profileData} loading={loading} />
    </form>
  );
}

CompanyInformation.propTypes = {
  loading: PropTypes.bool.isRequired,
  profileData: PropTypes.object.isRequired,
  setProfileData: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};
