import * as React from 'react';
import PropTypes from 'prop-types';
import BookingOption from './BookingOption';
import SearchSupportIcon from './icons/bookingOptionSearchSupport.svg';
import ActiveSearchIcon from './icons/bookingOptionActiveSearch.svg';
import AdvertIcon from './icons/bookingOptionAdvert.svg';

import './BookingOptionSelectionStyles.css';

// readOnly disables all, readOnlyOptions only the given options
// readOnly overrules readOnlyOptions
const BookingOptionSelection = ({ value, onChange, readOnlyOptions, readOnly }) => {
    let tmpBookingOptions = value;

    if (tmpBookingOptions === undefined) {
        tmpBookingOptions = {};
    }

    const getOnCheckboxChangeCallback = (field) => (newCheckState) => {
        if (onChange) {
            const resultingOptions = {...tmpBookingOptions};
            resultingOptions[field] = newCheckState
            onChange(resultingOptions);
        }
    }

    const bookingOptionData = {
        searchSupport: {
            title: 'Search Service',
            description: 'Sie haben die offene Position beschrieben, aber keine Zeit zu suchen? Wir finden für Sie passgenaue Kandidaten auf unserer Plattform. Wir entwickeln anhand Ihres Anforderungsprofils zielführende Suchstrings, sprechen die idealen Kandidaten an und klären Wechselbereitschaft und Gehaltswünsche ab. Sie können sich zurücklehnen und erhalten die vollständigen Unterlagen interessierter Kandidaten.',
            price: 990,
            icon: SearchSupportIcon
        },
        activeSearch: {
            title: 'Active Sourcing',
            description: 'Wir identifizieren für Sie auf anderen Kanälen und Plattformen über 100 weitere passende Kandidaten. Denn viele Talente befinden sich bereits in Lohn und Brot und sind im Moment nicht aktiv auf der Suche nach einer neuen Position. Via Active Sourcing auf Xing, LinkedIn, Experteer, CV-Datenbanken, etc. wecken wir das Interesse für den nächsten Karriereschritt, den Sie mit Ihrer offenen Position bieten. Ohne weiteren Zeitaufwand erhalten Sie vollständige Unterlagen interessierter Kandidaten.',
            price: 1990,
            icon: ActiveSearchIcon
        },
        advert: {
            title: 'Anzeigenbundle',
            description: 'Mehr Reichweite für Ihr Stellenangebot! Je nach Zielgruppe kann es sinnvoll sein, eine Stellenanzeige gleichzeitig auf mehreren Stellenbörsen zu schalten. Die Lösung: Unsere Anzeigenbundle. In unseren Anzeigenpaketen kombinieren wir verschiedene Kanäle um noch mehr passende Kandidaten zu erreichen. Hierzu zählen Xing, LinkedIn, Indeed, Facebook, Arbeitsagentur, Experteer, Kimeta, regionale Stellenbörsen und 20 weitere Kanäle. Ihr Vorteil: Niedrige Kosten, mehr Kandidaten und für Sie kein Zeitaufwand!',
            price: 990,
            icon: AdvertIcon
        }
    }

    let sumPrice = 0;

    Object.keys(tmpBookingOptions).forEach((key) => {
        if (tmpBookingOptions[key]) {
            sumPrice += bookingOptionData[key].price;
        }
    })

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <BookingOption 
                        title={bookingOptionData.searchSupport.title}
                        description={bookingOptionData.searchSupport.description}
                        disabled={readOnly || readOnlyOptions.includes('searchSupport')}
                        iconSrc={bookingOptionData.searchSupport.icon}
                        price={bookingOptionData.searchSupport.price}
                        checked={tmpBookingOptions.searchSupport}
                        onChange={getOnCheckboxChangeCallback('searchSupport')} />
                </div>
                <div className="col">
                    <BookingOption 
                        title={bookingOptionData.activeSearch.title}
                        description={bookingOptionData.activeSearch.description}
                        disabled={readOnly || readOnlyOptions.includes('activeSearch')}
                        iconSrc={bookingOptionData.activeSearch.icon}
                        price={bookingOptionData.activeSearch.price}
                        checked={tmpBookingOptions.activeSearch}
                        onChange={getOnCheckboxChangeCallback('activeSearch')} />
                </div>
                <div className="col">
                    <BookingOption 
                        title={bookingOptionData.advert.title}
                        description={bookingOptionData.advert.description}
                        disabled={readOnly || readOnlyOptions.includes('advert')}
                        iconSrc={bookingOptionData.advert.icon}
                        price={bookingOptionData.advert.price}
                        checked={tmpBookingOptions.advert}
                        onChange={getOnCheckboxChangeCallback('advert')} />
                </div>
            </div>
            <div className="row">
                <div className="col bookingSummary">
                    Gesamtpreis: {sumPrice},-
                </div>
            </div>
        </div>
    )
}

BookingOptionSelection.propTypes = {
    value: PropTypes.object.isRequired,
    readOnlyOptions: PropTypes.array,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};

BookingOptionSelection.defaultProps = {
    readOnlyOptions: [],
    onChange: null,
    readOnly: false
}

export default BookingOptionSelection;