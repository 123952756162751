import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DialogWrapper from '../generic_components/DialogWrapper';
import DrawerWrapper from '../generic_components/DrawerWrapper';
import ProjectSelectorDropDown from './ProjectSelectorDropDown';
import ProjectDetails from './profile/ProjectDetails';

import { getProjectById } from '../api/projects';
import useLoading from '../utils/useLoading';

export default function ProjectSelectorWrapper({ handleClose, handleSave, projectSelectorOpen }) {
  const [selectedProjectId, setSelectedProjectId] = React.useState();
  const [selectedProject, setSelectedProject] = React.useState();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [loading, wrapAsyncLoading] = useLoading();

  React.useEffect(
    wrapAsyncLoading(async () => {
      if (!selectedProject && selectedProjectId) {
        const project = await getProjectById(selectedProjectId);
        setSelectedProject(project);
      }
    }),
    [selectedProjectId]
  );

  React.useEffect(() => {
    if (!projectSelectorOpen) {
      setSelectedProjectId('');
    }
  }, [projectSelectorOpen]);

  return (
    <>
      <DialogWrapper
        open={projectSelectorOpen}
        dialogTitle="Auftrag auswählen"
        maxWidth="sm"
        fullWidth
        handleClose={handleClose}
        handleSave={() => {
          if (selectedProjectId && selectedProject) {
            handleSave(selectedProject);
          } else {
            setDrawerOpen(true);
          }
        }}
        acceptButtonName={
          selectedProjectId && selectedProject ? (
            'Übernehmen'
          ) : (
            <>
              <CreateNewFolderIcon />
              <span style={{ paddingLeft: '10px' }} />
              Neuen Auftrag Anlegen
            </>
          )
        }
        cancelButtonName="Abbrechen"
      >
        {loading ? (
          <div className="d-flex align-items-center">
            <CircularProgress /> <div className="ml-3">Liste wird geladen...</div>
          </div>
        ) : (
          <ProjectSelectorDropDown setSelectedProject={setSelectedProject} onChange={setSelectedProjectId} value={selectedProjectId} />
        )}
      </DialogWrapper>
      <DrawerWrapper title="Neues Stellengesuch anlegen" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <ProjectDetails
          projectId={selectedProjectId}
          useAsTemplate={false}
          onSave={async projectId => {
            setSelectedProjectId(projectId);
            setDrawerOpen(false);
          }}
          onClose={() => {
            setSelectedProjectId(undefined);
            setDrawerOpen(false);
          }}
        />
      </DrawerWrapper>
    </>
  );
}

ProjectSelectorWrapper.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  projectSelectorOpen: PropTypes.bool.isRequired
};
