import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  ListItemAvatar,
  Avatar,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Divider,
  ExpansionPanelActions,
  Badge,
  withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useLoading from '../../utils/useLoading';
import ProjectStatus from './ProjectStatus';
import { getProjectCandidateList } from '../../api/projects';
import ProjectAvatar from './ProjectAvatar';
import BookingOptionIcons from '../bookingOptions/BookingOptionIcons';

import './ProjectSummaryPanelStyles.css'

const StyledBadge = withStyles(theme => ({
  badge: {
    top: '50%',
    right: -20,
    // The border color match the background color.
    border: `2px solid ${theme.palette.grey[500]}`,
    color: 'white',
    backgroundColor: theme.palette.grey[500]
  }
}))(Badge);

const stopPropagation = (e) => e.stopPropagation();
const InputWrapper = ({ children }) =>
  // eslint-disable-next-line
  <div onClick={stopPropagation}>
    {children}
  </div>

InputWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const ProjectSummaryPanel = ({ children, project, expanded, onExpand, bookingOptionsDisabled, onClickBookingOptions }) => {
  const [loading, wrapAsyncLoading] = useLoading();
  const [candidateList, setCandidateList] = useState();

  const loadCandidateList = async () => {
    setCandidateList((await getProjectCandidateList(project.id)).candidates);
  };
  React.useEffect(
    wrapAsyncLoading(async () => {
      if (expanded) {
        await loadCandidateList();
      }
    }),
    [expanded, project.id]
  );

  return (
    <div>
      <ExpansionPanel className="mb-2" expanded={expanded && !loading} onChange={onExpand} TransitionProps={{ unmountOnExit: true }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <ListItemAvatar className="ml-n2 position-relative">
            {loading ? (
              <Avatar>
                <CircularProgress size={20} />
              </Avatar>
            ) : (
              <ProjectAvatar status={project.status} />
            )}
          </ListItemAvatar>
          <div className="d-flex flex-sm-wrap flex-xs-wrap" style={{width: '100%'}}>
            <div  style={{flexGrow: 1}}>
              <StyledBadge badgeContent={candidateList ? candidateList.length : project.candidateCount}>
                <Typography>{project.title}</Typography>
              </StyledBadge>
              <Typography variant="body2" color="textSecondary">
                {project.positionFunction}
                {project.address && `, ${project.address.postalCode} ${project.address.city}`}
              </Typography>
            </div>
            <div className="booking-option-icons" style={{flexBasis: 0, whiteSpace: 'nowrap'}}>
              <InputWrapper>
                <BookingOptionIcons
                  allButtonsDisabled={bookingOptionsDisabled}
                  bookingOptions={project.bookingOptions} 
                  onClick={onClickBookingOptions}/>
              </InputWrapper>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ProjectStatus
            projectId={project.id}
            candidateList={candidateList}
            showChart={project.status !== 'Noch nicht freigegeben'}
            onUpdate={loadCandidateList}
          />
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>{children}</ExpansionPanelActions>
      </ExpansionPanel>
    </div>
  );
};

ProjectSummaryPanel.propTypes = {
  children: PropTypes.node.isRequired,
  project: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  bookingOptionsDisabled: PropTypes.bool.isRequired,
  onClickBookingOptions: PropTypes.func.isRequired
};

export default ProjectSummaryPanel;
