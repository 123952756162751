import React, { Component } from 'react';
import './Results.css';
import $ from 'jquery';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';
import { isWidthUp } from '@material-ui/core/withWidth';
import Chip from '@material-ui/core/Chip';

import withUser from '../utils/withUser';
import * as constants from '../constants';
import * as CONFIG from '../config';
import * as FILTER_HELPER from '../utils/filterHelperFunctions';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';
import AllSearchResults from '../components/AllSearchResults';
import MainFilterArea from '../components/filter/MainFilterComponent';
import JobFilterArea from '../components/filter/JobFilterComponent';
import StickyFilterArea from '../components/filter/StickyFilterComponent';
import SaveSearchBox from '../components/SaveSearchBox';
import { getProjectList } from '../api/projects';
import InfoIcon from '../generic_components/InfoIcon';
import staticInfoText from '../staticInfoText';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      showPage: false,
      projectCandidates: [],
      otherProjectCandidateIDs: [],
      showLoadingIndicator: true,
      sortBy: 'Passgenauigkeit',
      sortDescending: true,
      countedJobParameters: {},
      filters: {
        matchPercentage: {
          parameters: 0,
          filterFunction: undefined,
          isSet: false,
          wasLoaded: false
        },
        age: {
          parameters: [15, 75],
          filterFunction: undefined,
          isSet: false,
          wasLoaded: false
        },
        name: {
          parameters: '',
          filterFunction: undefined,
          isSet: false,
          wasLoaded: false
        },
        companyName: {
          parameters: '',
          filterFunction: undefined,
          isSet: false
        },
        willingnessToChange: {
          parameters: [0, 100],
          filterFunction: undefined,
          isSet: false
        },
        salary: {
          parameters: [20, 200],
          filterFunction: undefined,
          isSet: false
        },
        willingnessToRelocate: {
          parameters: 'all',
          filterFunction: undefined,
          isSet: false
        },
        // TODO it may require refactoring
        showMarked: {
          parameters: false,
          filterFunction: undefined,
          isSet: false
        },
        distance: {
          parameters: [0, this.props.distance],
          filterFunction: undefined,
          isSet: false
        },
        // job filters
        durationCurrentJob: {
          parameters: [], // possible labels ['0-1', '1-2', '2-5', '5-10', '10-100'],
          filterFunction: undefined,
          isSet: false
        },
        currentPosition: {
          parameters: [],
          filterFunction: undefined,
          isSet: false
        },
        industrySectorName: {
          parameters: [],
          filterFunction: undefined,
          isSet: false
        },
        currentEmployer: {
          // fiters on values of results.jobs[0].company
          parameters: [],
          filterFunction: undefined,
          isSet: false
        },
        workExperience: {
          // fiters on values of results.workExperience
          parameters: [], // same default label as DurationCurrentJobFilter
          filterFunction: undefined,
          isSet: false
        }
      },
      numberOfShownFilterOption: {},
      filterTextfieldValues: {}
    };

    this.initialFilters = $.extend(true, {}, this.state.filters);
    this.initialResults = [];
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.sortCountJobParameters = FILTER_HELPER.sortCountJobParameters.bind(this);
    this.handleResetFiltersButtonClick = this.handleResetFiltersButtonClick.bind(this);
    this.scrollParentRef = React.createRef();
    if ((this.props.selectedProjectHandler.selectedProject || {}).id) {
      this.loadMarkedCandidates((this.props.selectedProjectHandler.selectedProject || {}).id);
    }
  }

  componentDidMount() {
    document.title = `Suchergebnisse - ${UTILS.getTitle()}`;

    const query = this.props.query;

    if (query && query.length) this.getSearchResults();
    else window.location = '/search';
  }

  componentDidUpdate(prevProps) {
    const prevId = (prevProps.selectedProjectHandler.selectedProject || {}).id;
    const newId = (this.props.selectedProjectHandler.selectedProject || {}).id;
    if (prevId !== newId) {
      this.loadMarkedCandidates(newId);
    }
  }

  loadMarkedCandidates(projectId) {
    if (!projectId) {
      this.setState({
        projectCandidates: [],
        otherProjectCandidateIDs: []
      });
      return;
    }
    getProjectList()
      .then(projectList => {
        const project = projectList.find(prj => prj.id === projectId) || {};
        this.setState({
          projectCandidates: project.candidates || [],
          otherProjectCandidateIDs: projectList
            .filter(prj => prj.id !== projectId)
            .reduce((agg, prj) => [...agg, ...(prj.candidates || []).map(c => c.id)], [])
        });
      })
      .catch(this.errorHandling);
  }

  liftUpFilteredOptions = (filterName, filteredOptionsValue) => {
    const filterTextfieldValues = JSON.parse(
      JSON.stringify(this.state.filterTextfieldValues) // eslint-disable-line
    );
    filterTextfieldValues[filterName] = filteredOptionsValue;
    this.setState({ filterTextfieldValues }, this.updateCountedJobParameters); //
  };

  isAtLeastOneFilterSet() {
    const { filters } = this.state;
    const isSetArray = [];
    Object.keys(filters).forEach(filterName => {
      const isSet = filters[filterName].isSet;
      isSetArray.push(isSet);
    });
    return isSetArray.some(element => element);
  }

  showMoreFilterOptions = filterName => {
    this.setState(
      prevState => ({
        numberOfShownFilterOption: {
          ...prevState.numberOfShownFilterOption,
          [filterName]: prevState.numberOfShownFilterOption[filterName] ? prevState.numberOfShownFilterOption[filterName] + 10 : 20
        }
      }),
      this.updateCountedJobParameters
    );
  };

  /**
   * Metod which should be called by any filter on onChangePage
   * @param  {string} filterName     Name of the Filter
   * @param  {Any} parameters     Parameter(s) of the Filter
   * @param  {function} filterFunction Filterfunction, which takes 2 parameters:
   *         first the result on which should be filtered, second the Filter Parameters
   */
  onFilterChange = (filterName, parameters, filterFunction) => {
    const startTime = new Date().getTime();
    // in case filter is applied for the first time
    if (!this.state.filters[filterName].isSet) {
      this.setState(
        prevState => ({
          results: prevState.results.filter(this.filterResults.bind(this, filterName, parameters, filterFunction, null, true)),
          filters: {
            ...prevState.filters,
            [filterName]: { parameters, filterFunction, isSet: true }
          }
        }),
        () => {
          this.updateCountedJobParameters();
          console.log('Time needed for filtering:', new Date().getTime() - startTime);
        }
      );
    }
    // in case filter has been applied before
    else {
      // consider filter as isSet = false in case parameters are not available
      const isSet = {};
      isSet[filterName] = true;
      if (!parameters || parameters.length === 0) {
        isSet[filterName] = false;
      }
      this.setState(
        prevState => ({
          results: this.sort(
            this.initialResults.filter(this.filterResults.bind(this, filterName, parameters, filterFunction, null, false)),
            prevState.sortBy,
            prevState.sortDescending
          ),
          filters: {
            ...prevState.filters,
            [filterName]: {
              parameters,
              filterFunction,
              isSet: isSet[filterName]
            }
          }
        }),
        () => {
          this.updateCountedJobParameters();
          console.log('Time needed for filtering:', new Date().getTime() - startTime);
        }
      );
    }
  };

  /**
   * Checks from where the Resultpage was called
   * @return {[type]} [description]
   */
  getSearchResults = () => {
    console.log(this.props.calledFrom);
    switch (this.props.calledFrom) {
      case 'searchPage':
        this.getSearchResultsBySearchQueryAndSavePastSearch();
        break;
      case 'markLists':
        this.getSearchResultsByIds();
        break;
      default:
        this.getSearchResultsBySearchQueryAndSavePastSearch();
        break;
    }
  };

  getSearchResultsByIds = () => {
    const request = {
      query: this.props.query
    };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    this.setState({ showPage: true, showLoadingIndicator: true });
    const liftUpObj = {
      showLoadingIndicatorGlobal: true,
      sidePanelContent: undefined
    };
    this.props.liftUpStateToApp(liftUpObj);
    $.ajax({
      url: constants.getSearchResultsByMarkListIdURL,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        const sortedResults = response.results.sort((a, b) => {
          if (a.score > b.score) return -1;
          if (a.score < b.score) return 1;
          return 0;
        });

        this.initialResults = sortedResults;
        const countedJobParametersResult = this.countJobParameters(sortedResults);
        const countedJobParameters = countedJobParametersResult.sortedCountJobParameters;
        const filteredResults = countedJobParametersResult.filteredResults;
        this.setState({
          results: filteredResults,
          showLoadingIndicator: false,
          countedJobParameters
        });
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false,
          filters: undefined
        });
      })
      .fail(err => {
        this.errorHandling(err);
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false
        });
        this.setState({
          showLoadingIndicator: false
        });
      });
  };

  /**
   * JQuery Ajax
   * @param {Object} requestPackage
   */
  getSearchResultsBySearchQueryAndSavePastSearch = () => {
    const request = {
      query: this.props.query,
      rating: this.props.rating,
      postalCode: this.props.postalCode,
      selectedProjectId: (this.props.selectedProjectHandler.selectedProject || {}).id
      
    };
   
    const maxUpperBound = 240; // so that the distance, lat, lon are not ignored
    const minLowerBound = 0; // so that the distance, lat, lon are not ignored
    if (this.props.distance <= maxUpperBound && this.props.distance !== minLowerBound && this.props.lat && this.props.lon) {
      request.distance = this.props.distance;
      request.lat = this.props.lat;
      request.lon = this.props.lon;
    }
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = constants.getSearchResultsURL;
    this.setState({ showPage: true, showLoadingIndicator: true });
    const liftUpObj = {
      showLoadingIndicatorGlobal: true,
      sidePanelContent: undefined
    };
    this.props.liftUpStateToApp(liftUpObj);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        console.log(`API ${url} response searchResults:`, response);
        const sortedResults = response.results.sort((a, b) => {
          if (a.score > b.score) return -1;
          if (a.score < b.score) return 1;
          return 0;
        });
        this.initialResults = sortedResults;

        let filteredResults;
        let countedJobParametersResult;

        if (this.props.filters) {
          Object.keys(this.props.filters).forEach(key => {
            if (this.props.filters[key].isSet) {
              this.props.filters[key].filterFunction = FILTER_HELPER.filterFunctions[key];
            }
          });
          filteredResults = sortedResults.filter(this.initialFilter);

          countedJobParametersResult = this.countJobParameters(filteredResults, true);
        } else {
          countedJobParametersResult = this.countJobParameters(sortedResults);
        }

        // console.log('DEBUG initialResults', this.initialResults);
        const countedJobParameters = countedJobParametersResult.sortedCountJobParameters;
        filteredResults = countedJobParametersResult.filteredResults;

        this.setState({
          results: filteredResults,
          showLoadingIndicator: false,
          countedJobParameters,
          filters: this.props.filters ? this.props.filters : this.initialFilters
        });
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false,
          filters: undefined
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false
        });
        this.setState({
          showLoadingIndicator: false
        });
      });
  };

  /**
   * Filters through all filters, which are active and only returns true, if all of them are true
   * @param  {string} newSetFilterName       the Name of the filter, which triggered the function
   * @param  {Any} newSetFilterParameters the parameters of the filter, which triggered the function
   * @param  {function} newSetFilterFunction   the function of the filter, which triggered the function
   * @param {string} ignoredFilter filter name which should be ignored for fitering
   * @param  {bool} isFilterNew                 true, if the filter was triggered the first time
   * @param  {object} result                 the object which is tested in the filter method
   * @return {bool}                        true, if the result matches all the filter criteria
   */
  filterResults(newSetFilterName, newSetFilterParameters, newSetFilterFunction, ignoredFilter, isFilterNew, result) {
    if (isFilterNew) return newSetFilterFunction(result, newSetFilterParameters);

    const filterKeyArray = Object.keys(this.state.filters);

    // ignore filter option is used for countJobParameters
    if (ignoredFilter) {
      const index = filterKeyArray.indexOf(ignoredFilter);
      if (index !== -1) filterKeyArray.splice(index, 1);
    }

    let resultShouldStayInArray = true;
    // no-restriced-syntax disabled, as only for...of and for...in loops support breaks
    // eslint-disable-next-line
    for (const filterKey of filterKeyArray) {
      const filter = this.state.filters[filterKey];
      // eslint-disable-next-line
      if (!filter || !filter.isSet || !filter.filterFunction) continue;

      const filterFunction = filterKey === newSetFilterName ? newSetFilterFunction : filter.filterFunction;
      const parameters = filterKey === newSetFilterName ? newSetFilterParameters : filter.parameters;
      if (!filterFunction(result, parameters)) {
        resultShouldStayInArray = false;
        break;
      }
    }
    return resultShouldStayInArray;
  }

  initialFilter = result => {
    const filterObject = this.props.filters;

    let resultShouldStayInArray = true;
    // no-restriced-syntax disabled, as only for...of and for...in loops support breaks
    // eslint-disable-next-line
    for (const filterKey of Object.keys(filterObject)) {
      const filter = filterObject[filterKey];

      // eslint-disable-next-line
      if (!filter || !filter.isSet || !filter.filterFunction) continue;
      const filterFunction = filter.filterFunction;
      const parameters = filter.parameters;
      if (!filterFunction(result, parameters)) {
        resultShouldStayInArray = false;
        break;
      }
    }

    return resultShouldStayInArray;
  };

  /**
   * Resets all the filters
   */
  handleResetFiltersButtonClick() {
    // TODO fix reset of job filters
    // BUG: Sortfunction syntactically incorrect
    console.log('this.initialFilters', this.initialFilters);
    this.setState(
      {
        results: this.initialResults,
        filters: this.initialFilters,
        numberOfShownFilterOption: {}
      },
      () => this.updateCountedJobParameters()
    );
  }

  /**
   * Returns a sorted Array after the setted sortBy criterion
   * used by the SortLabel components
   * @param  {string} sortBy the sort criterion
   * @return {Array}        array of sorted candidate objects
   */
  sort(results, sortBy, sortDescending) {
    const unsortedResults = results;
    let sortedArray = unsortedResults;
    // enter the switch block using the sort criterion passed in the Parallel object instance
    switch (sortBy) {
      case TEXT.resultPage.sortingOptions.matchPercentage:
        sortedArray = unsortedResults.sort((a, b) => {
          if (a.score > b.score) return -1;
          if (a.score < b.score) return 1;
          return 0;
        });
        break;
      case TEXT.resultPage.sortingOptions.willingnessToChange:
        sortedArray = unsortedResults.sort((a, b) => {
          if (a.willingnessToChange > b.willingnessToChange) return -1;
          if (a.willingnessToChange < b.willingnessToChange) return 1;
          return 0;
        });
        break;
      case TEXT.resultPage.sortingOptions.postalcode:
        sortedArray = unsortedResults.sort((a, b) => {
          let res = 0;
          if (a.distance > b.distance) {
            res = -1;
          } else if (a.distance < b.distance) {
            res = 1;
          }
          return res;
        });
        break;
      case TEXT.resultPage.sortingOptions.age:
        sortedArray = unsortedResults.sort((a, b) => {
          let res = 0;
          if (a.age > b.age) {
            res = -1;
          } else if (a.age < b.age) {
            res = 1;
          }
          return res;
        });
        break;
      default:
        break;
    }
    // check if the array should be diplayed ascending or descending
    sortedArray = sortDescending ? sortedArray : sortedArray.reverse();
    return sortedArray;
  }

  /**
   * Method which is called when sorting is triggered
   * @param  {string} sortBy name of the sorting function
   */
  sortResults = sortBy => {
    // set default sort direction as descending
    let sortDescending = true;
    // if the sort follows the same criterion then toggle the direction (desc || asc)
    if (this.state.sortBy === sortBy) {
      sortDescending = !this.state.sortDescending;
    }
    this.setState(prevState => ({
      results: this.sort(prevState.results, sortBy, sortDescending),
      sortBy,
      sortDescending
    }));
  };

  handleBoostButtonClick = searchTermContent => {
    const boostArray = this.props.query;
    boostArray.forEach((column, columnIndex) => {
      column.forEach((searchTerm, searchTermIndex) => {
        if (searchTerm.searchTerm === searchTermContent) {
          if (searchTerm.boost !== 10) boostArray[columnIndex][searchTermIndex].boost = 10;
          else boostArray[columnIndex][searchTermIndex].boost = 1;
        }
      });
    });
    this.forceUpdate(); // force update for color change in boost buttons

    this.props.liftUpStateToApp('searchArray', boostArray);
  };

  /**
   * Create util objects of job parameters with respective values and counts for all job filters
   * @param  {object} results current state of objects (current filters already applied)
   * @return {object} object of job parameters with respective values and counts for the job filters
   */
  countJobParameters(results, skipInitialResults) {
    const startTime = new Date().getTime(); // for debugging of time duration
    // prepare jobParameterCount object with default values
    const jobParameterCount = {
      duration: { '0-1': 0, '1-2': 0, '2-5': 0, '5-10': 0, '10-100': 0 }, // filter durationCurrentJob
      jobText: {}, // filter currentPosition
      workExperience: { '0-1': 0, '1-2': 0, '2-5': 0, '5-10': 0, '10-100': 0 }, // 'workExperience',
      branches: {}, // filter industry
      company: {} // filter 'currentEmployer'
    };
    const jobProperties = Object.keys(jobParameterCount);
    let filteredResults = results;
    jobProperties.forEach(jobProperty => {
      const ignoredFilter = FILTER_HELPER.mapJobPropertyToFilterName(jobProperty);

      if (ignoredFilter) {
        if (skipInitialResults) {
          filteredResults = results.filter(this.filterResults.bind(this, null, null, null, ignoredFilter, false));
        } else {
          filteredResults = this.initialResults.filter(this.filterResults.bind(this, null, null, null, ignoredFilter, false));
        }
      }

      filteredResults.forEach(element => {
        // check if values exist in BE response
        if (jobProperty === 'workExperience' && !element.workExperience) return;
        if (jobProperty !== 'workExperience' && (!element.jobs[0] || !element.jobs[0][jobProperty])) return;

        // get rawValue for filter/count from element of results
        let rawValue;
        if (jobProperty === 'workExperience') {
          rawValue = element.workExperience;
        } else rawValue = element.jobs[0][jobProperty];
        let value;
        if (jobProperty === 'duration' || jobProperty === 'workExperience') {
          // round raw value from milliseconds to months
          const months = UTILS.convertMillisecondsToMonths(rawValue);
          value = FILTER_HELPER.mapMonthsToYearRange(months);
        } else if (jobProperty === 'branches') {
          if (Array.isArray(rawValue)) value = rawValue[0];
          else value = rawValue;
          if (!value) return;
        } else {
          // direct mapping for currentPosition and currentEmployer filters
          value = rawValue;
        }

        // filter with filteredOptions
        // for currentPosition
        let allowedFilterOption = false;
        if (jobProperty === 'jobText') {
          const filteredOptions = this.state.filterTextfieldValues.currentPosition
            ? this.state.filterTextfieldValues.currentPosition.toLowerCase()
            : this.state.filterTextfieldValues.currentPosition;

          if (filteredOptions && filteredOptions.length > 0) {
            const valueIsValidOption = value.toLowerCase().indexOf(filteredOptions);
            if (valueIsValidOption >= 0) allowedFilterOption = true;
          } else allowedFilterOption = true;
        }

        // logic for filter search field filteredOptions
        if (jobProperty !== 'jobText' || allowedFilterOption === true) {
          if (!jobParameterCount[jobProperty][value]) {
            // Initial job property and set count to 1
            jobParameterCount[jobProperty][value] = 1;
          } else {
            // Same occurrences found. Increase the existing count by one
            jobParameterCount[jobProperty][value]++;
          }
        }
      });
    });
    // sort and slice jobParameterCount object for each job property
    const sortedCountJobParameters = this.sortCountJobParameters(jobParameterCount, jobProperties, this.state.numberOfShownFilterOption);

    const endTime = new Date().getTime();
    const diffTime = endTime - startTime;
    console.log('time needed for sortedCountJobParameters', diffTime);
    return { sortedCountJobParameters, filteredResults };
  }

  /**
   * method to update the jobCountParamters object to update the count for the job filters after filtering
   * get current results object from state and save the updated jobCountParamters object
   * to state which will cause a rerender of the counts in the job filters
   */
  updateCountedJobParameters() {
    const results = this.state.results;
    const countedJobParameters = this.countJobParameters(results).sortedCountJobParameters;
    this.setState({ countedJobParameters });
  }

  renderBoostArea = () => {
    if (this.props.calledFrom === 'markLists') return '';
    return (
      <div className="m-2 border-bottom">
        <div className="row">
          <div className="col-12">
            <h4 className="p-2 text-center">{TEXT.resultPage.boostYourResults}</h4>
            <p>{TEXT.resultPage.boostDescription}</p>
            <div className="row">{this.renderBoosterButtons()}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center text-md-right mb-3">
            <Button variant="contained" color="primary" onClick={this.getSearchResults}>
              {TEXT.resultPage.adoptChanges}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderBoosterButtons = () => {
    const buttonArray = [];
    this.props.query.forEach(column => {
      column.forEach(searchTerm => {
        buttonArray.push(
          <Chip
            key={searchTerm.searchTerm}
            color={searchTerm.boost > 1 ? 'secondary' : 'default'}
            onClick={() => this.handleBoostButtonClick(searchTerm.searchTerm)}
            disabled={this.state.isButtonDisabled}
            label={searchTerm.searchTerm}
            className="boostChip"
          />
        );
      });
    });
    return buttonArray;
  };

  render() {
    const numberOfShownFilterOptionForCurrentPositionFilter = this.state.numberOfShownFilterOption.currentPosition
      ? this.state.numberOfShownFilterOption.currentPosition + 10
      : 10;

    if (!this.state.showPage) return '';
    return (
      <div className="container-fluid container-results" ref={this.scrollParentRef}>
        <div className="row">
          <div className="col-md-4 col-lg-3  col-xl-2" id="filterColumn">
            <InfoIcon tooltip={staticInfoText.search.filter} />
            {this.isAtLeastOneFilterSet() && (
              <Button color="secondary" onClick={this.handleResetFiltersButtonClick} disabled={this.state.isButtonDisabled}>
                {TEXT.resultPage.resetFiltersButton}
                <i className="material-icons">delete</i>
              </Button>
            )}
            <ExpansionPanel defaultExpanded={isWidthUp('md', this.props.width)}>
              <ExpansionPanelSummary expandIcon={<i className="material-icons">expand_more</i>}>
                {TEXT.filter.mainFilter.headline}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="ExpansionPanelClass">
                <MainFilterArea
                  onFilterChange={this.onFilterChange}
                  name={this.state.filters.name.parameters}
                  companyName={this.state.filters.companyName.parameters}
                  matchPercentage={this.state.filters.matchPercentage}
                  age={this.state.filters.age}
                  willingnessToChange={this.state.filters.willingnessToChange}
                  salary={this.state.filters.salary.parameters}
                  willingnessToRelocate={this.state.filters.willingnessToRelocate.parameters}
                  showMarked={this.state.filters.showMarked.parameters}
                  distance={this.state.filters.distance.parameters}
                  disableDistanceFiltering={this.props.calledFrom === 'markLists'}
                  maxRange={this.props.distance === 0 ? 250 : this.props.distance}
                  markedCandidateIDs={this.state.projectCandidates.map(c => c.id)}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={isWidthUp('md', this.props.width)}>
              <ExpansionPanelSummary expandIcon={<i className="material-icons">expand_more</i>}>
                {TEXT.filter.jobFilter.headline}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="ExpansionPanelClass">
                <JobFilterArea
                  numberOfShownFilterOptionForCurrentPositionFilter={numberOfShownFilterOptionForCurrentPositionFilter}
                  showMoreFilterOptions={this.showMoreFilterOptions}
                  liftUpFilteredOptions={this.liftUpFilteredOptions}
                  onFilterChange={this.onFilterChange}
                  countedJobParameters={this.state.countedJobParameters}
                  durationCurrentJob={
                    // filter current job duration
                    this.state.filters.durationCurrentJob.parameters
                  }
                  workExperience={
                    // filter current job duration
                    this.state.filters.workExperience.parameters
                  }
                  titleCurrentJob={
                    // filter currentPosition job filter
                    this.state.filters.currentPosition.parameters
                  }
                  titleCurrentJobFilteredOptions={this.state.filterTextfieldValues.currentPosition}
                  industrySectorName={this.state.filters.industrySectorName.parameters}
                  currentEmployer={this.state.filters.currentEmployer.parameters}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {this.isAtLeastOneFilterSet() && (
              <ExpansionPanel className="visibleBox" defaultExpanded={isWidthUp('md', this.props.width)}>
                <ExpansionPanelSummary expandIcon={<i className="material-icons">expand_more</i>}>
                  {TEXT.filter.sticky.headline}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="ExpansionPanelClass">
                  <StickyFilterArea
                    filters={this.state.filters}
                    onFilterChange={this.onFilterChange}
                    countedJobParameters={this.state.countedJobParameters}
                    /* new props for inputField */
                    numberOfShownFilterOptionForCurrentPositionFilter={numberOfShownFilterOptionForCurrentPositionFilter}
                    showMoreFilterOptions={this.showMoreFilterOptions}
                    liftUpFilteredOptions={this.liftUpFilteredOptions}
                    titleCurrentJobFilteredOptions={this.state.filterTextfieldValues.currentPosition}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </div>
          <div className="result-field col">
            {this.renderBoostArea()}

            <AllSearchResults
              resultCount={this.state.results.length}
              results={this.state.results}
              projectCandidates={this.state.projectCandidates}
              otherProjectCandidateIDs={this.state.otherProjectCandidateIDs}
              showLoadingIndicator={this.state.showLoadingIndicator}
              sortResults={this.sortResults}
              sortBy={this.state.sortBy}
              sortDescending={this.state.sortDescending}
              liftUpStateToApp={this.props.liftUpStateToApp}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              disableDistanceSorting={this.props.calledFrom === 'markLists'}
              selectedProjectHandler={{
                setMark: (candidateId, markValue) => {
                  this.setState(prevState => {
                    let newprojectCandidates;
                    if (markValue) {
                      newprojectCandidates = [...prevState.projectCandidates, {id: candidateId, status: 'added'}];
                    } else {
                      newprojectCandidates = prevState.projectCandidates.filter(c => c.id !== candidateId);
                    }
                    return { projectCandidates: newprojectCandidates };
                  });
                },
                ...this.props.selectedProjectHandler
              }}
              scrollParentRef={this.scrollParentRef}
            />
          </div>
        </div>

        <SaveSearchBox
          geoLocationId={this.props.geoLocationId}
          distance={this.props.distance}
          open={this.props.saveSearchBoxOpen}
          query={this.props.query}
          ratings={this.props.rating}
          postalCode={this.props.postalCode}
          handleClose={this.props.handleSaveSearchBoxClose}
          filters={this.state.filters}
        />
      </div>
    );
  }
}

Results.defaultProps = {
  filters: undefined
};

Results.propTypes = {
  query: PropTypes.array.isRequired,
  calledFrom: PropTypes.string.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  distance: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
  filters: PropTypes.object,
  rating: PropTypes.shape({
    rating1: PropTypes.oneOf([1, 2, 3, 4, 5]),
    rating2: PropTypes.oneOf([1, 2, 3, 4, 5]),
    rating3: PropTypes.oneOf([1, 2, 3, 4, 5]),
    rating4: PropTypes.oneOf([1, 2, 3, 4, 5])
  }).isRequired,
  postalCode: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  saveSearchBoxOpen: PropTypes.bool.isRequired,
  handleSaveSearchBoxClose: PropTypes.func.isRequired,
  geoLocationId: PropTypes.string.isRequired,
  selectedProjectHandler: PropTypes.object.isRequired
};
export default withUser(Results);
