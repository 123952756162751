import * as React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@material-ui/core';

const ResponsivePaper = ({ children, className }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return <div className={`${matches ? 'MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded p-3' : ''} ${className || ''}`}>{children}</div>;
};

ResponsivePaper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

ResponsivePaper.defaultProps = {
  className: undefined
};

export default ResponsivePaper;
