/* eslint-disable react/prop-types */
import * as React from 'react';
import { Button } from '@material-ui/core';

export const step1 = ({ setAdvancedView }) => (
  <div>
    <p>Hallo, ich bin IVA, und ich werde Sie bei der Erstellung eines neuen Auftrags unterstützen.</p>
    <p>Machen Sie zunächst Angaben zur Position und dem Arbeitsort und klicken Sie auf „weiter“.</p>
    <div className="d-flex align-items-center">
      <div className="flex-grow-1">Sie kennen den Prozess bereits? Dann können Sie hier zur Expertenansicht wechseln:</div>
      <Button variant="outlined" color="primary" onClick={() => setAdvancedView(true)}>
        Expertenansicht
      </Button>
    </div>
  </div>
);

export const step2 = ({ title }) => (
  <div>
    <p>
      Sehr gut, lassen Sie uns nun zusammen Ihren Auftrag <em>{title}</em> vervollständigen!
    </p>
    <p>
      Als nächstes geben Sie bitte möglichst detailliert ein, welche Aufgaben und Funktionen der Kandidat in Ihrem Unternehmen ausführen
      wird.
    </p>
  </div>
);

export const step3 = () => (
  <div>
    <p>Super!</p>
    <p>Machen Sie nun Angaben zur Hierarchie und Verantwortungsbereichen für die gesuchte Position</p>
  </div>
);
export const step4 = () => (
  <div>
    <p>Prima!</p>
    <p>Welche Qualifikationen sollte Ihr idealer Kandidat mitbringen? </p>
  </div>
);
export const step5 = () => (
  <div>
    <p>Sehr gut!</p>
    <p>
      Jetzt geht es darum Kandidaten von Ihnen und der Position zu überzeugen. Warum soll die Stelle neu besetzt werden und aus welchem
      Gründen sollte ein Kandidat zu Ihnen wechseln?
    </p>
  </div>
);
export const step6 = () => (
  <div>
    <p>Sehr gut!</p>
    <p>Wir benötigen jetzt noch einige Angaben zur Dotierung.</p>
  </div>
);
export const step7 = () => (
  <div>
    <p>Fast geschafft!</p>
    <p>Jetzt noch einmal ein paar Angaben um unsere Kandidaten von Ihrem Unternehmen begeistern zu können.</p>
  </div>
);

export const step8 = () => (
  <div>
    <p>Wir befinden uns auf der Zielgeraden!</p>
    <p>
      Haben Sie bisher schon Rekrutierungsmaßnahmen ergriffen? Haben Sie sonst noch Anmerkungen zur Position? Wenn Sie fertig Sind klicken
      Sie bitte auf „Speichern“ Sie werden dann zur Suche weitergeleitet.
    </p>
  </div>
);

export const lorem = () => (
  <div>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr?</p>
    <p>
      Sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
      dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
    </p>
  </div>
);
