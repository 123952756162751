import * as React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grow } from '@material-ui/core';
import * as styles from './IvaGuidance.module.css';

const IvaGuidance = ({ children }) => (
  <div className="d-flex my-2">
    <div>
      <img src="/img/IVA_Transp.png" alt="iva" className={styles.iva} />
    </div>
    <Grow in>
      <Paper className={styles.helpBox}>{children}</Paper>
    </Grow>
  </div>
);

IvaGuidance.propTypes = {
  children: PropTypes.node.isRequired
};

export default IvaGuidance;
