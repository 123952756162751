const staticInfoText = {
  projectList: {
    // 'Noch nicht freigegeben': (
    //   <p>
    //     Aufträge können ab 10 Kandidaten freigegeben werden.
    //     <br /> Maximal können 30 Kandidaten einem Auftrag zugeordnet werden
    //   </p>
    // ),
    'Noch nicht freigegeben':
      'In diesen Aufträgen wurde noch keine Interviewanfrage freigegeben. Fügen Sie Kandidaten hinzu und geben Sie diese frei, um Interviewanfragen zu versenden.',
    'In Bearbeitung':
      'Diese Aufträge sind bereits in Bearbeitung. Ihre Interviewanfragen wurden versendet und wir sind dabei Kandidaten-Feedback einzuholen.',
    Abgeschlossen: 'Diese Aufträge wurden bereits besetzt. Sie können diese als Vorlage für neue Aufträge verwenden'
  },
  search: {
    filter: 'Infos zu den Filtern'
  }
};
export default staticInfoText;
