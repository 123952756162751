import * as React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Box } from '@material-ui/core';
import DropzoneContainer from './DropzoneContainer';
import FileList from './FileList';

const FileUploadField = ({ readOnly, disabled, label, placeholder, accept, value, onChange, onResolveFileUrl }) => {
  const multiple = Array.isArray(value);
  const [isNewFile, setIsNewFile] = React.useState(false);

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Box mt={2}>
        {(multiple || !value) && !readOnly && (
          <DropzoneContainer
            placeholder={placeholder}
            accept={accept}
            disabled={disabled}
            onDrop={files => {
              setIsNewFile(true);
              if (multiple) {
                onChange([...value, ...files]);
              } else {
                onChange(files[0]);
              }
            }}
          />
        )}
        <FileList
          files={multiple || !value ? value || [] : [value]}
          readOnly={readOnly}
          disabled={disabled}
          isNewFile={isNewFile}
          onResolveFileUrl={onResolveFileUrl}
          onDelete={(file, idx) => {
            if (multiple) {
              onChange(value.splice(idx, 1));
            } else {
              onChange(null);
            }
          }}
        />
      </Box>
    </FormControl>
  );
};

FileUploadField.propTypes = {
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onResolveFileUrl: PropTypes.func.isRequired
};

FileUploadField.defaultProps = {
  readOnly: false,
  disabled: false,
  value: undefined,
  placeholder: 'Datei hier ablegen oder mit Klick auswählen',
  accept: 'application/pdf'
};

export default FileUploadField;
