import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { hot } from 'react-hot-loader/root';

import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import UserContext from './utils/UserContext';

import ErrorPage from './pages/ErrorPage';
import Results from './pages/Results';
import Search from './pages/Search';
import MarkLists from './pages/MarkLists';
import Register from './pages/Register';
import RegisterTan from './pages/RegisterTan';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import Login from './pages/Login';
import PageContainer from './PageContainer';

import LoadSearchBox from './components/LoadSearchBox';
import Sidebar from './components/Sidebar';
import ErrorBoundary from './components/ErrorBoundary';
import MenuBar from './components/app_bar/MenuBar';
import ProjectSelectorWrapper from './components/ProjectSelectorWrapper';
import CandidateCountNotificationWrapper from './components/search_result_components/CandidateCountNotificationWrapper';

import * as CONSTANTS from './constants';
import * as CONFIG from './config';
import * as UTILS from './utils/utilFunctions';
import TEXT from './text';

import Profile from './pages/Profile';
import MyProjects from './pages/MyProjects';
import FaqPage from './pages/FaqPage';
import SingleProjectSummaryDrawer from './components/SingleProjectSummaryDrawer';
import NotificationPage from './pages/NotificationPage';

const systemPartner = CONSTANTS.partner;
const THEME = require('./theme')[systemPartner];

const theme = createMuiTheme(THEME.app);

const rootElement = document.documentElement;
rootElement.style.setProperty('--status-green', '#00c767');
rootElement.style.setProperty('--status-red', '#c7000e');
rootElement.style.setProperty('--teal', THEME.app.palette.primary.main);
rootElement.style.setProperty('--grey', '#717473');
rootElement.style.setProperty('--dred', '#762128');
rootElement.style.setProperty('--blue', THEME.app.palette.primary.main);
rootElement.style.setProperty('--background-secondary', '#00c7671c');
rootElement.style.setProperty('--secondary', THEME.app.palette.secondary.main);
rootElement.style.setProperty('--light-primary', THEME.app.palette.primary.light);

// wraps the routed component in an ErrorBoundary
// eslint-disable-next-line react/prop-types
const SafeRoute = ({ render, ...props }) => <Route {...props} render={routeProps => <ErrorBoundary>{render(routeProps)}</ErrorBoundary>} />;

class App extends PureComponent {
  constructor(props) {
    super(props);
    const startUser = {
      role: 'client'
    };

    this.state = {
      // the array for rest calls without indizes
      searchArray: {},
      // the array for search with indizes for focusmanagement
      searchQuery: [
        {
          column: [
            {
              searchTerm: '',
              isSemantic: true,
              ID: new Date().getTime(),
              hasError: false
            }
          ],
          ID: new Date().getTime()
        }
      ],
      // IVA
      suggestions: [],
      intelligentSearchSuggestions: [],
      resultCount: 0,
      isShowResultsButtonDisabled: true,
      loggedIn: false,
      user: startUser,
      sidePanelContent: undefined,
      markedCount: 0,
      snackbarOpen: false,
      snackbarText: '',
      // Dialogs
      saveSearchBoxOpen: false,
      loadSearchBoxOpen: false,

      // Loading indicators
      showLoadingIndicatorGlobal: false,
      showLoadingIndicatorLoggedIn: false,
      showLoadingIndicatorGlobalText: '',
      locationPath: '/',
      ratings: {
        rating1: 3,
        rating2: 3,
        rating3: 3,
        rating4: 3
      },
      resultsCalledFrom: '',
      distance: 0,
      geoLocation: undefined,
      projectSelectorOpen: false,
      candidateCountNotificationOpen: false,
      selectedProject: undefined,
      selectedProjectSummaryDrawerOpen: false,
      notificationCount: 0
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
    this.UTILS_logoutLocalUser = UTILS.logoutLocalUser.bind(this);
  }

  handleNotificationCountUpdate = notificationCount => {
    this.setState({ notificationCount });
  };

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  handleSaveSearchBoxOpen = () => {
    this.setState({
      saveSearchBoxOpen: true
    });
  };

  handleLoadSearchBoxOpen = () => {
    this.setState({
      loadSearchBoxOpen: true
    });
  };

  handleSaveSearchBoxClose = success => {
    if (success) {
      this.handleSnackbarOpen(TEXT.searchPage.saveSearchInfoMessage);
    }
    this.setState({ saveSearchBoxOpen: false });
  };

  handleLoadLastSearch = searchQuery => {
    if (!searchQuery) return;
    let id = new Date().getTime();

    let tempSearchQuery = searchQuery.slice(0, searchQuery.length - 1);

    tempSearchQuery = tempSearchQuery.filter(andField => andField.length>0).map(andField => {
      return {
        ID: id--,
        column: andField.map(searchTerm => {
          return {
            searchTerm: searchTerm.searchTerm,
            isSemantic: true,
            ID: searchTerm.id,
            hasError: false
          };
        })
      };
    });

    this.setState({ searchQuery: tempSearchQuery });
  };

  handleLoadSearchBox = (searchQuery, geoLocation, distance, filters) => {
    const stateUpdate = {
      searchQuery,
      loadSearchBoxOpen: false,
      resultsCalledFrom: 'loadedSearch',
      distance,
      filters
    };
    if (geoLocation) {
      stateUpdate.geoLocation = geoLocation;
    }
    this.setState(stateUpdate);
  };

  handleLoadSearchBoxClose = () => {
    this.setState({ loadSearchBoxOpen: false });
  };

  handleSnackbarOpen = description => {
    this.setState({ snackbarOpen: true, snackbarText: description });
  };

  liftUpStateToApp = (stateNameOrObject, value, callback = () => {}) => {
    if (typeof stateNameOrObject === 'object') {
      Object.keys(stateNameOrObject).forEach(key => {
        this.setState({
          [key]: stateNameOrObject[key]
        });
      });
    } else if (stateNameOrObject === 'loggedIn' && !value) this.UTILS_logoutLocalUser();
    else this.setState({ [stateNameOrObject]: value }, callback);
  };

  resetSearch = () => {
    this.setState({
      resultCount: 0,
      isShowResultsButtonDisabled: true,
      searchQuery: [
        {
          column: [
            {
              searchTerm: '',
              isSemantic: true,
              ID: new Date().getTime(),
              hasError: false
            }
          ],
          ID: new Date().getTime()
        }
      ],
      suggestions: [],
      intelligentSearchSuggestions: [],
      distance: 0,
      geoLocation: undefined
    });
  };

  showResultButtonHandler = (searchQuery, ratings) => {
    const searchArray = [];
    searchQuery.forEach((column, columnCount) => {
      if (searchQuery[columnCount].column[0].length !== 0) {
        const tempArray = searchQuery[columnCount].column.slice(0);
        tempArray.splice(-1, 1);
        searchArray.push(tempArray);
      }
    });

    this.setState({
      searchArray,
      ratings,
      resultsCalledFrom: 'searchPage'
    });
  };

  logout() {
    this.setState({ showLoadingIndicatorGlobal: true });
    console.log('API /logout request');
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.logoutURL;
    $.ajax({
      url,
      method: 'POST',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status, xhr) => {
        console.log('API logout response status code', xhr.status);

        this.setState({ showLoadingIndicatorGlobal: false });
        if (xhr.status === 200) {
          this.setState({ loggedIn: false, user: { role: [] } });
          this.UTILS_logoutLocalUser();
        }
      })
      .fail(err => {
        this.errorHandling(err, url, this.liftUpStateToApp);
        this.setState({ showLoadingIndicatorGlobal: false });
      });
  }

  renderProjectSelector() {
    return (
      <ProjectSelectorWrapper
        handleClose={() => {
          this.setState({ projectSelectorOpen: false });
        }}
        handleSave={selectedProject => {
          this.setState(prevState => ({
            selectedProject,
            geoLocation: prevState.geoLocation || selectedProject.address
          }));
          this.setState({ projectSelectorOpen: false });
        }}
        projectSelectorOpen={this.state.projectSelectorOpen}
      />
    );
  }

  renderCandidateCountNotification() {
    return (
      <CandidateCountNotificationWrapper
        open={this.state.candidateCountNotificationOpen}
        handleClose={() => this.setState({ candidateCountNotificationOpen: false })}
        handleSave={() => this.setState({ candidateCountNotificationOpen: false })}
        selectedProject={this.state.selectedProject}
        updateSelectedProject={selectedProject => this.setState({ selectedProject })}
      />
    );
  }

  renderSelectedProjectSummaryDrawer() {
    return (
      <SingleProjectSummaryDrawer
        project={this.state.selectedProject}
        open={this.state.selectedProjectSummaryDrawerOpen}
        onClose={() => this.setState({ selectedProjectSummaryDrawerOpen: false })}
        updateSelectedProject={selectedProject => this.setState({ selectedProject })}
      />
    );
  }

  render() {
    const currentPage = window.location.pathname;

    const showMenuBar = !CONFIG.pagesWithoutLogin.includes(currentPage);
    return (
      <UserContext.Provider value={this.state.user}>
        <MuiThemeProvider theme={theme}>
          <div className="App" style={{ flexGrow: 1 }}>
            <CssBaseline />
            <Router>
              <PageContainer loggedIn={this.state.loggedIn} locationPath={this.state.locationPath} liftUpStateToApp={this.liftUpStateToApp}>
                {showMenuBar && (
                  <MenuBar
                    logoutApiCall={() => this.logout()}
                    resetSearch={this.resetSearch}
                    currentPageContext={currentPage}
                    handleLoadSearchBoxOpen={this.handleLoadSearchBoxOpen}
                    handleSaveSearchBoxOpen={this.handleSaveSearchBoxOpen}
                    setMarkedCount={() => {
                      this.setState({ markedCount: 0 });
                    }}
                    screenWidth={this.props.width}
                    selectedProjectName={this.state.selectedProject ? this.state.selectedProject.title : ''}
                    selectedProjectCandidateCount={this.state.selectedProject ? this.state.selectedProject.candidateCount : 0}
                    openProjectSelectorHandler={() => this.setState({ projectSelectorOpen: true })}
                    openSelectedProjectSummaryDrawer={() => this.setState({ selectedProjectSummaryDrawerOpen: true })}
                    notificationCount={this.state.notificationCount}
                  />
                )}
                {(this.state.showLoadingIndicatorGlobal || this.state.showLoadingIndicatorLoggedIn) && (
                  <div id="loading-indicator-overlay">
                    <div id="loading-indicator-container" className="text-center">
                      <CircularProgress className="mb-3" size={60} thickness={5} />
                      {this.state.showLoadingIndicatorGlobalText !== '' && (
                        <h3 style={{ color: '#fff' }}>{this.state.showLoadingIndicatorGlobalText}</h3>
                      )}
                    </div>
                  </div>
                )}
                <LoadSearchBox
                  open={this.state.loadSearchBoxOpen}
                  handleClose={this.handleLoadSearchBoxClose}
                  handleLoad={this.handleLoadSearchBox}
                  liftUpStateToApp={this.liftUpStateToApp}
                />
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  open={this.state.snackbarOpen}
                  autoHideDuration={3000}
                  onClose={this.handleSnackbarClose}
                  ContentProps={{
                    'aria-describedby': 'message-id'
                  }}
                  message={<span id="message-id">{this.state.snackbarText}</span>}
                  action={[
                    <IconButton key="close" aria-label="Close" color="inherit" className="closeSnackbar" onClick={this.handleSnackbarClose}>
                      <i className="material-icons">close</i>
                    </IconButton>
                  ]}
                />

                <SafeRoute
                  exact
                  path="/"
                  render={props => <Login {...props} liftUpStateToApp={this.liftUpStateToApp} loggedIn={this.state.loggedIn} />}
                />
                <SafeRoute exact path="/register" render={props => <Register {...props} liftUpStateToApp={this.liftUpStateToApp} />} />

                <SafeRoute
                  exact
                  path="/registerTan"
                  render={props => <RegisterTan {...props} liftUpStateToApp={this.liftUpStateToApp} />}
                />

                <SafeRoute
                  exact
                  path="/search"
                  render={props => (
                    <Search
                      {...props}
                      handleLoadLastSearch={this.handleLoadLastSearch}
                      selectedProject={this.state.selectedProject}
                      searchQuery={this.state.searchQuery}
                      suggestions={this.state.suggestions}
                      intelligentSearchSuggestions={this.state.intelligentSearchSuggestions}
                      liftUpStateToApp={this.liftUpStateToApp}
                      showResultButtonHandler={this.showResultButtonHandler}
                      distance={this.state.distance}
                      geoLocation={this.state.geoLocation}
                      resultCount={this.state.resultCount}
                      isShowResultsButtonDisabled={this.state.isShowResultsButtonDisabled}
                    />
                  )}
                />
                <SafeRoute
                  exact
                  path="/markLists"
                  render={props => (
                    <MarkLists {...props} liftUpStateToApp={this.liftUpStateToApp} handleSnackbarOpen={this.handleSnackbarOpen} />
                  )}
                />
                <SafeRoute exact path="/imprint" render={props => <Imprint {...props} />} />
                <SafeRoute exact path="/privacy" render={props => <Privacy {...props} />} />

                <SafeRoute
                  path="/results"
                  render={props => (
                    <div className="row static-container">
                      <div className="col-lg-12 col-xl-8 col-sm-12 no-padding-right">
                        <Results
                          {...props}
                          width={this.props.width}
                          query={this.state.searchArray}
                          rating={this.state.ratings}
                          distance={this.state.distance}
                          lat={this.state.geoLocation ? this.state.geoLocation.location.lat : 0}
                          lon={this.state.geoLocation ? this.state.geoLocation.location.lon : 0}
                          geoLocationId={(this.state.geoLocation || {}).id}
                          calledFrom={this.state.resultsCalledFrom}
                          postalCode={(this.state.geoLocation || {}).postalCode}
                          liftUpStateToApp={this.liftUpStateToApp}
                          markedCount={this.state.markedCount}
                          handleSnackbarOpen={this.handleSnackbarOpen}
                          filters={this.state.filters}
                          saveSearchBoxOpen={this.state.saveSearchBoxOpen}
                          handleSaveSearchBoxClose={this.handleSaveSearchBoxClose}
                          selectedProjectHandler={{
                            selectedProject: this.state.selectedProject,
                            openSelectorHandler: () => this.setState({ projectSelectorOpen: true }),
                            openCandidateCountNotification: () => this.setState({ candidateCountNotificationOpen: true }),
                            updateProjectDataHandler: selectedProject => this.setState({ selectedProject })
                          }}
                        />
                      </div>
                      {isWidthUp('lg', this.props.width) ? (
                        <div className="col-4 no-padding-left">
                          <div className="container-fluid container-results pt-0">
                            <div className="row">
                              <Sidebar content={this.state.sidePanelContent} handleSnackbarOpen={this.handleSnackbarOpen} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                />

                <SafeRoute
                  exact
                  path="/notifications"
                  render={props => <NotificationPage {...props} handleNotificationCountUpdate={this.handleNotificationCountUpdate} />}
                />

                <SafeRoute
                  exact
                  path="/profile"
                  render={props => (
                    <Profile
                      {...props}
                      onSelectProject={project => {
                        this.setState({
                          selectedProject: project,
                          geoLocation: project.address
                        });
                      }}
                    />
                  )}
                />

                <SafeRoute
                  exact
                  path="/myprojects"
                  render={props => (
                    <MyProjects
                      {...props}
                      onSelectProject={project => {
                        this.setState({
                          selectedProject: project,
                          geoLocation: project.address
                        });
                      }}
                    />
                  )}
                />

                <SafeRoute exact path="/faq" render={props => <FaqPage {...props} />} />

                <Route path="/err" render={props => <ErrorPage {...props} />} />
                {this.renderProjectSelector()}
                {this.state.selectedProject && this.renderCandidateCountNotification()}
                {this.state.selectedProject && this.renderSelectedProjectSummaryDrawer()}
              </PageContainer>
            </Router>
          </div>
        </MuiThemeProvider>
      </UserContext.Provider>
    );
  }
}

App.propTypes = {
  width: PropTypes.string.isRequired
};
export default process.env.NODE_ENV === 'development' ? hot(withWidth()(App)) : withWidth()(App);
