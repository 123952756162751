import * as React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../generic_components/ConfirmationModal';
import BookingOptionSelection from './BookingOptionSelection';

import './BookingOptionModalStyles.css';

const BookingOptionModal = ({ initialBookingOptions, open, handleClose, buttonPrimaryAction }) => {
    const [tmpBookingOptions, setTmpBookingOptions] = React.useState(initialBookingOptions);
    React.useEffect(() => {
        // reset options on close
        if (initialBookingOptions) {
            setTmpBookingOptions(initialBookingOptions);
        }
    }, [initialBookingOptions])
    
    return (
        <ConfirmationModal
            className="booking-option-modal"
            open={open}
            handleClose={handleClose}
            descriptionText=""
            buttonPrimaryAction={() => {
                buttonPrimaryAction(tmpBookingOptions);
            }}
            buttonPrimaryText="Zusatzleistung buchen"
            buttonSecondaryText="Abbrechen"
            headlineText="Buchungsoptionen"
            icon={null}
        >
            <BookingOptionSelection 
                readOnlyOptions={initialBookingOptions 
                    ? Object.keys(initialBookingOptions).filter((option) => initialBookingOptions[option]) 
                    : []}
                value={tmpBookingOptions} 
                onChange={(newBookingOptions) => {
                    setTmpBookingOptions(newBookingOptions);
                }} />
        </ConfirmationModal>
    )
}


BookingOptionModal.propTypes = {
    initialBookingOptions: PropTypes.object,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    buttonPrimaryAction: PropTypes.func.isRequired,
};

BookingOptionModal.defaultProps = {
    initialBookingOptions: {},
}

export default BookingOptionModal;