import React from 'react';
import PropTypes from 'prop-types';

import { TextField, Divider, Box, Grid } from '@material-ui/core';

const detailFieldSections = [
  {
    companyType: 'Unternehmenszusatz'
  },
  {
    street: 'Straße',
    postbox: 'Postfach',
    postalCode: 'PLZ',
    city: 'Stadt',
    country: 'Land'
  },
  {
    phoneNumber: 'Telefonnummer',
    faxNumber: 'Faxnummer',
    url: 'URL',
    urlCareerPage: 'Karriereseite'
  },
  {
    advisor: 'Betreuer'
  }
];

export default function CompanyDetails({ fieldValues }) {
  const renderTextField = (entry, entryKey) => (
    <TextField
      value={fieldValues ? fieldValues[entryKey] || 'Kein Eintrag' : 'Kein Eintrag'}
      label={entry[entryKey]}
      key={`det_${entryKey}`}
      InputProps={{
        readOnly: true,
        shrink: true,
        disableUnderline: true,
        focused: false
      }}
    />
  );

  return (
    <>
      {detailFieldSections.map(entry => (
        <>
          <Box mt={3} mb={3}>
            <Grid container spacing={4}>
              {Object.keys(entry).map(entryKey => (
                <Grid item>{renderTextField(entry, entryKey)}</Grid>
              ))}
            </Grid>
          </Box>
          {!('advisor' in entry) && <Divider />}
        </>
      ))}
    </>
  );
}

CompanyDetails.propTypes = {
  fieldValues: PropTypes.object.isRequired
};
