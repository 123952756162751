const text = {
  roles: {
    employee: 'Interner Partner',
    client: 'Kunde',
    admin: 'Admin'
  },
  ConfirmationModal: {
    buttonPrimaryText: 'Bestätigen',
    buttonSecondaryText: 'Abbrechen',
    headlineText: 'Bestätigung erforderlich',
    descriptionText: 'Bitte bestätigen Sie Ihre ausgeführte Aktion'
  },
  navBar: {
    changeSearch: 'Suche anpassen',
    saveSearch: 'Suche speichern',
    resetSearch: 'Neue Suche',
    loadSearch: 'Suche laden',
    semanticSearch: 'Semantische Suche',
    sendOrder: 'Interviewliste',
    logout: 'Abmelden',
    returnToSearchPage: 'Zurück zur Suchseite',
    returnToResultsPage: 'Zurück zur Detailansicht',
    markLists: 'Gespeicherte Merkliste',
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    profile: 'Unternehmensprofil'
  },
  registerPage: {
    inputFields: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      password: 'Neues Passwort wählen',
      passwordRepeated: 'Neues Passwort wiederholen',
      phoneNumber: 'Telefonnummer'
    },
    passwordRequirements: [
      'Mindestenslänge 8 Zeichen',
      'Mindestens ein Sonderzeichen',
      'Mindestens eine Zahl',
      'Mindestens einen Großbuchstaben',
      'Mindestens einen Kleinbuchstaben'
    ],
    button: 'Registrierung abschließen',
    errorMsg: {
      newAndOldPasswordIsIdenticital: 'Bitte wählen Sie ein neues Passwort'
    }
  },
  loginPage: {
    headline: 'Login Seite',
    errorMsg: {
      error400: 'Bitte überprüfen Sie Ihre Logindaten!',
      accountLocked: 'Ihr Nutzer ist gesperrt!',
      startPasswordExpired: 'Ihr Start Passwort ist abgelaufen',
      accessOnlyToDifferentPortal: 'Fehlende Berechtigungen zum Login für dieses Portal'
    },
    inputfield: {
      email: {
        label: 'E-Mail'
      },
      password: {
        label: 'Passwort'
      },
      tan: {
        label: 'TAN'
      }
    },
    button: 'Login',
    imageAlt: 'Matchflix Logo '
  },
  registerTanPage: {
    headline: '2-Faktor Authentifizierung',
    description: 'Aktivieren Sie die 2 Faktor Authentifizierung, um die Sicherheit Ihres Accounts zu erhöhen.',
    steps: [
      {
        headline: 'Schritt 1',
        description: 'Downloaden Sie den "Google Authenticator" aus dem App Store Ihres Smartphone'
      },
      {
        headline: 'Schritt 2',
        description: 'Scannen Sie den QR Code mit dem „Google Authenticator“'
      },
      {
        headline: 'Schritt 3',
        description: 'Geben Sie die angezeigte sechsstellige TAN ein und schließen Sie die Aktivierung ab'
      }
    ],
    tanFieldLabel: 'Initiale TAN',
    buttonValidateTan: 'TAN validieren',
    buttonRedirect: 'Zum Portal',
    successMessage: 'Die Zwei-Faktor Authentifizierung wurde erfolgreich aktiviert',
    errorMessage: 'Ihre eingegebene Tan ist nicht gültig. Versuchen Sie es erneut!'
  },
  searchPage: {
    searchResultCountBox: {
      title: 'Treffer der aktuellen Suche:',
      greyText: 'Bitte geben Sie einen Suchbegriff ein.',
      greenText: 'Erfolgreich! Suche weiter einschränken oder die Kandidaten anzeigen lassen.',
      redText: 'Zuviele Suchergebnisse, bitte schränken Sie die Suche weiter ein.',
      button: 'Trefferliste anzeigen'
    },
    saveSearchInfoMessage: 'Suche wurde gespeichert',
    searchInputField: {
      mandatoryPlaceholder: 'Weiteres Muss-Kriterium',
      optionalPlaceholder: 'Optional / Oder auch',
      errorMessage: 'Ungültige Eingabe'
    },
    sendSearchBox: {
      headline: 'Angaben zum Arbeitgeber',
      postalCodeInfoMessage: 'Vor dem Absenden muss noch eine Postleitzahl hinterlegt werden',
      sendSearchBoxMessage: 'Bitte bewerten Sie noch die angebotene Stelle für eine Berechnung der Wechselwilligkeit',
      postalCodeLabel: 'Postleitzahl des Arbeitgebers',
      postalCodeError: 'Bitte geben Sie eine fünfstellige numerische Postleitzahl ein',
      starsLabel1: 'Attraktives Gehaltsniveau',
      starsLabel2: 'Attraktiver Arbeitgeber',
      starsLabel3: 'Attraktiver Wohnort',
      starsLabel4: 'Karriereangebot',
      cancelButton: 'Abbrechen',
      continueButton: 'Weiter zur Trefferliste'
    },
    helpBox: {
      onFirstSearchField: 'Für welche Position / Funktion suchen Sie?',
      onOtherSearchFieldsWithoutSuggestion: 'Welche weiteren Kriterien sind wichtig?',
      onOtherSearchFieldsWithSuggestions:
        'Welche weiteren Kriterien sind wichtig?',
      otherUsersText:
        'Andere User suchten in Verbindung mit Ihren Suchkriterien auch nach'
    },

    tooltip: 'Zusätzlich wird nach folgenden Begriffen gesucht: '
  },
  companyArchive: {
    addButton: {
      createCompanyEntry: 'Unternehmen anlegen',
      createCommentEntry: 'Kommunikationseintrag',
      createContactEntry: 'Ansprechpartner hinzufügen'
    },
    createCompanyPopUp: {
      title: 'Neues Unternehmen anlegen ',
      subtitle: '',
      inputFieldNames: [{ companyName: 'Unternehmen' }, { postalCode: 'Postleitzahl' }, { city: 'Ort' }, { street: 'Straße' }],
      selectPartner: 'Systempartner auswählen',
      snackbarInfoStart: 'Der Unternehmenseintrag "',
      snackbarInfoEnd: '" wurde angelegt',
      cancel: 'abbrechen',
      saveEntry: 'eintrag speichern'
    }
  },
  resultPage: {
    errorUserTrysToSelectMoreThan10Candidates: 'Sie können maximal 10 Kandidaten markieren',
    resetFiltersButton: 'Filter leeren',
    sortingOptions: {
      matchPercentage: 'Passgenauigkeit',
      willingnessToChange: 'Wechselwahrscheinlichkeit',
      postalcode: 'Entfernung',
      age: 'Alter'
      // salary: 'Gehalt',
      // willingnessToRelocate: 'Umzugsbereitschaft'
    },
    skillDetails: {
      languageTableHeading: {
        leftColumn: 'Sprache',
        rightColumn: 'Grad der Beherrschung'
      },
      otherSkillsTableHeading: {
        leftColumn: 'EDV-/Sonstige Kenntnisse',
        rightColumn: 'Grad der Beherrschung'
      }
    },
    sideBar: {
      skillLevel0: 'Anfänger',
      skillLevel1: 'Grundkenntnisse (A1)',
      skillLevel2: 'erweiterte Grundkenntnisse (A2)',
      skillLevel3: 'gute Kenntnisse (B1)',
      skillLevel4: 'sehr gute Kenntnisse (B2)',
      skillLevel5: 'verhandlungssicher / fließend (C1)',
      skillLevel6: 'Muttersprache / Dolmetscher (C2)',
      documentsView: {
        selectDoc: {
          documentName: 'Dokument',
          label: 'Dokument auswählen'
        },
        downloadButton: 'Dokument herunterladen',
        pdfErrorNoDocumentAvailable: 'Kein Dokument vorhanden.',
        candidateHasNoDocs: 'Dieser Kandidat verfügt über keine Dokumente.',
        fileOnlyAsDownload: 'Diese Datei wird nur als Download bereit gestellt.'
      }
    },
    selectCandidateForSidebar: 'Wählen Sie einen Kandidaten aus, um weitere Informationen anzuzeigen.',
    amountOfHits: 'Anzahl Treffer:',
    labelRowsPerPage: 'Kandidaten pro Seite:',
    labelPaginationFromTo1: 'bis',
    labelPaginationFromTo2: 'aus',
    boostYourResults: 'Gewichten Sie Ihre Suche',
    adoptChanges: 'Änderungen übernehmen',
    boostDescription: 'Markieren Sie einzelne Suchbegriffe, die für Sie besonders relevant sind',
    results: 'Suchergebnisse:',
    noJobDescription: 'Keine Angaben zum Job oder den Branchen.',
    lastJobs: 'Bisherige Positionen:',
    languageSkills: 'Sprachkentnisse:',
    additionalSkills: 'EDV-Kentnisse:',
    branches: 'Branchen:',
    description: 'Berufstätigkeit:',
    noJobDuration: 'Anstellungsdauer nicht vohanden.',
    available: 'verfügbar',
    notAvailable: 'nicht verfügbar',
    age: 'Alter:',
    lastChange: 'Letzte Änderung:',
    phoneNumber: 'Telefon:',
    mailAddress: 'E-Mail:',
    candidateId: 'Kandidatennummer',
    addToProject: 'Zur Interviewliste hinzufügen',
    containedInProject: 'Im Auftrag vorhanden',
    textCopied: 'Kandidatennummer wurde kopiert.',
    expandButton: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger anzeigen'
    },
    addToOrder: 'zu Auftrag hinzufügen',
    matchPercentage: 'Passgenauigkeit',
    willingnessToChange: 'Wechsel&shy;wahr&shy;schein&shy;lich&shy;keit',
    // willingnessToChange1: 'Wechselwahr',
    // willingnessToChange2: 'scheinlichkeit',
    noResults: 'Keine Ergebnisse passen zu Ihren Filterkriterien.',
    company: 'Name der Firma',
    firstName: 'Vorname',
    lastName: 'Nachname',
    lastPositions: {
      currentPosition: 'Derzeitige Position (',
      formerPosition: 'Letzte Position (',
      years: 'Jahre',
      months: 'Monate'
    },
    sendOrderBox: {
      sendOrder: 'Interviewanfrage absenden',
      yourCandidates: 'Ihre ausgewählten Kandidaten:',
      yourOrder: 'Ihre Interviewanfrage',
      lastJob: 'Letzer Job:',
      success: 'Ihre Interviewanfrage wurde erfolgreich übermittelt!',
      successText: 'Wir werden uns sobald wie möglich mit Ihnen in Verbindung setzen.',
      startNextSearch: 'Weitere Suche starten'
    },
    saveSearchBox: {
      headline: 'Suche speichern',
      description: 'Bitte geben Sie einen Namen für Ihre zu speichernde Suche ein:',
      labelInputField: 'Beschreibung der Suche',
      leftButton: 'Abbrechen',
      rightButton: 'Speichern'
    }
  },
  errorPage: {
    headlineLarge: 'Oh, No...',
    headlineSmall: 'Technischer Fehler',
    infoMessage: 'Es ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späterem Zeitpunkt erneut.'
  },
  markListPopup: {
    headline: 'Kandidat zur Merkliste hinzufügen',
    headlineCompany: 'Unternehmen zur Merkliste hinzufügen',
    placeholder: 'Merklisten filtern oder neue Merkliste erstellen',
    addItemToMarkListButton: 'Kandidat merken',
    button: 'Zu neuer Merkliste hinzufügen',
    subheadline: 'Zu bestehender Merkliste hinzufügen:',
    noExistingList: 'Es existieren keine gespeicherten Merklisten',
    cancelButton: 'Abbrechen ',
    addItemToMarkListInfo: 'Kandidat zur Merkliste hinzufügen',
    itemIsInListInfo: 'Kandidat ist bereits in der Merkliste vorhanden',
    companyIsInListInfo: 'Unternehmen ist bereits in der Merkliste vorhanden',
    addCompanyToListInfo: 'Unternehmen zur Merkliste hinzufügen',
    snackBarItemAddInfoToList: 'Kandidat wurde zu Merkliste "',
    snackBarItemAddInfoToNewList: 'Kandidat wurde zu neuer Merkliste "',
    addedInfo: '" hinzugefügt',
    snackbarInfoCompany: {
      addItemToNewMarkList: 'Unternehmen wurde zu Merkliste "',
      addItemToExistingMarkList: 'Unternehmen wurde zu neuer Merkliste "'
    }
  },
  markListsPage: {
    categoryList: [
      { savedListsCandidates: 'Kandidaten Merklisten' },
      { savedListsOrders: 'Auftragslisten' },
      { savedListsCompanies: 'Unternehmenslisten' }
    ],
    noListsAvailable: 'Es wurden keine gespeicherte Merklisten gefunden',
    headline: 'Gespeicherte Merklisten',
    emptyList: 'Die Merkliste ist leer',
    deleteListButton: 'Merkliste löschen',
    candidateDetailsButton: 'Details anzeigen',
    companyDetailsButton: 'Unternehmensdetails anzeigen',
    deletionConfirmationModul: {
      headline: 'Merkliste löschen',
      description: 'Wollen Sie die Merkliste und die gespeicherten Markierungen wirklich löschen?',
      buttonPrimaryText: 'Löschen'
    },
    removeItemConfirmationModul: {
      headline: 'Kandidaten aus Merkliste entfernen',
      description: 'Wollen Sie den Kandidaten aus der Merkliste entfernen?',
      buttonPrimaryText: 'Entfernen'
    },
    removeCompanyConfirmationModul: {
      headline: 'Unternehmen aus Merkliste entfernen',
      description: 'Wollen Sie das Unternehmen aus der Merkliste entfernen?',
      buttonPrimaryText: 'Entfernen'
    },
    snackBar: {
      success: {
        deleteMarkList: 'Die Merkliste wurde erfolgreich gelöscht!',
        removeItemFromMarkList: 'Der Kandidat wurde erfolgreich aus der Merkliste entfernt!',
        removeCompanyFromMarkList: 'Der Unternehmen wurde erfolgreich aus der Merkliste entfernt!',
        genericText: 'Eintrag wurde entfernt!'
      }
    },
    noListsExisting: 'Es existieren keine gespeicherten Merklisten'
  },
  filter: {
    mainFilter: {
      headline: 'Filter nach Personendetails',
      matchPercentage: 'Übereinstimmung:',
      age: 'Alter',
      name: 'Name',
      companyName: 'Firma',
      willingnessToChange: 'Wechselwilligkeit',
      salary: 'Gehalt',
      postalCode: 'Distanz zur angegebenen Postleitzahl',
      willingnessToRelocate: {
        headline: 'Umzugsbereitschaft',
        dropdown: [
          { key: 'all', value: 'Keine Einschränkung' },
          { key: 'yes', value: 'Ja' },
          { key: 'no', value: 'Nein' },
          { key: 'notspecified', value: 'Keine Angabe' }
        ]
      },
      showMarked: {
        headline: 'Nur markierte Kandidaten anzeigen'
      }
    },
    jobFilter: {
      headline: 'Filter nach Jobdetails',
      durationCurrentJob: {
        headline: 'Dauer der aktuellen Position',
        labels: {
          label1: '<1 Jahr',
          label2: '1-2 Jahre',
          label3: '2-5 Jahre',
          label4: '5-10 Jahre',
          label5: '>10 Jahre'
        }
      },
      currentPosition: {
        headline: 'Aktuelle Position',
        placeholderFilteredOptions: 'Filteroptionen einschränken'
      },
      workExperience: {
        headline: 'Berufserfahrung',
        labels: {
          label1: '<1 Jahr',
          label2: '1-2 Jahre',
          label3: '2-5 Jahre',
          label4: '5-10 Jahre',
          label5: '>10 Jahre'
        }
      },
      industry: {
        headline: 'Branche'
      },
      currentEmployer: {
        headline: 'Aktueller Arbeitgeber'
      }
    },
    sticky: {
      headline: 'Gesetzte Filter'
    }
  },
  commonTerms: {
    cancel: 'Abbrechen',
    ok: 'Ok',
    logout: 'Logout',
    NA: 'N/A'
  },
  errorHandling: {
    networkError: 'Bitte überprüfen Sie Ihre Internetverbindung',
    httpError: 'Es ist ein technischer Fehler aufgetreten'
  }
};
module.exports = text;
