import React from 'react';
import { Typography, Hidden, Container } from '@material-ui/core';

import ResponsivePaper from '../generic_components/ResponsivePaper';

export default function FaqPage() {
  return (
    <Container fixed>
      <ResponsivePaper className="my-3">
        <Hidden xsDown>
          <Typography variant="h5">FAQ</Typography>
        </Hidden>
        <p>
          Lorem ipsum dolor sit amet, et eam utroque mediocritatem, habeo assentior cu mea. Vim te nostro eirmod latine, sonet causae
          detraxit ad per. Rebum dicant possit vim eu, dolorem gubergren te sed. Eius habemus sit at, et vix minim ridens reprehendunt, ei
          utinam melius omnesque vel. Cu his impetus suscipit, agam mandamus ei pri. Mei ei omnis tacimates. Iuvaret fastidii deterruisset
          sit id, accusamus reprehendunt concludaturque in vix, an aperiri reformidans definitiones nam. Minim nullam diceret vel in, dicunt
          utamur delenit ut vel, ex nobis conclusionemque mea. Homero efficiantur mei an. Debet dolore signiferumque pri et, usu populo
          graeco ex, eos quodsi deseruisse ex. In oblique civibus mediocritatem his. His graeco causae oportere ne, cum te nonumy
          percipitur, at per mundi aliquam. Cibo consul duo no, sea detraxit facilisi theophrastus te. Pri ad quodsi vulputate intellegebat.
          Utamur vocibus cu mel. Invenire vulputate eum et, eam id mollis ancillae. At vix quot fabulas. Solum paulo interesset te mel. Has
          ea dictas salutandi, no choro civibus pri, id alia delenit dissentias eam. Sit animal fabulas docendi ad, ex sea facer dicant. Cu
          duo summo movet mucius, ad ridens scribentur ius. At enim esse viderer mel, at principes corrumpit consectetuer his. Duis invidunt
          consequuntur eum at, dictas verterem est ea. Ut sed ferri quidam corpora, detraxit delicatissimi quo in. Id vis electram
          convenire, eam omnis quidam ut. Ne velit tamquam appetere has.
        </p>
      </ResponsivePaper>
    </Container>
  );
}
