import React, { useState } from 'react';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useLoading from '../../utils/useLoading';
import DropzoneContainer from '../../generic_components/DropzoneContainer';
import { uploadProfileLogo, getProfileLogo } from '../../api/company';

function binaryImageToBase64(binaryString) {
  return `data:image/jpeg;base64,${binaryString}`;
}

export default function CompanyLogo() {
  const [imageBuffer, setImageBuffer] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, wrapAsyncLoading] = useLoading(false);

  const renderImage = () => (imageBuffer ? <img src={binaryImageToBase64(imageBuffer)} alt="logo" className="img-fluid pt-3" /> : '');

  React.useEffect(
    wrapAsyncLoading(async () => {
      try {
        const logo = await getProfileLogo();
        setImageBuffer(logo.picture);
      } catch (error) {
        enqueueSnackbar('Logo download Fehler', { variant: 'error' });
      }
    }),
    []
  );

  const uploadImage = wrapAsyncLoading(async logo => {
    try {
      await uploadProfileLogo(logo);
      const newLogo = await getProfileLogo();
      setImageBuffer(newLogo.picture);
    } catch (error) {
      enqueueSnackbar('Logo upload Fehler', { variant: 'error' });
    }
  });

  const renderLoadingIndicator = () => (
    <Box p={3} pt={4}>
      <Typography component="div" align="center">
        <CircularProgress size={100} />
      </Typography>
    </Box>
  );

  return (
    <Box p={2} pt={0}>
      {loading ? (
        renderLoadingIndicator()
      ) : (
        <Typography component="div" align="center">
          <Box pb={2}>{renderImage()}</Box>
          <DropzoneContainer placeholder="Logo Upload" accept="image/jpeg, image/png, image/gif" onDrop={uploadImage} />
        </Typography>
      )}
    </Box>
  );
}
