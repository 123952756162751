import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { Typography, Grid } from '@material-ui/core';
import useLoading from '../../utils/useLoading';
import { removeCandidateFromProject, addCandidateToProject, getCandidateFileInfo } from '../../api/projects';
import StatusChart from './StatusChart';
import CandidateList from './CandidateList';
import PdfViewerModal from '../../generic_components/PdfViewerModal';

const ProjectStatus = ({ projectId, candidateList, showChart, onUpdate }) => {
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [loading, wrapAsyncLoading] = useLoading();

  const [isPdfViewerVisible, setIsPdfViewerVisible] = useState(false);
  const [pdfFile, setPdfFile] = useState();

  const deleteCandidate = wrapAsyncLoading(async candidateId => {
    await removeCandidateFromProject(projectId, candidateId);
    await onUpdate();
  });
  const undoRemoveCandidate = wrapAsyncLoading(async candidateId => {
    await addCandidateToProject(projectId, candidateId);
    await onUpdate();
  });

  const showPdf = wrapAsyncLoading(async candidateId => {
    const file = await getCandidateFileInfo(candidateId, projectId);
    setPdfFile(file);
    setIsPdfViewerVisible(true);
  });

  const downloadPdf = () => {
    if (!pdfFile) {
      return '';
    }
    return pdfFile.contentUrl;
  };

  function changeFilter(newFilter) {
    if (isEqual(statusFilter, newFilter)) {
      setStatusFilter(undefined);
    } else {
      setStatusFilter(newFilter);
    }
  }

  if (candidateList && candidateList.length === 0) {
    return (
      <Typography component="div">
        <p>Es wurden noch keine Kandidaten zum Auftrag hinzugefügt.</p>
        <p>Bitte verwenden Sie die semantische Suche, um interessante Kandidaten zu finden.</p>
      </Typography>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {showChart && (
          <Grid item xs={12} lg={5} md={6}>
            <StatusChart candidateList={loading ? undefined : candidateList} selectedStatus={statusFilter} onClick={changeFilter} />
          </Grid>
        )}
        <Grid item xs={12} lg={showChart ? 7 : 12} md={showChart ? 6 : 12}>
          <CandidateList
            projectId={projectId}
            filter={statusFilter}
            candidateList={candidateList || []}
            loading={loading}
            onDelete={deleteCandidate}
            onAdd={undoRemoveCandidate}
            onViewPdf={showPdf}
          />
        </Grid>
      </Grid>
      {/* // WTF warum brauchst du kein File?????? */}
      {isPdfViewerVisible && (
        <PdfViewerModal isOpen={isPdfViewerVisible} onResolveFileUrl={downloadPdf} onClose={() => setIsPdfViewerVisible(false)} />
      )}
    </>
  );
};

ProjectStatus.propTypes = {
  projectId: PropTypes.string.isRequired,
  candidateList: PropTypes.array,
  showChart: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired
};

ProjectStatus.defaultProps = {
  candidateList: undefined
};

export default ProjectStatus;
