import * as React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

import './BookingOptionStyles.css';

const BookingOption = ({ title, description, iconSrc, price, checked, onChange, disabled }) => {
    return (
        <ul className="pricing">
            <li>
                <img src={iconSrc} alt="" />
                <big>{title}</big>
            </li>
            <li className="description">{description}</li>
            <li>
                <h3>{price},-</h3>
            </li>
            <li>
                <Checkbox 
                    disabled={disabled}
                    checked={checked}
                    value={checked ? 'true' : 'false'}
                    onChange={(event) => {
                        onChange(event.target.value !== 'true');
                    }} />
            </li>
        </ul>
    ) 
}

BookingOption.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iconSrc: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

BookingOption.defaultProps = {
    disabled: false,
    checked: false,
    onChange: undefined
}

export default BookingOption;