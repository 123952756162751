import * as React from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { CircularProgress } from '@material-ui/core';

export const STATUS_BUCKETS = [
  { status: 'added', label: 'Interviewanfrage ausstehend' },
  { status: 'released', label: 'Interviewanfrage abgesendet' },
  { status: 'contacted', label: 'Positive Rückmeldung' },
  { status: 'applied', label: 'Dossier bereitgestellt' },
  { status: 'interview', label: 'Interview vereinbart' },
  { status: 'rejected', label: 'Absage' },
  { status: 'accepted', label: 'Eingestellt' }
];

const BAR_COLORS = ['57, 169, 220', '75, 192, 192', '255, 0, 0', '75, 75, 75'];

const StatusChart = ({ candidateList, height, width, selectedStatus, onClick }) => {
  let data;
  if (candidateList) {
    const customerCandidates = candidateList.filter(candidate => candidate.source === 'customer');
    const internalCandidates = candidateList.filter(candidate => candidate.source === 'internal');
    const activeSearchCandidates = candidateList.filter(candidate => candidate.source === 'activeSearch');
    const advertCandidates = candidateList.filter(candidate => candidate.source === 'advert');

    data = {
      labels: STATUS_BUCKETS.map(b => b.label),
      datasets: [
        {
          label: 'Kundensuche',
          backgroundColor: STATUS_BUCKETS.map(b =>
            selectedStatus === b.status ? `rgba(${BAR_COLORS[0]}, 1)` : `rgba(${BAR_COLORS[0]}, 0.4)`
          ),
          borderColor: `rgba(${BAR_COLORS[0]}, 1)`,
          borderWidth: 1,
          hoverBackgroundColor: `rgba(${BAR_COLORS[0]}, 0.4)`,
          hoverBorderColor: `rgba(${BAR_COLORS[0]}, 1)`,
          data: STATUS_BUCKETS.map(b => customerCandidates.filter(candidate => candidate.allStatus.includes(b.status)).length)
        },
        {
          label: 'Search Service',
          backgroundColor: STATUS_BUCKETS.map(b =>
            selectedStatus === b.status ? `rgba(${BAR_COLORS[1]}, 1)` : `rgba(${BAR_COLORS[1]}, 0.4)`
          ),
          borderColor: `rgba(${BAR_COLORS[1]}, 1)`,
          borderWidth: 1,
          hoverBackgroundColor: `rgba(${BAR_COLORS[1]}, 0.4)`,
          hoverBorderColor: `rgba(${BAR_COLORS[1]}, 1)`,
          data: STATUS_BUCKETS.map(b => internalCandidates.filter(candidate => candidate.allStatus.includes(b.status)).length)
        },
        {
          label: 'Active Sourcing',
          backgroundColor: STATUS_BUCKETS.map(b =>
            selectedStatus === b.status ? `rgba(${BAR_COLORS[2]}, 1)` : `rgba(${BAR_COLORS[2]}, 0.4)`
          ),
          borderColor: `rgba(${BAR_COLORS[2]}, 1)`,
          borderWidth: 1,
          hoverBackgroundColor: `rgba(${BAR_COLORS[2]}, 0.4)`,
          hoverBorderColor: `rgba(${BAR_COLORS[2]}, 1)`,
          data: STATUS_BUCKETS.map(b => activeSearchCandidates.filter(candidate => candidate.allStatus.includes(b.status)).length)
        },
        {
          label: 'Anzeigebundle',
          backgroundColor: STATUS_BUCKETS.map(b =>
            selectedStatus === b.status ? `rgba(${BAR_COLORS[3]}, 1)` : `rgba(${BAR_COLORS[3]}, 0.4)`
          ),
          borderColor: `rgba(${BAR_COLORS[3]}, 1)`,
          borderWidth: 1,
          hoverBackgroundColor: `rgba(${BAR_COLORS[3]}, 0.4)`,
          hoverBorderColor: `rgba(${BAR_COLORS[3]}, 1)`,
          data: STATUS_BUCKETS.map(b => advertCandidates.filter(candidate => candidate.allStatus.includes(b.status)).length)
        }
      ]
    };
  }
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          stacked: true
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            precision: 0
          }
        }
      ]
    },
    onHover: (event, chartElements) => {
      const canvas = event.target;
      canvas.style.cursor = chartElements[0] ? 'pointer' : 'default';
    },
    onClick: (event, chartElements) => {
      if (chartElements.length) {
        // eslint-disable-next-line no-underscore-dangle
        const index = chartElements[0]._index;
        onClick(STATUS_BUCKETS[index].status);
      }
    }
  };

  return (
    <div>
      {candidateList ? (
        <HorizontalBar data={data} width={width} height={height} options={options} />
      ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height, width }}>
          <CircularProgress /> <div className="ml-3">Wird geladen...</div>
        </div>
      )}
    </div>
  );
};

StatusChart.propTypes = {
  candidateList: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  selectedStatus: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

StatusChart.defaultProps = {
  candidateList: undefined,
  height: 250,
  width: 350,
  selectedStatus: undefined
};

export default StatusChart;
