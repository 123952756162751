import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Hidden, Container } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import * as UTILS from '../utils/utilFunctions';
import ProjectList from '../components/profile/ProjectList';
import ResponsivePaper from '../generic_components/ResponsivePaper';
import { readCompanyProfile } from '../api/company';
import useLoading from '../utils/useLoading';

const MyProjects = props => {
  const { onSelectProject } = props;
  const [loading, wrapAsyncLoading] = useLoading();
  const [companyProfile, setCompanyProfile] = useState('INIT');

  useEffect(
    wrapAsyncLoading(async () => {
      const data = await readCompanyProfile();
      setCompanyProfile(data.description);
      document.title = `Meine Aufträge - ${UTILS.getTitle()}`;
    }),
    []
  );

  const renderContent = () => {
    if (companyProfile === 'INIT') return '';
    if (companyProfile && companyProfile.length > 255)
      return (
        <Container fixed>
          <ResponsivePaper className="my-3">
            <Hidden xsDown>
              <Typography variant="h5">Meine Aufträge</Typography>
            </Hidden>
            <p className="mt-3">
              Legen Sie hier mit wenigen Schritten Ihre Aufträge an.
              <br />
              Ein möglichst ausführliches Stellenprofil hilft uns Kandidaten von Ihrer Position zu überzeugen und dient bei Rückfragen der
              Kandidaten als Info.
              <br />
              Starten Sie mit Klick auf das Plus.
            </p>
            <ProjectList onSelectProject={onSelectProject} />
          </ResponsivePaper>
        </Container>
      );
    return <Redirect to="/profile" />;
  };
  return !loading && renderContent();
};
MyProjects.propTypes = {
  onSelectProject: PropTypes.func.isRequired
};
export default MyProjects;
