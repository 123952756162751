import { performGet, performPut, performDelete } from './apiUtils';

export async function readCompanyProfile() {
  return performGet('/matchflix/profile');
}

export async function updateCompanyProfile(updateData) {
  return performPut('/matchflix/profile', updateData);
}

export async function uploadProfilePdf(file) {
  return performPut('/matchflix/profile/pdf', file);
}

export async function getProfilePdfFileInfo() {
  return performGet('/matchflix/profile/pdf');
}

export async function deleteProfilePdf() {
  return performDelete('/matchflix/profile/pdf');
}

export async function uploadProfileLogo(logo) {
  return performPut('/matchflix/profile/logo', logo);
}

export async function getProfileLogo() {
  return performGet('/matchflix/profile/logo');
}
