import { useSnackbar } from 'notistack';
import { logoutLocalUser } from './utilFunctions';

export default function useErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();
  return err => {
    // tslint:disable-next-line: no-console
    console.error(err);
    if (err.status === 401) {
      logoutLocalUser();
    } else {
      enqueueSnackbar('Es ist ein Fehler aufgetreten.', { variant: 'error' });
    }
  };
}
