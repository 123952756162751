import * as React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsIcon from '@material-ui/icons/Settings';

const STATUS_ICONS = {
  'Noch nicht freigegeben': { IconComponent: ScheduleIcon, color: 'action' },
  'In Bearbeitung': { IconComponent: SettingsIcon, color: 'primary' },
  'Erfolgreich abgeschlossen': { IconComponent: CheckIcon, color: 'secondary' },
  Storniert: { IconComponent: CancelIcon, color: 'error' }
};

const ProjectAvatar = ({ status }) => {
  const { IconComponent, ...iconProps } = STATUS_ICONS[status];
  return (
    <>
      <Avatar title={status}>
        <AssignmentIndIcon />
      </Avatar>
      <div style={{ position: 'absolute', right: 8, top: -8 }}>
        <IconComponent {...iconProps} />
      </div>
    </>
  );
};

ProjectAvatar.propTypes = {
  status: PropTypes.string.isRequired
};

export default ProjectAvatar;
