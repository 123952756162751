import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import * as CONSTANTS from './constants';
import * as CONFIG from './config';
import * as UTILS from './utils/utilFunctions';
import { syncStoredToken, readToken, clearStoredToken } from './utils/tokenStorage';
import parseTokenToState from './utils/parseTokenToState';
import ReleaseNotePopUp from './components/ReleaseNotePopUp';

class PageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { verified: false };
    this.props.history.listen(location => {
      this.checkAuthentication(location.pathname);
    });
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentWillMount() {
    this.setState({ verified: false });
    syncStoredToken(() => this.checkAuthentication(window.location.pathname));
  }

  checkAuthentication(currentPage) {
    this.props.liftUpStateToApp('currentPage', currentPage);
    // console.log('currentPage', currentPage);
    // check authentication only if page requires authentication
    // CASE page which require login
    if (!CONFIG.pagesWithoutLogin.includes(currentPage) || currentPage === '/') {
      // console.log('currentPage is /login or page which requires login');
      // CASE token available => validate token
      const tokenFromLocalStorage = readToken();

      if (tokenFromLocalStorage) {
        this.verifyTokenAPIcall(tokenFromLocalStorage);
        // console.log('verify');
      }
      // CASE no token available => redirect to loginPage
      else if (currentPage !== '/') {
        window.location = '/';
        // console.log('verify2');
      }

      // CASE login page without token => show loginPage
      else if (currentPage === '/') {
        this.setState({ verified: true });
      }
    }
    // CASE page which doesn't require login
    else {
      this.setState({ verified: true });
      // console.log('verify3');
    }
  }

  verifyTokenAPIcall(token) {
    this.props.liftUpStateToApp('showLoadingIndicatorLoggedIn', true);
    // console.log('API verifyToken request');
    const url = CONSTANTS.verifyTokenURL;
    const pagesWithoutReleaseNotes = CONFIG.pagesWithoutLogin.concat(['/register', '/registerTan']);
    const requestBody = {
      requestReleaseNote: !pagesWithoutReleaseNotes.includes(this.props.history.location.pathname)
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      headers: { 'x-auth': token }
    })
      .done(responseBody => {
        this.props.liftUpStateToApp(parseTokenToState(token));

        this.props.liftUpStateToApp('notificationCount', responseBody.notificationCount);
        this.props.liftUpStateToApp('loggedIn', true);
        this.props.liftUpStateToApp('showLoadingIndicatorLoggedIn', false);
        this.setState({ releaseNote: responseBody.releaseNote });

        // check if user status allows to see specific pages
        const currentPage = window.location.pathname;
        const userStatus = responseBody.user.status;

        if (
          (['/search', '/results', '/markLists', '/profile', '/myprojects', '/faq', '/notifications'].includes(currentPage) &&
            userStatus === 'active') ||
          (['/register'].includes(currentPage) && userStatus === 'initialLogin') ||
          (['/registerTan'].includes(currentPage) && userStatus === 'passwordSet')
        ) {
          // if yes
          console.log('Verified User');
          this.setState({ verified: true });

          // if no
          // redirect to correct page
        } else if (userStatus === 'initialLogin' && currentPage !== '/register') window.location = '/register';
        else if (userStatus === 'passwordSet' && currentPage !== '/registerTan') window.location = '/registerTan';
        else if (
          userStatus === 'active' &&
          !['/search', '/results', '/profile', '/myprojects', '/faq', '/notifications'].includes(currentPage)
        )
          window.location = '/profile';
        else {
          console.log('WARNUNG: unknown page status');
          clearStoredToken();

          window.sessionStorage.removeItem('token');
          window.location = '/';
        }
      })
      .fail(err => {
        // console.log('API /verify has been called with error', err);

        this.props.liftUpStateToApp('showLoadingIndicatorLoggedIn', false);
        // login with existing token which is invalid (e.g. belongs to a different user)
        if (window.location.pathname === '/') {
          this.setState({ verified: true });
        } else {
          this.errorHandling(err, url, this.props.liftUpStateToApp);
        }
      });
  }

  handleCloseReleaseNotePopUp = () => {
    this.setState({ releaseNote: {} });
  };

  render() {
    const showPage = CONFIG.pagesWithoutLogin.includes(window.location.pathname) || this.state.verified;
    const showReleaseNotePopUp = this.state.releaseNote && this.state.releaseNote.content && !!this.state.releaseNote.content.length;
    return (
      <>
        {showPage && this.props.children}
        {showReleaseNotePopUp && (
          <ReleaseNotePopUp
            icon={this.state.releaseNote.icon}
            headline={this.state.releaseNote.headline}
            content={this.state.releaseNote.content}
            subContent={this.state.releaseNote.subContent}
            adminContent={this.state.releaseNote.adminContent}
            open={showReleaseNotePopUp}
            handleClose={this.handleCloseReleaseNotePopUp}
          />
        )}
      </>
    );
  }
}

PageContainer.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default withRouter(PageContainer);
