export const baseUrl = 'https://api.match-flix.de'; // development
export const partner = 'matchflix'; // personaltotal matchflix senator
export const amountOfResultsURL = `${baseUrl}/getAmountOfResults`;
export const getIntelligentSearchSuggestionsURL = `${baseUrl}/getIntelligentSearchSuggestions`;

export const getSuggestionsURL = `${baseUrl}/getSuggestionSearchTerms`;
export const getSearchResultsURL = `${baseUrl}/getSearchResults`;
export const getNASearchResultsURL = `${baseUrl}/getNASearchResults`;
export const getSearchResultsByMarkListIdURL = `${baseUrl}/getSearchResultsByMarkListId`;
export const loginURL = `${baseUrl}/login`;
export const logoutURL = `${baseUrl}/logout`;
export const verifyTokenURL = `${baseUrl}/user`;
export const saveSearchURL = `${baseUrl}/saveSearch`;
export const getSavedSearchesURL = `${baseUrl}/getSavedSearches`;
export const getDeleteSearchesURL = `${baseUrl}/deleteSavedSearch`;
export const sendOrderURL = `${baseUrl}/sendOrder`;
export const getCandidateDocumentByIdURL = `${baseUrl}/getCandidateDocumentById`;
export const inviteUserToRegistrationURL = `${baseUrl}/inviteUserToRegistration`;
export const registerURL = `${baseUrl}/register`;
export const get2FASecretURL = `${baseUrl}/get2FASecret`;
export const verifyTanURL = `${baseUrl}/verifyTan`;
export const addItemToMarkListURL = `${baseUrl}/addItemToMarkList`;
export const loadMarkListsURL = `${baseUrl}/loadMarkLists`;
export const loadPopulatedMarkListsURL = `${baseUrl}/loadPopulatedMarkLists`;
export const deleteMarkListURL = `${baseUrl}/deleteMarkList`;
export const removeItemFromMarkListURL = `${baseUrl}/removeItemFromMarkList`;
export const geoLocationLookUpURL = `${baseUrl}/geoLocationLookUp`;

export const candidatePlaceholderPictureURL = '/avatar.png';
