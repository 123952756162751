import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';

import TEXT from '../../../text';
import * as FILTER_HELPER from '../../../utils/filterHelperFunctions';

class PostalCodeFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      range: this.props.range,
      rangeOptions: { rangeOptions: { stepSize: 10, max: 250, markCount: 6, offset: 50, showInfinity: true } }
    };
    this.onChange = this.onChange.bind(this);
    this.onAfterChange = this.onAfterChange.bind(this);
  }

  componentDidMount() {
    if (this.props.maxRange === 250) {
      this.setState({ rangeOptions: { stepSize: 10, max: 250, markCount: 6, offset: 50, showInfinity: true } });
    } else {
      const rangeOptions = this.generateRangeOptions(this.props.maxRange);
      this.setState({ rangeOptions });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ range: nextProps.range });
  }

  onChange(range) {
    this.setState({ range });
  }

  onAfterChange(range) {
    this.props.onFilterChange('distance', range, FILTER_HELPER.filterFunctions.distance);
  }

  generateMarks(markCount, offset, showInfinity) {
    const markValues = Array.from(Array(markCount), (_, x) => x * offset);
    const mappedMarks = markValues.reduce(
      (accumulator, currentEntry) => ({
        ...accumulator,
        [currentEntry]: `${currentEntry}km`
      }),
      {}
    );

    mappedMarks[`${markValues[0]}`] = '0';
    if (showInfinity) {
      mappedMarks[`${markValues[markCount - 1]}`] = '∞';
    }

    return mappedMarks;
  }

  generateRangeOptions(maxDistance) {
    let result;
    if (maxDistance < 9) {
      result = {
        stepSize: 1,
        max: maxDistance,
        markCount: maxDistance + 1,
        offset: 1,
        showInfinity: false
      };
    } else if (maxDistance < 50) {
      result = {
        stepSize: 1,
        max: 50,
        markCount: 5,
        offset: 10,
        showInfinity: false
      };
    } else if (maxDistance < 80) {
      result = {
        stepSize: 1,
        max: 80,
        markCount: 5,
        offset: 20,
        showInfinity: false
      };
    } else if (maxDistance < 100) {
      result = {
        stepSize: 1,
        max: maxDistance,
        markCount: 5,
        offset: 20,
        showInfinity: false
      };
    } else if (maxDistance < 150) {
      result = {
        stepSize: 1,
        max: maxDistance,
        markCount: 5,
        offset: 25,
        showInfinity: false
      };
    } else if (maxDistance < 200) {
      result = {
        stepSize: 1,
        max: maxDistance,
        markCount: 4,
        offset: 50,
        showInfinity: false
      };
    } else {
      result = { stepSize: 10, max: 240, markCount: 5, offset: 50, showInfinity: false };
    }
    return result;
  }

  render() {
    const capDistanceSymbol = 'unendlich';
    const subCapDistanceSymbol = `${this.state.range[1] === this.props.maxRange ? this.props.maxRange : this.state.range[1]}km`;
    const headline = `${TEXT.filter.mainFilter.postalCode} (${this.state.range[0]}km - ${
      this.state.range[1] > 240 && this.props.maxRange === 250 ? capDistanceSymbol : subCapDistanceSymbol
    }):`;
    const options = this.state.rangeOptions;
    return (
      <div className="col-12 pb-5">
        <p>{headline}</p>
        <Range
          value={this.state.range}
          step={options.stepSize}
          min={0}
          max={options.max}
          marks={this.generateMarks(options.markCount, options.offset, options.showInfinity)}
          onChange={this.onChange}
          onAfterChange={this.onAfterChange}
        />
      </div>
    );
  }
}

PostalCodeFilter.defaultProps = {
  maxRange: 250
};

PostalCodeFilter.defaultProps = {};
PostalCodeFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  maxRange: PropTypes.number
};

export default PostalCodeFilter;
