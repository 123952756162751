import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const Step = ({ title, guidance, grid, children, onValidate }) => {
  if (grid) {
    return (
      <Grid key={title} container spacing={3} className="spacing-x">
        {children}
      </Grid>
    );
  }
  return <div key={title}>{children}</div>;
};

Step.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  guidance: PropTypes.node.isRequired,
  grid: PropTypes.bool,
  onValidate: PropTypes.func
};

Step.defaultProps = {
  grid: false,
  onValidate: undefined
};

export default Step;
