import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { STATUS_BUCKETS } from '../profile/StatusChart';

function mapStatus(status) {
  return (STATUS_BUCKETS.find(b => b.status === status) || {}).label;
}

const CandidateStatusPanel = ({ status }) => (
  <div className="mt-3 mb-1">
    <Chip label={<span style={{whiteSpace: 'normal'}}>{mapStatus(status)}</span>} />
  </div>
);

CandidateStatusPanel.propTypes = {
  status: PropTypes.string.isRequired
};

export default CandidateStatusPanel;
