import { performGet, performPost, performPatch, performPut, performDelete } from './apiUtils';

export async function getProjectList() {
  return performGet('/matchflix/projects');
}

export async function getProjectById(projectId) {
  return performGet(`/matchflix/projects/${projectId}`);
}
export async function getLastSearchByProjectById(projectId) {
  return performGet(`/matchflix/projects/${projectId}/lastSearch`);
}

export async function createProject(projectData) {
  return performPost('/matchflix/projects', projectData);
}

export async function updateProject(projectId, updateData) {
  return performPatch(`/matchflix/projects/${projectId}`, updateData);
}

export async function addCandidateToProject(projectId, candidateId, score, willingnessToChange) {
  return performPost(`/matchflix/projects/${projectId}/candidates`, { candidateId, score, willingnessToChange });
}

export async function removeCandidateFromProject(projectId, candidateId) {
  return performDelete(`/matchflix/projects/${projectId}/candidates/${candidateId}`);
}

export async function getProjectCandidateList(projectId) {
  return performGet(`/matchflix/projects/${projectId}/candidates`);
}

export async function getCandidateById(projectId, candidateId) {
  return performGet(`/matchflix/projects/${projectId}/candidates/${candidateId}`);
}

export async function getProjectFileInfo(projectId, fileName) {
  return performGet(`/matchflix/projects/${projectId}/files/${fileName}`);
}
export async function getCandidateFileInfo(candidateId, projectId) {
  return performGet(`/matchflix/projects/${projectId}/candidates/${candidateId}/dossier`);
}

export async function saveProjectFile(projectId, fileName, file) {
  return performPut(`/matchflix/projects/${projectId}/files/${fileName}`, file);
}

export async function deleteProjectFile(projectId, fileName) {
  return performDelete(`/matchflix/projects/${projectId}/files/${fileName}`);
}
