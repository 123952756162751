import * as React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import useLoading from '../../utils/useLoading';
import { getCandidateById } from '../../api/projects';
import * as CONSTANTS from '../../constants';
import SingleSearchResult from '../SingleSearchResult';
import { formatAge } from '../../utils/candidate';

const CandidateDetails = ({ projectId, candidateId, showRatings }) => {
  const [candidate, setCandidate] = React.useState();
  const [loading, wrapAsyncLoading] = useLoading(true);
  React.useEffect(
    wrapAsyncLoading(async () => {
      if (candidateId) {
        const apiResponse = await getCandidateById(projectId, candidateId);
        setCandidate({ ...apiResponse, picture: CONSTANTS.candidatePlaceholderPictureURL });
      }
    }),
    [candidateId]
  );
  return loading || !candidate ? (
    <div className="d-flex align-items-center">
      <CircularProgress /> <div className="ml-3">Wird geladen...</div>
    </div>
  ) : (
    <SingleSearchResult {...candidate} showRatings={showRatings} score={candidate.score || 5} age={formatAge(candidate)} />
  );
};

CandidateDetails.propTypes = {
  projectId: PropTypes.string.isRequired,
  candidateId: PropTypes.string,
  showRatings: PropTypes.bool
};

CandidateDetails.defaultProps = {
  candidateId: undefined,
  showRatings: true
};

export default CandidateDetails;
