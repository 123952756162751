import React from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@material-ui/core';

export default function DialogWrapper({
  acceptButtonName,
  cancelButtonName,
  children,
  dialogTitle,
  dialogContentText,
  handleClose,
  handleSave,
  open,
  maxWidth,
  fullWidth,
  icon
}) {
  const generateTitle = () => {
    let result = dialogTitle;
    if (icon) {
      result = (
        <>
          <Typography variant="h3" component="div" gutterBottom align="center">
            <i className="material-icons" style={{ color: '#39a9dc', fontSize: '1.750em' }}>
              {icon}
            </i>
          </Typography>
          <Typography variant="h6" align="center">
            {dialogTitle}
          </Typography>
        </>
      );
    }
    return result;
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby={`dialog-${dialogTitle}`} maxWidth={maxWidth} fullWidth={fullWidth}>
      <DialogTitle id={`dialog-${dialogTitle}`}>{generateTitle()}</DialogTitle>
      <DialogContent>
        {dialogContentText && <DialogContentText>{dialogContentText}</DialogContentText>}
        <div className={dialogContentText ? 'pt-3' : ''}>{children}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelButtonName}
        </Button>
        {handleSave && (
          <Button onClick={handleSave} variant="contained" color="primary">
            {acceptButtonName}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

DialogWrapper.defaultProps = {
  acceptButtonName: 'Accept',
  cancelButtonName: 'Cancel',
  fullWidth: false,
  dialogContentText: '',
  handleSave: undefined,
  maxWidth: undefined,
  icon: undefined
};

DialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogContentText: PropTypes.node,
  dialogTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  children: PropTypes.node.isRequired,
  acceptButtonName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cancelButtonName: PropTypes.string,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string
};
