import * as React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, ListItemText, ListItemIcon, Button } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

function OverflowIconMenu({ max, actions, iconsOnly }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  // the maximum item priority that is allowed to be moved to the overflow menu
  let maxPrio = 0;

  // move action to the menu until at most (max) actions are left in the button bar
  const iconActions = [...actions];
  const menuActions = [];
  while (iconActions.length > max) {
    let idx;
    // eslint-disable-next-line no-cond-assign,no-loop-func
    while ((idx = iconActions.findIndex(a => (a.priority || 0) <= maxPrio)) < 0) {
      maxPrio++;
    }
    const a = iconActions.splice(idx, 1);
    menuActions.push(a[0]);
  }

  return (
    <>
      {iconActions.map(action =>
        iconsOnly ? (
          <IconButton
            key={action.title}
            edge="end"
            aria-label={action.aria}
            title={action.title}
            disabled={action.disabled}
            onClick={action.handler}
            color={action.color}
          >
            <action.icon />
          </IconButton>
        ) : (
          <Button key={action.title} disabled={action.disabled} onClick={action.handler} color={action.color} variant={action.variant}>
            {action.icon && <action.icon style={{ marginRight: 10 }} />} {action.title}
          </Button>
        )
      )}
      {menuActions.length > 0 && (
        <>
          <IconButton edge={iconsOnly ? 'end' : undefined} aria-label="menu" onClick={handleOpen}>
            <MoreHorizIcon />
          </IconButton>
          <Menu id="overflow-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {menuActions.map(action => (
              <MenuItem
                key={action.title}
                disabled={action.disabled}
                onClick={() => {
                  action.handler();
                  handleClose();
                }}
              >
                {action.icon && (
                  <ListItemIcon>
                    <action.icon />
                  </ListItemIcon>
                )}
                <ListItemText primary={action.title} />
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
}

OverflowIconMenu.propTypes = {
  max: PropTypes.number,
  actions: PropTypes.array.isRequired,
  iconsOnly: PropTypes.bool
};

OverflowIconMenu.defaultProps = {
  max: 1,
  iconsOnly: false
};

export default OverflowIconMenu;
