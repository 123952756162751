import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DurationCurrentJobFilter from './jobFilters/DurationCurrentJobFilter';
import WorkExperienceFilter from './jobFilters/WorkExperienceFilter';
import TitleCurrentJobFilter from './jobFilters/TitleCurrentJobFilter';
import IndustrySectorFilter from './jobFilters/IndustrySectorFilter';
import './FilterStyles.css';

class JobFilterComponent extends PureComponent {
  render() {
    return (
      <div className="container-fluid filterContainer jobFilter">
        <div className="row text-left">
          <TitleCurrentJobFilter
            onFilterChange={this.props.onFilterChange}
            numberOfShownFilterOptionForCurrentPositionFilter={this.props.numberOfShownFilterOptionForCurrentPositionFilter}
            countedJobParameters={this.props.countedJobParameters}
            // TODO Warning: Failed prop type: Invalid prop `countedJobParameters`
            // of type `object` supplied to `TitleCurrentJobFilter`, expected an array.
            titleCurrentJob={this.props.titleCurrentJob}
            titleCurrentJobFilteredOptions={this.props.titleCurrentJobFilteredOptions}
            showMoreFilterOptions={this.props.showMoreFilterOptions}
            liftUpFilteredOptions={this.props.liftUpFilteredOptions}
          />
        </div>

        <div className="row text-left">
          <DurationCurrentJobFilter
            onFilterChange={this.props.onFilterChange}
            // TODO Warning: Failed prop type: Invalid prop `countedJobParameters`
            // of type `object` supplied to `DurationCurrentJobFilter`, expected an array.
            durationCurrentJob={this.props.durationCurrentJob}
            countedJobParameters={this.props.countedJobParameters}
          />
        </div>

        <div className="row text-left">
          <WorkExperienceFilter
            onFilterChange={this.props.onFilterChange}
            // TODO Warning: Failed prop type: Invalid prop
            workExperience={this.props.workExperience}
            countedJobParameters={this.props.countedJobParameters}
          />
        </div>
        <div className="row text-left">
          <IndustrySectorFilter
            onFilterChange={this.props.onFilterChange}
            countedJobParameters={this.props.countedJobParameters}
            industrySectorName={this.props.industrySectorName}
          />
        </div>
      </div>
    );
  }
}
JobFilterComponent.defaultProps = {};

JobFilterComponent.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  durationCurrentJob: PropTypes.arrayOf(PropTypes.string).isRequired,
  liftUpFilteredOptions: PropTypes.func.isRequired,
  numberOfShownFilterOptionForCurrentPositionFilter: PropTypes.number.isRequired,
  countedJobParameters: PropTypes.object.isRequired,
  titleCurrentJob: PropTypes.string.isRequired,
  titleCurrentJobFilteredOptions: PropTypes.object.isRequired,
  showMoreFilterOptions: PropTypes.func.isRequired,
  workExperience: PropTypes.shape({
    parameters: PropTypes.arrayOf(PropTypes.string).isRequired,
    filterFunction: PropTypes.func,
    isSet: PropTypes.bool.isRequired
  }).isRequired,
  industrySectorName: PropTypes.shape({
    parameters: PropTypes.arrayOf(PropTypes.string).isRequired,
    filterFunction: PropTypes.func,
    isSet: PropTypes.bool.isRequired
  }).isRequired
};
export default JobFilterComponent;
