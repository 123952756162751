/* eslint-disable no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid, Button } from '@material-ui/core';
import PostalCodeAutosuggest2 from '../../generic_components/PostalCodeAutosuggest2';
import SwitchField from '../../generic_components/SwitchField';
import SliderField from '../../generic_components/SliderField';
import FileUploadField from '../../generic_components/FileUploadField';
import CheckBoxField from '../../generic_components/CheckBoxField';
import Step from './Step';
import ProjectDetailsStepper from './ProjectDetailsStepper';
import * as IVA from './ivaTexts';
import BookingOptionSelection from '../bookingOptions/BookingOptionSelection';
import ConfirmationModal from '../../generic_components/ConfirmationModal';
import { mapBookingOptionName } from '../../utils/utilFunctions';

const SALARY_MARKS = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(v => ({ value: v * 10000, label: `${v * 10}k` }));
const AGE_MARKS = [15, 20, 30, 40, 50, 60, 70, 80, 90, 99].map(v => ({ value: v, label: v }));
const URL_PATTERN = { pattern: 'https?://.*' };

const fmtThousands = v => `${Math.trunc(v / 1000)}k`;

function validate(projectData) {
  if (!projectData.salary || !projectData.salary[1] || projectData.salary[1] > (projectData.maxSalary || 0)) {
    return false;
  }
  return true;
}

const ProjectDetailsWizard = ({ readOnly, projectData, setProjectData, isExistingProject, onDownloadAdvertFile, onSave }) => {

  const [isUpdateBookingConfirmationOpen, setIsUpdateBookingConfirmationOpen] = React.useState(false);
  const [newBookingOptions, setNewBookingOptions] = React.useState(projectData.bookingOptions || {});

  const readOnlyOptions = projectData.bookingOptions 
    ? Object.keys(projectData.bookingOptions).filter((option) => projectData.bookingOptions[option]) 
    : [];

  React.useEffect(() => {
    // when the dialog is open, we have to save the confirmed data
    // in all other cases no save is needed, as it's done somewhere else
    if (isUpdateBookingConfirmationOpen) {
      setIsUpdateBookingConfirmationOpen(false);
      onSave();
    }
  }, [projectData])

  function bind(field) {
    return {
      value: projectData[field],
      onChange: React.useCallback(
        evOrValue => {
          const value = evOrValue && evOrValue.target ? evOrValue.target.value : evOrValue;
          setProjectData(data => ({ ...data, [field]: value }));
        },
        [field, setProjectData]
      ),
      readOnly,
      ...(readOnly
        ? {
            inputProps: {
              placeholder: ''
            },
            InputProps: {
              readOnly: true,
              disableUnderline: true,
              placeholder: ''
            },
            InputLabelProps: {
              shrink: true,
              focused: false
            }
          }
        : {})
    };
  }
  const [advancedView, setAdvancedView] = React.useState(isExistingProject);
  const [salaryInvalid, setSalaryInvalid] = React.useState(false);

  return (
    <>
    <ConfirmationModal
        open={isUpdateBookingConfirmationOpen}
        handleClose={() => {
          setIsUpdateBookingConfirmationOpen(false);
          setNewBookingOptions(projectData.bookingOptions || {})
        }}
        buttonPrimaryAction={() => {
          setProjectData(data => ({ ...data, 'bookingOptions': newBookingOptions }));
          // onSave() is called in useEffect, after projectData is updated
        }}
        headlineText="Nur noch ein Klick!"
        descriptionText={`Hilfe naht! Sie haben "${Object.keys(newBookingOptions).filter((selectedOption) => {
            if (newBookingOptions[selectedOption] === true && 
                (!projectData.bookingOptions || projectData.bookingOptions[selectedOption] !== true)) {
                  return true;
            }
            return false;
          }
        ).map(mapBookingOptionName).join(', ')}" ausgewählt`}
        icon={<img src="/iva.gif" alt="iva" width="128" />}
      >
        <p>Jetzt verbindlich buchen?</p>
        <p>Dies kann nach dem Speichern nicht rückgängig gemacht werden.</p>
    </ConfirmationModal>
    <ProjectDetailsStepper flatten={advancedView} onSave={() => {
      let anyNewOption = false;
      Object.keys(newBookingOptions).forEach((option) => {
        if (newBookingOptions[option] === true && 
           (!projectData.bookingOptions || projectData.bookingOptions[option] !== true)) {
          anyNewOption = true;
        }
      })

      if (anyNewOption) {
        setIsUpdateBookingConfirmationOpen(true);
      } else {
        onSave();
      }
    }}>
      <Step grid title="Basisangaben" guidance={<IVA.step1 setAdvancedView={setAdvancedView} />}>
        <Grid item xs={12}>
          <TextField {...bind('title')} label="Position / Anzeigentitel" fullWidth required autoFocus />
        </Grid>
        <Grid item xs={12}>
          <PostalCodeAutosuggest2 {...bind('address')} label="Arbeitsort" placeholder="Postleitzahl oder Ort eingeben" required />
        </Grid>
      </Step>
      <Step grid title="Angaben zur Position" guidance={<IVA.step2 title={projectData.title} />}>
        <Grid item xs={12}>
          <TextField {...bind('positionFunction')} label="Funktion" fullWidth required autoFocus={!advancedView} />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('tasks')} label="Aufgaben, Schwerpunkte" fullWidth multiline />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('specialTasks')} label="Standard- / Spezialfunktion" fullWidth multiline />
        </Grid>
      </Step>
      <Step grid title="Hierarchie und Verantwortung" guidance={<IVA.step3 />}>
        <Grid item md={6} xs={12}>
          <TextField {...bind('superiors')} label="Führungsverantwortung" fullWidth autoFocus={!advancedView} />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField {...bind('subordinates')} label="Teammitglied?" placeholder="Anzahl Mitarbeiter" type="number" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('respBudget')} label="Verantwortung für Budget/Umsatz etc." fullWidth multiline />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('respEmployees')} label="Verantwortung für Mitarbeiter" fullWidth multiline />
        </Grid>
      </Step>
      <Step grid title="Gewünschte Qualifikationen" guidance={<IVA.step4 />}>
        <Grid item md={6} xs={12}>
          <SliderField {...bind('age')} label="Alter" min={15} max={99} step={1} marks={AGE_MARKS} />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('education')} label="Ausbildung" fullWidth multiline />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('career')} label="Berufliche Entwicklung" fullWidth multiline />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('skills')} label="Fachkenntnisse" fullWidth multiline />
        </Grid>
        <Grid item xs={12}>
          <TextField {...bind('qualifications')} label="Zusatzqualifikationen" fullWidth multiline />
        </Grid>
        <Grid item md={6} xs={12}>
          <SwitchField {...bind('leadershipExp')} label="Führungserfahrung erforderlich" />
        </Grid>
      </Step>
      <Step title="Wechselgründe" guidance={<IVA.step5 />}>
        <TextField
          {...bind('previousChanges')}
          label="Wie oft und aus welchen Gründen sind Wechsel erfolgt?"
          fullWidth
          multiline
          autoFocus={!advancedView}
        />
        <TextField
          {...bind('reasonsForEstablishment')}
          label="Bei neuer Position: Welche Gründe gibt es für die Einrichtung?"
          fullWidth
          multiline
        />
        <TextField {...bind('recruiting')} label="Welche Rekrutierungsbemühungen gab es bisher?" fullWidth multiline />
        <TextField
          {...bind('reasonsForChange')}
          label="Nennen Sie 3 Gründe, warum der Kandidat zu Ihrem Unternehmen wechseln soll"
          fullWidth
          multiline
        />
      </Step>
      <Step
        grid
        title="Dotierung"
        guidance={<IVA.step6 />}
        onValidate={() => {
          const valid = validate(projectData);
          setSalaryInvalid(!valid);
          return valid;
        }}
      >
        <Grid item md={6} xs={12}>
          <SliderField
            {...bind('salary')}
            label="Zieljahreseinkommen"
            min={30000}
            max={150000}
            step={1000}
            marks={SALARY_MARKS}
            valueLabelFormat={fmtThousands}
            error={salaryInvalid}
            errorMessage="Bitte wählen Sie einen Maximalbetrag, der über dem Zieleinkommen liegt."
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SliderField
            {...bind('maxSalary')}
            label="Maximal möglicher Betrag"
            min={30000}
            max={150000}
            step={1000}
            marks={SALARY_MARKS}
            valueLabelFormat={fmtThousands}
            error={salaryInvalid}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField {...bind('wageGroup')} label="Tarifgruppe" fullWidth />
        </Grid>
      </Step>
      <Step title="Zusatzleistungen" guidance={<IVA.step7 />}>
        <TextField
          {...bind('compensation')}
          label="Erfolgsbestandteile? / Variable Komponenten"
          fullWidth
          multiline
          autoFocus={!advancedView}
        />
        <TextField {...bind('workingTime')} label="Arbeitszeit / Modelle" fullWidth multiline />
        <TextField {...bind('benefits')} label="Sozialleistungen / Benefits (Firmenwagen, Altersvorsorge, etc.)" fullWidth multiline />
        <TextField {...bind('perspective')} label="Perspektive: Welche Entwicklungsmöglichkeiten gibt es?" fullWidth multiline />
      </Step>
      <Step title="Weitere Angaben" guidance={<IVA.step8 />}>
        <TextField
          {...bind('advertLink')}
          label="Link zu bestehender Anzeige"
          fullWidth
          autoFocus={!advancedView}
          placeholder="z.B. https://www.linkedin.com/jobs/view/1234567890"
          inputProps={URL_PATTERN}
        />
        <div className="mt-3">
          <FileUploadField
            {...bind('advertFile')}
            label="Stellenanzeige / Anforderungsprofil hochladen"
            onResolveFileUrl={onDownloadAdvertFile}
          />
        </div>
        <TextField {...bind('other')} label="Sonstige Punkte" fullWidth multiline />
        <div className="mt-3">
        
        <BookingOptionSelection
          readOnlyOptions={readOnlyOptions}
          {...bind('bookingOptions')}
          value={newBookingOptions /* overwrite value source from bind as it is cached and only written 
          to the project if the user confirmed the dialog which appears on the save button */}
          onChange={(updatedBookingOptions) => {
            setNewBookingOptions(updatedBookingOptions);
          }}
          />
        </div>
      </Step>
    </ProjectDetailsStepper>
    </>
  );
};

ProjectDetailsWizard.propTypes = {
  readOnly: PropTypes.bool,
  projectData: PropTypes.object,
  setProjectData: PropTypes.func.isRequired,
  isExistingProject: PropTypes.bool,
  onDownloadAdvertFile: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

ProjectDetailsWizard.defaultProps = {
  readOnly: false,
  projectData: {},
  isExistingProject: false
};

export default ProjectDetailsWizard;
