import React, { Component } from 'react';
import './ErrorPage.css';
import Typography from '@material-ui/core/Typography';

import TEXT from '../text';

class ErrorPage extends Component {
  render() {
    console.log('INFO: render of ErrorPage');
    return (
      <div className="container-fluid  h-100-minusAppBar" key="maincontainer">
        <div className="row justify-content-center align-items-center h-100">
          <div className="Login col col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <Typography variant="display3" gutterBottom>
              {TEXT.errorPage.headlineLarge}
            </Typography>
            <h1>{TEXT.errorPage.headlineSmall}</h1>
            <p>{TEXT.errorPage.infoMessage}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
