import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AppBar, Badge, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import MainMenuDrawer from './MainMenuDrawer';
import AccountMenu from './AccountMenu';
// import UserGuideMenu from './UserGuideMenu';
import MobileOptions from './MobileOptions';

import * as CONSTANTS from '../../constants';
import TEXT from '../../text';
import MobileProjectSelector from './MobileProjectSelector';

const systemPartner = CONSTANTS.partner;
const THEME = require('../../theme')[systemPartner];

function NotificationCountButton({ notificationCount, routingPath }) {
  return (
    <IconButton component={Link} to={routingPath} color="inherit">
      {notificationCount !== 0 ? (
        <Badge badgeContent={notificationCount} color="error">
          <i className="material-icons">notifications</i>
        </Badge>
      ) : (
        <i className="material-icons">notifications</i>
      )}
    </IconButton>
  );
}

NotificationCountButton.propTypes = {
  notificationCount: PropTypes.number.isRequired,
  routingPath: PropTypes.string.isRequired
};

class MenuBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuDrawerOpen: false
    };
  }

  renderBurgerMenu() {
    return (
      <>
        <IconButton className="mainMenuDrawer" color="inherit" onClick={() => this.setState({ menuDrawerOpen: true })}>
          <i className="material-icons">menu</i>
        </IconButton>

        <MainMenuDrawer open={this.state.menuDrawerOpen} onCloseMainMenu={() => this.setState({ menuDrawerOpen: false })} />
      </>
    );
  }

  renderMatchFlixButton() {
    return (
      <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
        <Button component={Link} to="/myprojects" color="inherit">
          <img src={THEME.logo} alt={TEXT.imageAlt} className="headerLogo" />
          {this.isMobile() ? '' : THEME.name}
        </Button>
      </Typography>
    );
  }

  renderClearSearchButton() {
    return (
      <Button color="inherit" onClick={this.props.resetSearch}>
        {TEXT.navBar.resetSearch}
        &nbsp;
        <i className="material-icons">autorenew</i>
      </Button>
    );
  }

  renderModifySearchButton() {
    return (
      <Button component={Link} to="/search" color="inherit" onClick={this.props.setMarkedCount}>
        {TEXT.navBar.changeSearch}
        &nbsp;
        <i className="material-icons">search</i>
      </Button>
    );
  }

  renderLoadSearchButton() {
    return (
      <Button color="inherit" onClick={this.props.handleLoadSearchBoxOpen}>
        {TEXT.navBar.loadSearch}
        &nbsp;
        <i className="material-icons">open_in_browser</i>
      </Button>
    );
  }

  renderSaveSearchButton() {
    return (
      <Button color="inherit" onClick={this.props.handleSaveSearchBoxOpen}>
        {TEXT.navBar.saveSearch}
        &nbsp;
        <i className="material-icons">save</i>
      </Button>
    );
  }

  renderOpenProjectSelectorButton() {
    return (
      <Button color="inherit" onClick={this.props.openProjectSelectorHandler}>
        <i className="material-icons">domain</i>
        &nbsp;
        {this.props.selectedProjectName || 'Bitte ein Auftrag auswählen'}
      </Button>
    );
  }

  renderOpenProjectCandidateListSummary() {
    return (
      <Button onClick={this.props.openSelectedProjectSummaryDrawer} color="inherit" disabled={!this.props.selectedProjectName}>
        {this.props.selectedProjectName ? (
          <Badge style={{ marginRight: '15px' }} badgeContent={this.props.selectedProjectCandidateCount} color="secondary" showZero>
            <PlaylistAddCheckIcon />
          </Badge>
        ) : (
          <PlaylistAddCheckIcon />
        )}
        {!this.isMobile() && 'Kandidaten freigeben'}
      </Button>
    );
  }

  isInPageContext = contextList => contextList.includes(this.props.currentPageContext);

  isMobile() {
    return !isWidthUp('sm', this.props.screenWidth);
  }

  render() {
    return (
      <>
        <AppBar position="fixed" style={THEME.appBar}>
          <Toolbar>
            {this.renderBurgerMenu()}

            {this.renderMatchFlixButton()}

            <Typography variant="h6" color="inherit" style={{ flex: 4 }} align="center">
              {this.isInPageContext(['/search', '/results']) && !this.isMobile() && this.renderOpenProjectSelectorButton()}
              {this.isInPageContext(['/search', '/results']) && !this.isMobile() && this.renderOpenProjectCandidateListSummary()}
            </Typography>

            {this.isInPageContext(['/search']) && !this.isMobile() && this.renderClearSearchButton()}

            {this.isInPageContext(['/results']) && !this.isMobile() && this.renderModifySearchButton()}

            {this.isInPageContext(['/results']) && !this.isMobile() && this.renderSaveSearchButton()}

            {this.isInPageContext(['/search', '/results']) && !this.isMobile() && this.renderLoadSearchButton()}

            {this.isMobile() && (
              <>
                <MobileProjectSelector
                  isInPageContext={this.isInPageContext}
                  selectedProject={this.props.selectedProjectName}
                  onClick={this.props.openProjectSelectorHandler}
                />
                {this.isInPageContext(['/search', '/results']) && this.renderOpenProjectCandidateListSummary()}
                <MobileOptions
                  currentPageContext={this.props.currentPageContext}
                  isInPageContext={this.isInPageContext}
                  resetSearch={this.props.resetSearch}
                  handleLoadSearchBoxOpen={this.props.handleLoadSearchBoxOpen}
                  handleSaveSearchBoxOpen={this.props.handleSaveSearchBoxOpen}
                  setMarkedCount={this.props.setMarkedCount}
                />
              </>
            )}
            <NotificationCountButton notificationCount={this.props.notificationCount} routingPath="/notifications" />
            <AccountMenu logoutApiCall={this.props.logoutApiCall} />
            {/* <UserGuideMenu /> */}
          </Toolbar>
        </AppBar>
        <div style={{ height: 64 }}>{/* Shim - Not visible */}</div>
      </>
    );
  }
}

MenuBar.defaultProps = {
  selectedProjectCandidateCount: undefined,
  selectedProjectName: undefined
};

MenuBar.propTypes = {
  logoutApiCall: PropTypes.func.isRequired,
  currentPageContext: PropTypes.string.isRequired,
  resetSearch: PropTypes.func.isRequired,
  handleLoadSearchBoxOpen: PropTypes.func.isRequired,
  handleSaveSearchBoxOpen: PropTypes.func.isRequired,
  setMarkedCount: PropTypes.func.isRequired,
  screenWidth: PropTypes.string.isRequired,
  selectedProjectName: PropTypes.string,
  selectedProjectCandidateCount: PropTypes.number || undefined,
  openProjectSelectorHandler: PropTypes.func.isRequired,
  openSelectedProjectSummaryDrawer: PropTypes.func.isRequired,
  notificationCount: PropTypes.number.isRequired
};

export default MenuBar;
