import * as React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { makeStyles, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 2,
    padding: 15,
    color: theme.palette.text.hint,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    '&:focus': {
      borderColor: theme.palette.primary[theme.palette.type]
    }
  },
  active: { borderColor: theme.palette.primary[theme.palette.type] },
  accept: { borderColor: '#00e676' },
  reject: { borderColor: theme.palette.error[theme.palette.type] },
  fileRow: {
    '& .hoverIcon': {
      display: 'none'
    },
    '&:hover .icon': {
      display: 'none'
    },
    '&:hover .hoverIcon': {
      display: 'inline-block'
    }
  }
}));

const DropzoneContainer = ({ placeholder, accept, disabled, onDrop }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept,
    disabled,
    onDrop: (acceptedFiles, rejectedFiles, event) => {
      event.preventDefault();
      if (rejectedFiles && rejectedFiles.length) {
        enqueueSnackbar(`${rejectedFiles[0].name}: Der Dateityp wird nicht unterstützt.`, { variant: 'error' });
        return;
      }
      if (acceptedFiles && acceptedFiles.length) {
        onDrop(acceptedFiles);
      }
    }
  });

  return (
    <div
      {...getRootProps({
        className: `${classes.dropzone} ${isDragActive ? classes.active : ''} ${isDragAccept ? classes.accept : ''} ${
          isDragReject ? classes.reject : ''
        }`
      })}
    >
      <input {...getInputProps()} />
      <Typography variant="h5" component="p">
        {placeholder}
      </Typography>
      <CloudUploadIcon fontSize="large" />
    </div>
  );
};

DropzoneContainer.propTypes = {
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  onDrop: PropTypes.func.isRequired
};

DropzoneContainer.defaultProps = {
  placeholder: 'Datei hier ablegen oder mit Klick auswählen',
  accept: 'application/pdf',
  disabled: false
};

export default DropzoneContainer;
