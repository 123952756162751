import isEqual from 'lodash/isEqual';
import * as React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Container } from '@material-ui/core';
import useLoading from '../../utils/useLoading';
import { getProjectById, createProject, updateProject, saveProjectFile, deleteProjectFile, getProjectFileInfo } from '../../api/projects';
import ResponsivePaper from '../../generic_components/ResponsivePaper';
import ProjectDetailsWizard from '../project/ProjectDetailsWizard';

function setDefaults(projectData) {
  return {
    age: [15, 99],
    salary: [30000, 150000],
    maxSalary: 30000,
    leadershipExp: false,
    ...projectData
  };
}

const ProjectDetails = ({ projectId, useAsTemplate, onSave }) => {
  const [loading, wrapAsyncLoading] = useLoading();
  const wrapAsyncSaving = useLoading()[1];
  const [projectData, setProjectData] = React.useState(setDefaults({}));
  const [initialProjectData, setInitialProjectData] = React.useState(setDefaults({}));

  React.useEffect(
    wrapAsyncLoading(async () => {
      const loadedData = projectId ? await getProjectById(projectId) : {};

      // do not use the booking options from a template
      if (useAsTemplate) {
        loadedData.bookingOptions = {};
      }
     
      setProjectData(setDefaults(loadedData));
      setInitialProjectData(loadedData);
    }),
    [projectId]
  );

  const save = async () => {
    let updatedData;
    if (useAsTemplate) {
      updatedData = projectData;
    } else {
      updatedData = Object.keys(projectData).reduce(
        (agg, k) => ({ ...agg, ...(isEqual(projectData[k], initialProjectData[k]) ? {} : { [k]: projectData[k] }) }),
        {}
      );
    }

    let id = projectId;
    if (Object.keys(updatedData).length > 0) {
      const { advertFile, ...requestData } = updatedData;
      if (advertFile !== undefined && initialProjectData.advertFile) {
        requestData.advertFile = null;
      }
      if (advertFile && advertFile instanceof File) {
        requestData.advertFile = advertFile.name;
      }

      if (projectId && !useAsTemplate) {
        await updateProject(projectId, requestData);
      } else {
        id = (await createProject(requestData)).id;
      }

      if (advertFile !== undefined && initialProjectData.advertFile) {
        try {
          await deleteProjectFile(id, initialProjectData.advertFile);
        } catch (err) {
          console.log(err);
        }
      }
      if (advertFile && advertFile instanceof File) {
        saveProjectFile(id, advertFile.name, advertFile);
      }
    }
    onSave(id);
  };

  const downloadAdvert = wrapAsyncSaving(async () => {
    if (!projectData.advertFile || projectData.advertFile instanceof File) {
      return projectData.advertFile;
    }
    const fileInfo = await getProjectFileInfo(projectId, projectData.advertFile);
    return fileInfo.contentUrl;
  }, true);

  const readOnly = !useAsTemplate && ['Storniert', 'Erfolgreich abgeschlossen'].includes(projectData.status);

  return (
    <Container fixed>
      <ResponsivePaper className="my-3">
        {loading ? (
          <div className="d-flex align-items-center">
            <CircularProgress /> <div className="ml-3">Loading...</div>
          </div>
        ) : (
          <ProjectDetailsWizard
            readOnly={readOnly}
            projectData={projectData}
            setProjectData={setProjectData}
            isExistingProject={projectId && !useAsTemplate}
            onDownloadAdvertFile={downloadAdvert}
            onSave={save}
          />
        )}
      </ResponsivePaper>
    </Container>
  );
};

ProjectDetails.propTypes = {
  projectId: PropTypes.string,
  useAsTemplate: PropTypes.bool,
  onSave: PropTypes.func.isRequired
};

ProjectDetails.defaultProps = {
  projectId: null,
  useAsTemplate: false
};

export default ProjectDetails;
