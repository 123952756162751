import $ from 'jquery';
import React from 'react';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import TEXT from '../text';
import { clearStoredToken, readToken } from './tokenStorage';

export function getHighestUserRole(roles) {
  if (roles.includes('admin')) return 'admin';
  if (roles.includes('employee')) return 'employee';
  return 'client';
}
/**
 * remove token from local storage and redirect to login pages
 * token will not removed from mongoDB user collection to keep other devices
 * which have a valid token stored logged in
 */
export function logoutLocalUser() {
  console.log('error handling - logout user');
  clearStoredToken();
  window.location = '/';
}

export function getTitle() {
  let title;
  switch (CONSTANTS.partner) {
    case 'matchflix':
      title = 'Match-Flix';
      break;
    case 'personaltotal':
      title = 'Personal Total';
      break;
    case 'senator':
      title = 'Senator';
      break;
    case 'lienert':
      title = 'Jörg Lienert AG';
      break;
    default:
  }
  return title;
}

export function dataURIToBlob(dataURI) {
  const binStr = atob(dataURI.split(',')[1]);

  const len = binStr.length;

  const arr = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr]);
}

/**
 * Checks if the testString matches the regex pattern
 * @param  {string}  testString string to test
 * @return {Boolean}            true if string matches the pattern
 */
export function isSearchtermValid(testString) {
  const reg = RegExp(/^"[A-Za-z0-9\s&\-öäüÖÄÜß\*#\+@À-ÿ.?:%=/'´`’!]{2,}"$|^((?!\+)(?!\s))[A-Za-z0-9\s&\-öäüÖÄÜß\*#\+@À-ÿ.?:%=/'´`’!]{2,}$/);
  return reg.test(testString);
}

/**
 * global error handling for all API calls
 * @param  {objects} err               error object response from API call
 * @param  {function} specificHandleError401    optional: specific handling of http error code 401
 * @param  {array} ignoredErrorCodes optional: error codes which should be ignored
 */
// eslint-disable-next-line
export function errorHandling(err, url, specificHandleError401, ignoredErrorCodes) {
  const handleError401 = specificHandleError401 ? specificHandleError401.bind(this, 'loggedIn', false) : logoutLocalUser;

  console.log(`API Error handling - error occurred while API call with url ${url || err.url} throws error: `, err);
  if (ignoredErrorCodes) {
    console.log('Error handling - following error codes are ignored by this util function: ', ignoredErrorCodes);
  }

  // skip error handling for ignored http error codes (param:ignoreErrorCodes)
  // network connection error is always handled
  // const errorType = err.readyState === 0 ? 'network/timeoutError' : 'httpError';
  if (
    !ignoredErrorCodes ||
    !ignoredErrorCodes.includes(err.status)
    // errorType === 'networkError'
  ) {
    if (err.status === 401) {
      console.log('Error handling - 401 authentication failed');
      handleError401();
    } else {
      /* handle network connection error and other http errors
       * except http error 401 and are not ignoredErrorCodes
       * */

      // log error
      // const errorObj = {};
      // errorObj.errorType = errorType;
      // errorObj.errorMessage = TEXT[errorType];
      // console.log(`ERROR of type: "${errorType}":`, errorObj);

      window.location = '/err';
    }
  }
}

export function logout() {
  console.log('API /logout request');
  const tokenFromLocalStorage = readToken();
  const url = CONSTANTS.logoutURL;
  $.ajax({
    url,
    method: 'POST',
    headers: { 'x-auth': tokenFromLocalStorage },
    timeout: CONFIG.ajaxTimeout
  })
    .done((responseBody, status, xhr) => {
      console.log('API logout response status code', xhr.status);

      if (xhr.status === 200) {
        logoutLocalUser();
      }
    })
    .fail(err => {
      errorHandling(err, url);
    });
}

export function convertMillisecondsToMonths(rawValue) {
  const roundedValue = Math.round(rawValue / (31556952000 / 12));
  return roundedValue;
}

export function convertBufferToImage(imageBuffer) {
  return `data:image/jpeg;base64,${Buffer.from(imageBuffer, 'binary').toString('base64')}`;
}

export function convertBufferToImageV2(imageBuffer, fileFormat) {
  return `data:image/${fileFormat};base64,${imageBuffer}`;
}

export function convertBufferToDocx(binaryBuffer, fileFormat) {
  return `data:image/${fileFormat};base64,${binaryBuffer}`;
}

export function convertBufferToPdf(pdfBuffer) {
  return `data:application/pdf;base64,${pdfBuffer}`;
}

export function formatDateToDELocale(date) {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('de-DE', options);
}

export function parseDate(dateString) {
  let date = new Date('0000-00-00');
  try {
    date = new Date(dateString);
  } catch (err) {
    console.log('Error during parseDate', err);
    // TODO exception handling
  }

  return date;
}

/**
 * Maps a received Score to the number of stars
 * @param  {number} score an elastic search score value
 * @return {JSX}       number of stars depending on the score
 */
export function calcMatchStars(score) {
  // TODO these block requires extensive rework ("bell"-curve not implemented)
  let tmpScore = score;
  const starArray = [];
  // TODO each score value > 5 gets 5 stars, it is wrong
  for (let i = 0; i < 5; i++) {
    if (tmpScore <= 0) starArray.push('star_border');
    else if (tmpScore > 0 && tmpScore < 0.9) starArray.push('star_half');
    else starArray.push('star');
    tmpScore--;
  }
  return starArray.map((element, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <i key={`star${index}`} className="material-icons cl-dred">
      {element}
    </i>
  ));
}

export function formatDuration(duration) {
  if (!duration) {
    return '';
  }
  const years = Math.floor(duration / (1000 * 60 * 60 * 24 * 30 * 12));
  const months = Math.floor(duration / (1000 * 60 * 60 * 24 * 30)) % 12;
  // TODO improve
  return `${years ? `${years} ${TEXT.resultPage.lastPositions.years}` : ''}${months && years ? ' ' : ''}${
    months ? `${months} ${TEXT.resultPage.lastPositions.months}` : ''
  }`;
}

export function downloadBlobFile(fileOrUrl) {
  const a = document.createElement('a');
  a.style = 'display: none';
  document.body.appendChild(a);
  const url = fileOrUrl instanceof File ? window.URL.createObjectURL(fileOrUrl) : fileOrUrl;
  a.href = url;
  if (fileOrUrl instanceof File) {
    a.download = fileOrUrl.name;
  }
  a.click();
  if (fileOrUrl instanceof File) {
    window.URL.revokeObjectURL(url);
  }
}

export function removeURLParam(history, key) {
  if (!history || !history.location || history.location.search === undefined) return false;
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.delete(key);
  history.replace({
    pathname: history.location.pathname,
    search: searchParams.toString()
  });
  return true;
}

export function setURLParam(history, key, value) {
  if (!history || !history.location || history.location.search === undefined) return false;

  const searchParams = new URLSearchParams(history.location.search);
  searchParams.set(key, value);
  history.replace({
    pathname: history.location.pathname,
    search: searchParams.toString()
  });
  return true;
}

export function getURLParam(history, key) {
  if (!history || !history.location || !history.location.search) return undefined;

  const searchParams = new URLSearchParams(history.location.search);
  return searchParams.get(key);
}

export function formatDateToDELocaleTime(date) {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  return date.toLocaleDateString('de-DE', options);
}

export function mapBookingOptionName(bookingOption) {
  switch (bookingOption) {
    case 'searchSupport':
      return 'Search Service';
    case 'activeSearch':
      return 'Active Sourcing';
    case 'advert':
      return 'Anzeigenbundle';
    default:
      return bookingOption;
  }
}
