import React, { useState, useEffect } from 'react';
import { Typography, Hidden, Box, Button, Grid, Divider, CircularProgress } from '@material-ui/core';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import * as UTILS from '../utils/utilFunctions';
import CompanyInformation from '../components/profile/CompanyInformation';
import CompanyDetails from '../components/profile/CompanyDetails';
import ResponsivePaper from '../generic_components/ResponsivePaper';

import { readCompanyProfile, updateCompanyProfile } from '../api/company';
import useLoading from '../utils/useLoading';
import CompanyLogo from '../components/profile/CompanyLogo';
import DialogWrapper from '../generic_components/DialogWrapper';

export default function Profile() {
  const [companyInformation, setCompanyInformation] = useState();
  const [loading, wrapAsyncLoading] = useLoading(true);
  const [isFirstTimeDialogOpen, setIsFirstTimeDialogOpen] = useState(false);
  const [isFirstTimeViewed, setIsFirstTimeViewed] = useState(false);

  useEffect(
    wrapAsyncLoading(async () => {
      const data = await readCompanyProfile();
      if (!data.description) {
        setIsFirstTimeViewed(true);
      }
      setCompanyInformation(data);
    }),
    []
  );

  useEffect(() => {
    document.title = `Unternehmensprofil - ${UTILS.getTitle()}`;
  }, []);

  const updateProfile = wrapAsyncLoading(async profileData => {
    await updateCompanyProfile(profileData);
    if (isFirstTimeViewed) {
      setIsFirstTimeDialogOpen(true);
      setIsFirstTimeViewed(false);
    }
  });

  const flatCompanyDetailsData = () => ({
    ...companyInformation.address,
    advisor: companyInformation.advisor
      ? `${companyInformation.advisor.firstName} ${companyInformation.advisor.lastName}`
      : 'Kein Betreuer Vorhanden',
    ...companyInformation.contactInfo
  });

  const renderCompanyDetails = () => (
    <ResponsivePaper>
      <Box pb={3}>
        <Typography variant="h5" align="center">
          {companyInformation.name}
        </Typography>
        <CompanyLogo />
      </Box>

      <Divider />
      <CompanyDetails fieldValues={flatCompanyDetailsData()} />
    </ResponsivePaper>
  );

  const renderCompanyProfile = () => (
    <ResponsivePaper>
      <Hidden xsDown>
        <Typography variant="h5">Unternehmensprofil</Typography>
      </Hidden>
      <br />
      <p>
        Willkommen auf MatchFlix!
        <br /> Bevor Sie mit der Suche starten, bitten wir Sie Ihr Unternehmensprofil einzugeben und Ihr Logo hochzuladen. Ein ansprechendes
        Unternehmensprofil hilft uns dabei, unsere Kandidaten von Ihnen zu überzeugen.
      </p>
      <CompanyInformation
        loading={loading}
        update={updateProfile}
        profileData={companyInformation}
        setProfileData={setCompanyInformation}
      />
      <div className="text-right mt-3">
        <Button component={Link} to="/myprojects" color="primary" variant="outlined">
          weiter zu meinen Aufträgen
        </Button>
      </div>
    </ResponsivePaper>
  );

  const renderFirstUpdatePopUp = () => (
    <DialogWrapper
      open={isFirstTimeDialogOpen}
      handleClose={() => setIsFirstTimeDialogOpen(false)}
      dialogTitle="Unternehmensprofil erfolgreich erstellt"
      cancelButtonName="Schließen"
      icon={<CheckCircleOutlineIcon style={{ fontSize: '85px' }} />}
    >
      <Box pb={2}>
        <Typography component="p">
          Geschafft! Sie haben Ihr Unternehmensprofil erfolgreich erstellt. Sie können jetzt einen Auftrag anlegen und mit der Suche
          beginnen.
        </Typography>
      </Box>

      <Typography component="p">
        Sollten Sie etwas ändern wollen können Sie Ihr Unternehmensprofil jederzeit über das Menü bearbeiten.
      </Typography>
    </DialogWrapper>
  );

  return (
    <>
      <Grid container spacing={2} className="my-3" style={{ paddingLeft: '1em', paddingRight: '1em' }}>
        {loading ? (
          <Grid item xs={12}>
            <Typography align="center">
              <CircularProgress color="primary" size={65} />
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={4}>
              {companyInformation && renderCompanyDetails()}
            </Grid>

            <Grid item xs={12} md={8}>
              {companyInformation && renderCompanyProfile()}
            </Grid>
          </>
        )}
      </Grid>
      {renderFirstUpdatePopUp()}
    </>
  );
}
