import * as React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Fab, CircularProgress, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import useLoading from '../../utils/useLoading';
import DrawerWrapper from '../../generic_components/DrawerWrapper';
import ProjectDetails from './ProjectDetails';
import { getProjectList, updateProject } from '../../api/projects';
import ProjectActions from './ProjectActions';
import BookingOptionModal from '../bookingOptions/BookingOptionModal';
import ProjectSummaryPanel from './ProjectSummaryPanel';
import { getURLParam, mapBookingOptionName } from '../../utils/utilFunctions';
import InfoIcon from '../../generic_components/InfoIcon';
import STATICINFOTEXT from '../../staticInfoText';
import ConfirmationModal from '../../generic_components/ConfirmationModal';

const ProjectList = ({ history, onSelectProject }) => {
  const [loading, wrapAsyncLoading] = useLoading();
  const [projectList, setProjectList] = React.useState();
  const [selectedProjectId, setSelectedProjectId] = React.useState();
  const [expandedProjectId, setExpandedProjectId] = React.useState();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [useAsTemplate, setUseAsTemplate] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // for booking option dialogs
  const [isBookingOptionModalOpen, setIsBookingOptionModalOpen] = React.useState(false);
  const [isUpdateBookingConfirmationOpen, setIsUpdateBookingConfirmationOpen] = React.useState(false);
  // the project to edit
  const [openedBookingOptionModalProject, setOpenedBookingOptionModalProject] = React.useState(null);
  // array of options to set
  const [newlySelectedBookingOptions, setNewlySelectedBookingOptions] = React.useState(null);

  const loadProjectList = wrapAsyncLoading(async () => {
    setProjectList(await getProjectList());
  });

  React.useEffect(loadProjectList, []);
  React.useEffect(() => expand(getURLParam(history, 'pid')), []);

  function expand(projectId) {
    setExpandedProjectId(expandedProjectId === projectId ? undefined : projectId);
  }
  function select(projectId, template) {
    setSelectedProjectId(projectId);
    setUseAsTemplate(!!template);
    setDrawerOpen(true);
  }
  const updateStatus = wrapAsyncLoading(async (projectId, newStatus) => {
    try {
      await updateProject(projectId, { status: newStatus });
      setProjectList(await getProjectList());
    } catch (err) {
      if (err.status === 409) {
        enqueueSnackbar(
          err.message === 'no_candidates'
            ? 'Es wurden keine Kandidaten zur Interviewliste hinzugefügt.'
            : 'Bitte fügen Sie mindestens 10 Kandidaten zu Ihrer Interviewliste hinzu, bevor Sie weitere Kandidaten freigeben.'
        );
      } else {
        throw err;
      }
    }
  });
  const updateBookingOptions = wrapAsyncLoading(async projectId => {
    try {
      await updateProject(projectId, { bookingOptions: newlySelectedBookingOptions });

      setProjectList(await getProjectList());
    } catch (err) {
      if (err.status === 409) {
        enqueueSnackbar('Bitte fügen Sie mindestens 10 Kandidaten zu Ihrer Interviewliste hinzu, bevor Sie weitere Kandidaten freigeben.');
      } else {
        throw err;
      }
    }
  });
  function deselect() {
    setSelectedProjectId(undefined);
    setDrawerOpen(false);
  }
  function projectSaved(savedProjectId) {
    setExpandedProjectId(savedProjectId);
    deselect();
    loadProjectList();
  }
  function redirectToSearch(project) {
    onSelectProject(project);
    // TODO pass project title / job function to search
    history.push('/search');
  }

  const groupedProjectList = groupByStatus(projectList || []);

  return (
    <div className="mt-3" style={{ minHeight: 200 }}>
      <ConfirmationModal
        open={isUpdateBookingConfirmationOpen}
        handleClose={() => {
          setIsUpdateBookingConfirmationOpen(false);
          setOpenedBookingOptionModalProject(null);
          setIsBookingOptionModalOpen(false);
        }}
        buttonPrimaryAction={() => {
          updateBookingOptions(openedBookingOptionModalProject.id);
          setIsUpdateBookingConfirmationOpen(false);
          setOpenedBookingOptionModalProject(null);
          setIsBookingOptionModalOpen(false);
        }}
        headlineText="Nur noch ein Klick!"
        descriptionText={`Hilfe naht! Sie haben "${Object.keys(newlySelectedBookingOptions || [])
          .filter(selectedOption => {
            if (
              (newlySelectedBookingOptions || [])[selectedOption] === true &&
              (!openedBookingOptionModalProject ||
                !openedBookingOptionModalProject.bookingOptions ||
                openedBookingOptionModalProject.bookingOptions[selectedOption] !== true)
            ) {
              return true;
            }
            return false;
          })
          .map(mapBookingOptionName)
          .join(', ')}" ausgewählt`}
        icon={<img src="/iva.gif" alt="iva" width="128" />}
      >
        <p>Jetzt verbindlich buchen?</p>
        <p>Dies kann nicht rückgängig gemacht werden.</p>
      </ConfirmationModal>
      <BookingOptionModal
        disallowUnchecking
        initialBookingOptions={openedBookingOptionModalProject ? openedBookingOptionModalProject.bookingOptions : []}
        open={isBookingOptionModalOpen}
        handleClose={() => {
          setOpenedBookingOptionModalProject(null);
          setIsBookingOptionModalOpen(false);
        }}
        buttonPrimaryAction={updatedBookingOptions => {
          const initialOptions = (openedBookingOptionModalProject && openedBookingOptionModalProject.bookingOptions) || {};

          let anyNewOption = false;
          Object.keys(updatedBookingOptions).forEach(option => {
            if (updatedBookingOptions[option] === true && initialOptions[option] !== true) {
              anyNewOption = true;
            }
          });

          if (anyNewOption) {
            setNewlySelectedBookingOptions(updatedBookingOptions);
            setIsUpdateBookingConfirmationOpen(true);
            setIsBookingOptionModalOpen(false);
          } else {
            setNewlySelectedBookingOptions(null);
            setOpenedBookingOptionModalProject(null);
            setIsBookingOptionModalOpen(false);
          }
        }}
      />

      {!projectList ? (
        <div className="d-flex align-items-center">
          <CircularProgress /> <div className="ml-3">Loading...</div>
        </div>
      ) : (
        <div>
          {projectList.length ? (
            Object.keys(groupedProjectList)
              .sort()
              .reverse()
              .map(status => (
                <React.Fragment key={status}>
                  <Typography variant="body1" component="h6" className="mt-3 mb-2">
                    {status} <InfoIcon tooltip={STATICINFOTEXT.projectList[status]} />
                  </Typography>
                  {groupedProjectList[status].map(project => (
                    <ProjectSummaryPanel
                      project={project}
                      expanded={expandedProjectId === project.id}
                      onExpand={() => expand(project.id)}
                      key={project.id}
                      bookingOptionsDisabled={status === 'Abgeschlossen'}
                      onClickBookingOptions={e => {
                        e.preventDefault();
                        setOpenedBookingOptionModalProject(project);
                        setIsBookingOptionModalOpen(true);
                      }}
                    >
                      <ProjectActions
                        loading={loading}
                        status={status}
                        onBookingOptions={() => {
                          setOpenedBookingOptionModalProject(project);
                          setIsBookingOptionModalOpen(true);
                        }}
                        onSearch={() => redirectToSearch(project)}
                        onEdit={() => select(project.id, false)}
                        onUseTemplate={() => select(project.id, true)}
                        onUpdateStatus={newStatus => updateStatus(project.id, newStatus)}
                      />
                    </ProjectSummaryPanel>
                  ))}
                </React.Fragment>
              ))
          ) : (
            <div>Noch keine Stellengesuche eingestellt.</div>
          )}
        </div>
      )}
      <Fab
        color="primary"
        aria-label="Add"
        title="Neues Stellengesuch anlegen"
        style={{ position: 'absolute', bottom: '30px', right: '30px' }}
        onClick={() => select(null)}
      >
        <AddIcon />
      </Fab>
      <DrawerWrapper
        title={selectedProjectId && !useAsTemplate ? 'Stellengesuch bearbeiten' : 'Neues Stellengesuch anlegen'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <ProjectDetails projectId={selectedProjectId} useAsTemplate={useAsTemplate} onSave={projectSaved} onClose={deselect} />
      </DrawerWrapper>
    </div>
  );
};

function groupByStatus(projectList) {
  return projectList.reduce(
    (agg, project) => ({ ...agg, [mapStatus(project.status)]: [...(agg[mapStatus(project.status)] || []), project] }),
    {}
  );
}
function mapStatus(status) {
  return ['Erfolgreich abgeschlossen', 'Storniert'].includes(status) ? 'Abgeschlossen' : status || 'Noch nicht freigegeben';
}

ProjectList.propTypes = {
  history: PropTypes.object.isRequired,
  onSelectProject: PropTypes.func.isRequired
};

export default withRouter(ProjectList);
