import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DialogWrapper from '../../generic_components/DialogWrapper';
import { getProjectById, updateProject } from '../../api/projects';
import { setURLParam } from '../../utils/utilFunctions';

function CandidateCountNotificationWrapper({ history, handleClose, handleSave, open, selectedProject, updateSelectedProject }) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <DialogWrapper
        open={open}
        dialogTitle={`Sie haben nun 10 Kandidaten in Ihren aktuellen Auftrag (${selectedProject.title}) hinzugefügt`}
        handleClose={handleClose}
        handleSave={async () => {
          try {
            let project = await getProjectById(selectedProject.id);
            if (project.status !== 'In Bearbeitung') {
              await updateProject(selectedProject.id, { status: 'In Bearbeitung' });
              project = await getProjectById(selectedProject.id);
            }
            updateSelectedProject(project);
          } catch (err) {
            if (err.status === 409) {
              enqueueSnackbar('http status 409 error');
            } else {
              throw err;
            }
          }
          handleSave();
          history.push('/myprojects');
          setURLParam(history, 'pid', selectedProject.id);
        }}
        acceptButtonName={selectedProject.status === 'In Bearbeitung' ? 'Zum Auftrag' : 'Kandidaten Freigeben'} // TODO CHECK
        cancelButtonName="Weitere Kandidaten hinzufügen"
        icon="check"
      >
        <div>
          Mit Klick auf „Kandidaten freigeben“ senden Sie Interviewanfragen an die bereits ausgewählten Kandidaten. Bitte beachten Sie, dass
          Sie die Kandidaten nach Freigabe nicht mehr aus Ihrer Interviewliste löschen können.
        </div>
      </DialogWrapper>
    </>
  );
}

CandidateCountNotificationWrapper.propTypes = {
  history: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  selectedProject: PropTypes.object.isRequired,
  updateSelectedProject: PropTypes.func.isRequired
};

export default withRouter(CandidateCountNotificationWrapper);
