import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MatchPercentageFilter from './mainFilters/MatchPercentageFilter';
import AgeFilter from './mainFilters/AgeFilter';
import WillingnessToChangeFilter from './mainFilters/WillingnessToChangeFilter';
import ShowMarked from './mainFilters/ShowMarked';
import PostalCodeFilter from './mainFilters/PostalCodeFilter';
import withUser from '../../utils/withUser';

import './FilterStyles.css';

class MainFilterComponent extends PureComponent {
  render() {
    return (
      <div className="container-fluid filterContainer mainFilter text-center">
        <div className="row">
          <MatchPercentageFilter
            onFilterChange={this.props.onFilterChange}
            matchPercentage={this.props.matchPercentage.parameters}
            wasLoaded={this.props.matchPercentage.wasLoaded}
          />
        </div>

        <div className="row paddingContainer">
          <AgeFilter onFilterChange={this.props.onFilterChange} range={this.props.age.parameters} wasLoaded={this.props.age.wasLoaded} />
        </div>
        <div className="row paddingContainer">
          <WillingnessToChangeFilter
            onFilterChange={this.props.onFilterChange}
            range={this.props.willingnessToChange.parameters}
            wasLoaded={this.props.willingnessToChange.wasLoaded}
          />
        </div>
        {!this.props.disableDistanceFiltering && (
          <div className="row paddingContainer">
            <PostalCodeFilter
              onFilterChange={this.props.onFilterChange}
              range={this.props.distance[1] === 0 ? [0, 250] : this.props.distance}
              maxRange={this.props.maxRange}
            />
          </div>
        )}
        <div className="row">
          <ShowMarked
            onFilterChange={this.props.onFilterChange}
            showMarked={this.props.showMarked}
            markedCandidateIDs={this.props.markedCandidateIDs}
          />
        </div>
      </div>
    );
  }
}
MainFilterComponent.defaultProps = {
  maxRange: 250
};
MainFilterComponent.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  matchPercentage: PropTypes.object.isRequired,
  age: PropTypes.object.isRequired,
  willingnessToChange: PropTypes.object.isRequired,
  distance: PropTypes.arrayOf(PropTypes.number).isRequired,
  disableDistanceFiltering: PropTypes.bool.isRequired,
  showMarked: PropTypes.bool.isRequired,
  maxRange: PropTypes.number,
  markedCandidateIDs: PropTypes.arrayOf(PropTypes.string).isRequired
};
export default withUser(MainFilterComponent);
