import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { IconButton, MenuItem, Menu } from '@material-ui/core';

class MobileProjectSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  exitMenuOnClick(handler) {
    handler();
    this.setState({ anchorEl: null });
  }

  render() {
    return (
      <>
        <IconButton
          aria-owns={this.state.anchorEl ? 'current-project-selection' : null}
          aria-haspopup="true"
          onClick={event => {
            this.setState({
              anchorEl: event.currentTarget
            });
          }}
          color="inherit"
        >
          <i className="material-icons">domain</i>
        </IconButton>
        <Menu
          id="current-project-selection"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.setState({ anchorEl: null });
          }}
        >
          {this.props.isInPageContext(['/search', '/results']) && (
            <MenuItem
              onClick={() => {
                this.exitMenuOnClick(this.props.onClick);
              }}
            >
              <i className="material-icons">arrow_forward_ios</i>
              &nbsp; {this.props.selectedProject || 'Kein Auftrag gewählt'}
            </MenuItem>
          )}
        </Menu>
      </>
    );
  }
}

MobileProjectSelector.propTypes = {
  isInPageContext: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default MobileProjectSelector;
