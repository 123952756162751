import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './SingleSearchResult.css';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withUser from '../utils/withUser';
import TEXT from '../text';
import * as UTILS from '../utils/utilFunctions';
import * as constants from '../constants';
import WillingnessToChangeCircle from './WillingnessToChangeCircle';

import AddToProjectButton from './search_result_components/AddToProjectButton';
import CandidateStatusPanel from './search_result_components/CandidateStatusPanel';

/**
 * The single frame for displaying the candidate information
 * @author Victor
 * @extends Component
 */
class SingleSearchResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }

  /**
   * Return a JSX with the "current" and "previous" job
   * @return {Array} an array of JSX elemtent for the
   *                 current and the previous job description
   */
  getJobs(getAllJobs) {
    let jobsArray = this.props.jobs;
    if (!this.state.isExpanded && !getAllJobs) {
      jobsArray = jobsArray.slice(0, 2);
    }
    return jobsArray.map((job, cnt) => (
      <div key={job.ID} className="current-job mb-4">
        <div className="mb-2 font-size-title">
          <div className="font-weight-bold cl-blue capitalize-words">{job.jobText}</div>

          <div className="font-weight-bold capitalize-words blurry">{TEXT.resultPage.company}</div>
        </div>
        {this.calcJobsDuration(job, cnt)}
        {this.displayCandidateJobBranches(job)}
      </div>
    ));
  }

  displayCandidateJobBranches(job) {
    let result = <div className="mb-2">{TEXT.resultPage.noJobDescription}</div>;
    if (job.description && job.description.length > 0) {
      result = (
        <div className="text-left">
          <span className="capitalize-words">{`${TEXT.resultPage.description} ${job.description}`}</span>
        </div>
      );
    } else if (job.branches.length > 0 && job.branches[0] !== '') {
      result = (
        <div className="text-left">
          {TEXT.resultPage.branches}
          <span className="capitalize-words">
            {job.branches.map((element, index) => ` ${element}${index === job.branches.length - 1 ? '.' : ', '}`)}
          </span>
        </div>
      );
    }
    return result;
  }

  mapSkillLevel(skillLevel) {
    let skillLevelText = '';
    switch (Number.parseInt(skillLevel, 10)) {
      case 0:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel0;
        break;
      case 1:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel1;
        break;
      case 2:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel2;
        break;
      case 3:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel3;
        break;
      case 4:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel4;
        break;
      case 5:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel5;
        break;
      case 7:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel6;
        break;
      default:
        break;
    }
    return skillLevelText;
  }

  getSkills() {
    return (
      <div>
        {this.props.languageSkills && this.props.languageSkills.length > 0 ? (
          <div>
            <h6 className="cl-blue m-md-0 font-weight-bold">{TEXT.resultPage.languageSkills}</h6>
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{TEXT.resultPage.skillDetails.languageTableHeading.leftColumn}</TableCell>
                  <TableCell>{TEXT.resultPage.skillDetails.languageTableHeading.rightColumn}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.languageSkills.map(row => (
                  <TableRow key={row.language + row.skillLevel}>
                    <TableCell component="th" scope="row">
                      {row.language}
                    </TableCell>
                    <TableCell>{this.mapSkillLevel(row.skillLevel)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>{' '}
          </div>
        ) : (
          ''
        )}
        {this.props.additionalSkills && this.props.additionalSkills.length > 0 ? (
          <div>
            <h6 className="cl-blue m-md-0 font-weight-bold pt-3">{TEXT.resultPage.additionalSkills}</h6>
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{TEXT.resultPage.skillDetails.otherSkillsTableHeading.leftColumn}</TableCell>
                  <TableCell>{TEXT.resultPage.skillDetails.otherSkillsTableHeading.rightColumn}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.additionalSkills.map(row => (
                  <TableRow key={row.skillName + row.skillLevel}>
                    <TableCell component="th" scope="row">
                      {row.skillName}
                    </TableCell>
                    <TableCell>{row.skillLevel}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  disableSelection(e) {
    e.preventDefault();
  }

  /**
   * [calcJobsDuration description]
   * @param  {Object} job  see job object in the prop validation for details
   * @param  {number} index denotes the position of the job Object in the jobs Array
   *                        if index == 0 the job is the current job, if index == 1
   *                        the job is the old job
   * @return {JSX}    the job duration for the current or the old job
   */
  calcJobsDuration(job, index) {
    let result = <div className="mb-2">{TEXT.resultPage.noJobDuration}</div>;
    if (job.duration > 0) {
      result = (
        <div className="mb-2">
          {index === 0 ? TEXT.resultPage.lastPositions.currentPosition : TEXT.resultPage.lastPositions.formerPosition}
          {UTILS.formatDuration(job.duration)})
        </div>
      );
    }
    return result;
  }

  handleExpandButton = e => {
    e.stopPropagation();
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  handleMoreInfoButton = () => {
    const liftUpObj = {
      picture: constants.candidatePlaceholderPictureURL,
      isAvailable: this.props.isAvailable,
      score: this.props.score,
      willingnessToChange: this.props.willingnessToChange,
      lastChange: this.props.lastChange,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      jobs: this.getJobs(true),
      skills: this.getSkills(),
      anonymized: this.props.anonymized,
      ID: this.props.ID,
      candidateIDs: this.props.origin
    };
    // liftUpObj.candidateNumbers = candidateNumbersDom;
    this.props.liftUpStateToApp('sidePanelContent', liftUpObj);
  };

  renderWillingnessToChange = () => (
    <div>
      {this.props.willingnessToChange && (
        <WillingnessToChangeCircle willingnessToChange={this.props.willingnessToChange} textAboveCircle={false} />
      )}
    </div>
  );

  getCandidatePicture() {
    return <img src={constants.candidatePlaceholderPictureURL} alt="" width="150px" className="img-fluid rounded" />;
  }

  getResidence() {
    if (this.props.currentResidence === null && this.props.currentCountry === null) return '';
    if (this.props.currentResidence === null && this.props.currentCountry !== null) return this.props.currentCountry;
    if (this.props.currentResidence !== null && this.props.currentCountry === null) return this.props.currentResidence;
    return `${this.props.currentResidence}, ${this.props.currentCountry}`;
  }

  getCandidateInformation() {
    return (
      <div>
        <p className="text-left">{this.getResidence()}</p>

        <p className="text-left">
          {TEXT.resultPage.age} {this.props.age}
        </p>
      </div>
    );
  }

  render() {
    const showExpandFunctionality = this.props.jobs.length > 2;
    const expandCollapseIcon = this.state.isExpanded ? 'expand_less' : 'expand_more';

    return (
      <Paper
        className={`mainBoxWrapper m-2 p-2 ${this.props.isAddedToOtherProject ? 'highlighted' : ''} ${
          this.props.projectStatus ? 'marked' : ''
        }`}
        onClick={this.props.liftUpStateToApp && this.handleMoreInfoButton}
      >
        <div className="row">
          <div className="col-md-2 col-xs-12">
            <div className="sidebarInfo">
              <div className="portrait-wrapper text-center mb-1">{this.getCandidatePicture()}</div>
              <p className="text-center">
                {this.props.isAvailable ? (
                  <span className="cl-green d-flex flex-row justify-content-center">
                    <i className="material-icons font-size-title">fiber_manual_record</i>
                    <span>{TEXT.resultPage.available}</span>
                  </span>
                ) : (
                  <span className="cl-red d-flex flex-row justify-content-center">
                    <i className="material-icons font-size-title">fiber_manual_record</i>
                    <span>{TEXT.resultPage.notAvailable}</span>
                  </span>
                )}
                {!this.props.projectStatus && this.props.isAddedToOtherProject && <span>Für anderen Auftrag gewählt</span>}
              </p>
              {this.getCandidateInformation()}
            </div>
          </div>
          <div className="col-md-7 col-xs-12">
            <div className="headerInfo mb-3 d-md-flex justify-content-md-between align-items-md-center">
              <h4 className="candidate-name cl-blue m-md-0 blurry">{`${this.props.firstName} ${this.props.lastName}`}</h4>
            </div>

            <div className="jobQualification">{this.getJobs()}</div>
            {this.state.isExpanded ? this.getSkills() : ''}
          </div>

          <div className="col-md-3 text-center">
            {this.props.showRatings && (
              <>
                <div className="row">
                  <div className="col-12 mt-3">
                    {UTILS.calcMatchStars(this.props.score)}
                    <br />
                    {TEXT.resultPage.matchPercentage}
                  </div>
                </div>
                <div />
                <div className="row">
                  <div className="col-12  mt-3 ">{this.renderWillingnessToChange()}</div>
                </div>
              </>
            )}
            {this.props.selectedProjectHandler && (!this.props.projectStatus || this.props.projectStatus === 'added') && (
              <AddToProjectButton
                selectedProjectHandler={this.props.selectedProjectHandler}
                candidateId={this.props.ID}
                score={this.props.score}
                willingnessToChange={this.props.willingnessToChange}
                isAdded={!!this.props.projectStatus}
              />
            )}
            {this.props.projectStatus && this.props.projectStatus !== 'added' && <CandidateStatusPanel status={this.props.projectStatus} />}
            {this.props.liftUpStateToAllSearchResults && (
              <Button
                className="button-more-info"
                variant="contained"
                color="secondary"
                onClick={() => {
                  this.props.liftUpStateToAllSearchResults('selectedUserMarkItem', {
                    candidate: this.props.ID,
                    matchPercentage: this.props.score,
                    willingnessToChange: this.props.willingnessToChange
                  });
                  this.props.liftUpStateToAllSearchResults('markCandidatePopUpOpen', true);
                }}
                fullWidth
              >
                {TEXT.markListPopup.addItemToMarkListButton}
              </Button>
            )}
          </div>
        </div>
        {showExpandFunctionality && (
          <div className="row expanderBar">
            <div className="col-12" align="center">
              <Button onClick={this.handleExpandButton} fullWidth>
                <i className="material-icons">{expandCollapseIcon}</i>
              </Button>
            </div>
          </div>
        )}
      </Paper>
    );
  }
}

SingleSearchResult.defaultProps = {
  firstName: TEXT.resultPage.firstName,
  lastName: TEXT.resultPage.lastName,

  currentResidence: '',
  currentCountry: TEXT.commonTerms.NA,
  jobs: [],
  age: 0,
  lastChange: TEXT.commonTerms.NA,
  additionalSkills: undefined,
  languageSkills: undefined,
  origin: undefined,
  willingnessToChange: undefined,
  liftUpStateToAllSearchResults: undefined,
  liftUpStateToApp: undefined,
  showRatings: true,
  projectStatus: undefined
};

SingleSearchResult.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  liftUpStateToAllSearchResults: PropTypes.func,
  isAvailable: PropTypes.bool.isRequired,
  currentResidence: PropTypes.string,
  currentCountry: PropTypes.string,
  age: PropTypes.number,
  ID: PropTypes.string.isRequired,
  lastChange: PropTypes.string,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      jobText: PropTypes.string,
      company: PropTypes.string,
      duration: PropTypes.number,
      branches: PropTypes.arrayOf(PropTypes.string),
      ID: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ),
  languageSkills: PropTypes.array,
  additionalSkills: PropTypes.array,
  score: PropTypes.number.isRequired,
  projectStatus: PropTypes.string,
  isAddedToOtherProject: PropTypes.bool.isRequired,
  willingnessToChange: PropTypes.number,
  liftUpStateToApp: PropTypes.func,
  anonymized: PropTypes.bool.isRequired,
  origin: PropTypes.array,
  selectedProjectHandler: PropTypes.object.isRequired,
  showRatings: PropTypes.bool
};

export default withUser(SingleSearchResult);
