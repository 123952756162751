import React, { PureComponent } from 'react';
import './Sidebar.css';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
// material UI
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import WillingnessToChangeCircle from './WillingnessToChangeCircle';

import TEXT from '../text';
import * as UTILS from '../utils/utilFunctions';

import withUser from '../utils/withUser';

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  mapSkillLevel(skillLevel) {
    let skillLevelText = '';
    switch (Number.parseInt(skillLevel, 10)) {
      case 0:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel0;
        break;
      case 1:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel1;
        break;
      case 2:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel2;
        break;
      case 3:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel3;
        break;
      case 4:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel4;
        break;
      case 5:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel5;
        break;
      case 7:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel6;
        break;
      default:
        break;
    }
    return skillLevelText;
  }

  createCandidateInfo() {
    return (
      <div className="sideBarWrapper m-2 p-2">
        <div className="col-12">
          <div className="row">
            <div className="col-6 text-center">
              <div className="portrait-wrapper mb-1 mt-3">
                <img
                  src={this.props.content.picture}
                  alt=""
                  width="150px"
                  className="img-fluid rounded"
                />
              </div>
              {/* <p>
                {this.props.content.isAvailable ? (
                  <span className="cl-green d-flex flex-row justify-content-center">
                    <i className="material-icons font-size-title">
                      fiber_manual_record
                    </i>
                    <span>{TEXT.resultPage.available}</span>
                  </span>
                ) : (
                  <span className="cl-red d-flex flex-row justify-content-center">
                    <i className="material-icons font-size-title">
                      fiber_manual_record
                    </i>
                    <span>{TEXT.resultPage.notAvailable}</span>
                  </span>
                )}
              </p> */}
            </div>
            <div className="col-6 text-center">
              <div className="row">
                <div className="col-12 mt-3">
                  {UTILS.calcMatchStars(this.props.content.score)}
                  <br />
                  {TEXT.resultPage.matchPercentage}
                </div>
              </div>
              <div />
              <div className="row">
                <div className="col-12  mt-3 ">
                  <WillingnessToChangeCircle
                    willingnessToChange={this.props.content.willingnessToChange}
                    textAboveCircle={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="headerInfo mb-3 d-md-flex justify-content-md-between align-items-md-center">
            <h4 className="candidate-name cl-blue m-md-0 blurry">
              {`${this.props.content.firstName} ${this.props.content.lastName}`}
            </h4>
          </div>
          <div className="row">
            <div className="col-6">
              {this.props.content.candidateInformation}
            </div>
            <div className="col-6">{this.props.content.contactInformation}</div>
          </div>
        </div>
        <div className="col-12">{this.props.content.skills}</div>
      </div>
    );
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    return (
      <div className="col no-padding-left no-padding-right">
        {this.props.content ? (
          <div>
            <AppBar position="static" color="default">
              <Tabs
                value={this.state.value}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                fullWidth
              >
                <Tab label="Info" />
                <Tab label="Positionen" />
              </Tabs>
            </AppBar>
            <SwipeableViews
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}
            >
              <Typography component="div">
                {this.createCandidateInfo()}
              </Typography>
              <Typography component="div">
                <div className="sideBarWrapper m-2 p-2">
                  <div className="col-12">
                    <div className="jobQualification text-left">
                      {this.props.content.jobs}
                    </div>
                  </div>
                </div>
              </Typography>
            </SwipeableViews>
          </div>
        ) : (
          <div className="sideBarWrapper m-2 p-2">
            <h4 className="select-text">
              {TEXT.resultPage.selectCandidateForSidebar}
            </h4>
          </div>
        )}
      </div>
    );
  }
}
Sidebar.defaultProps = {
  content: undefined
};
Sidebar.propTypes = {
  content: PropTypes.object
};

export default withUser(Sidebar);
