import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MatchPercentageFilter from './mainFilters/MatchPercentageFilter';
import AgeFilter from './mainFilters/AgeFilter';
import WillingnessToChangeFilter from './mainFilters/WillingnessToChangeFilter';
import ShowMarked from './mainFilters/ShowMarked';
import PostalCodeFilter from './mainFilters/PostalCodeFilter';
import withUser from '../../utils/withUser';
import DurationCurrentJobFilter from './jobFilters/DurationCurrentJobFilter';
import WorkExperienceFilter from './jobFilters/WorkExperienceFilter';
import TitleCurrentJobFilter from './jobFilters/TitleCurrentJobFilter';
import IndustrySectorFilter from './jobFilters/IndustrySectorFilter';
import './FilterStyles.css';

class StickyFilterComponent extends PureComponent {
  render() {
    return (
      <div className="container-fluid filterContainer mainFilter">
        {this.props.filters.matchPercentage.isSet ? (
          <div className="row">
            <MatchPercentageFilter
              onFilterChange={this.props.onFilterChange}
              matchPercentage={this.props.filters.matchPercentage.parameters}
            />
          </div>
        ) : (
          ''
        )}
        {this.props.filters.age.isSet ? (
          <div className="row paddingContainer">
            <AgeFilter onFilterChange={this.props.onFilterChange} range={this.props.filters.age.parameters} />
          </div>
        ) : (
          ''
        )}
        {this.props.filters.willingnessToChange.isSet ? (
          <div className="row paddingContainer">
            <WillingnessToChangeFilter
              onFilterChange={this.props.onFilterChange}
              range={this.props.filters.willingnessToChange.parameters}
            />
          </div>
        ) : (
          ''
        )}
        {this.props.filters.distance.isSet ? (
          <div className="row paddingContainer">
            <PostalCodeFilter onFilterChange={this.props.onFilterChange} range={this.props.filters.distance.parameters} />
          </div>
        ) : (
          ''
        )}
        {this.props.filters.showMarked.isSet ? (
          <div className="row">
            <ShowMarked onFilterChange={this.props.onFilterChange} showMarked={this.props.filters.showMarked.parameters} />
          </div>
        ) : (
          ''
        )}
        {
          // start job filters
        }
        {this.props.filters.currentPosition.isSet ? (
          <div className="row text-left">
            <TitleCurrentJobFilter
              onFilterChange={this.props.onFilterChange}
              titleCurrentJob={this.props.filters.currentPosition.parameters}
              stickyFilter
              countedJobParameters={this.props.countedJobParameters}
              /* new props for inputField */
              numberOfShownFilterOptionForCurrentPositionFilter={this.props.numberOfShownFilterOptionForCurrentPositionFilter}
              titleCurrentJobFilteredOptions={this.props.titleCurrentJobFilteredOptions}
              showMoreFilterOptions={this.props.showMoreFilterOptions}
              liftUpFilteredOptions={this.props.liftUpFilteredOptions}
            />
          </div>
        ) : (
          ''
        )}
        {this.props.filters.durationCurrentJob.isSet ? (
          <div className="row text-left">
            <DurationCurrentJobFilter
              onFilterChange={this.props.onFilterChange}
              // TODO Warning: Failed prop type: Invalid prop `countedJobParameters` of type
              // `object` supplied to `DurationCurrentJobFilter`, expected an array.
              durationCurrentJob={this.props.filters.durationCurrentJob.parameters}
              countedJobParameters={this.props.countedJobParameters}
              stickyFilter
            />
          </div>
        ) : (
          ''
        )}
        {this.props.filters.workExperience.isSet ? (
          <div className="row text-left">
            <WorkExperienceFilter
              onFilterChange={this.props.onFilterChange}
              // TODO Warning: Failed prop type: Invalid prop
              workExperience={this.props.filters.workExperience.parameters}
              countedJobParameters={this.props.countedJobParameters}
              stickyFilter
            />
          </div>
        ) : (
          ''
        )}
        {this.props.filters.industrySectorName.isSet ? (
          <div className="row text-left">
            <IndustrySectorFilter
              onFilterChange={this.props.onFilterChange}
              countedJobParameters={this.props.countedJobParameters}
              industrySectorName={this.props.filters.industrySectorName.parameters}
              stickyFilter
            />
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
StickyFilterComponent.defaultProps = {};
StickyFilterComponent.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  liftUpFilteredOptions: PropTypes.func.isRequired,
  // TODO remove comment
  filters: PropTypes.shape({
    matchPercentage: PropTypes.object.isRequired,
    age: PropTypes.object.isRequired,
    willingnessToChange: PropTypes.object.isRequired,
    distance: PropTypes.object.isRequired,
    showMarked: PropTypes.object.isRequired,
    currentPosition: PropTypes.object.isRequired,
    durationCurrentJob: PropTypes.object.isRequired,
    workExperience: PropTypes.object.isRequired,
    industrySectorName: PropTypes.object.isRequired
  }).isRequired,
  countedJobParameters: PropTypes.object.isRequired,
  titleCurrentJobFilteredOptions: PropTypes.object.isRequired,
  showMoreFilterOptions: PropTypes.func.isRequired,
  numberOfShownFilterOptionForCurrentPositionFilter: PropTypes.number.isRequired
};
export default withUser(StickyFilterComponent);
