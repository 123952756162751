import { TextField, FormControlLabel, Switch } from '@material-ui/core';
import * as React from 'react';
import PropTypes from 'prop-types';

const SwitchField = ({ readOnly, value, onChange, label }) => {
  if (readOnly) {
    return (
      <TextField
        fullWidth
        label={label}
        value={value ? 'ja' : 'nein'}
        InputProps={{ readOnly: true, disableUnderline: true }}
        InputLabelProps={{
          shrink: true,
          focused: false
        }}
      />
    );
  }
  return <FormControlLabel control={<Switch value checked={!!value} onChange={ev => onChange(ev.target.checked)} />} label={label} />;
};

SwitchField.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

SwitchField.defaultProps = {
  readOnly: false,
  value: false
};

export default React.memo(SwitchField);
