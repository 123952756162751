import React from 'react';
import PropTypes from 'prop-types';
import { Paper, List } from '@material-ui/core';

export default function NotificationList({ children }) {
  return (
    <Paper>
      <List>{children}</List>
    </Paper>
  );
}

NotificationList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};
