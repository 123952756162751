import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { Link } from 'react-router-dom';

// material UI
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import WillingnessToChangeCircle from '../components/WillingnessToChangeCircle';

// components
import ConfirmationModal from '../generic_components/ConfirmationModal';

import withUser from '../utils/withUser';

import './MarkLists.css';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

const itemTypes = {
  candidateItem: 'candidate',
  companyItem: 'company',
  projectItem: 'project'
};

class MarkLists extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      savedListsCompanies: [],
      savedListsCandidates: [],
      savedListsProjects: [],
      // savedListsOrders: [],
      confirmationModalListDeletion: {
        isOpen: false,
        listId: ''
      },
      confirmationModalItemRemoval: {
        isOpen: false,
        listId: '',
        itemId: '',
        selectedForDeletionItemType: ''
      },
      isSerialMailDrawerOpen: '', // accepts markListId; isOpen === !!markListId
      // isConfirmationModalOpen: false,
      // isConfirmationModalOpenForRemovalRequest: false,
      // listIdOfDeletionRequest: '',
      employeesByPartner: []
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Merklisten - ${UTILS.getTitle()}`;
    this.loadPopulatedMarkListsAPICall();
  }

  sortListsByType(bulkMarkLists) {
    const markLists = bulkMarkLists;
    const result = {
      candidateMarkLists: [],
      companyMarkLists: [],
      projectMarkLists: []
    };
    markLists.forEach(markList => {
      if (markList.candidateMarks.length > 0) {
        result.candidateMarkLists.push(markList);
      } else if (markList.companyMarks.length > 0) {
        result.companyMarkLists.push(markList);
      } else if (markList.projectMarks.length > 0) {
        result.projectMarkLists.push(markList);
      }
    });
    return result;
  }

  loadPopulatedMarkListsAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.loadPopulatedMarkListsURL;
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(
          'API /loadPopulatedMarkListsAPICall response',
          responseBody,
          status
        );
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        const sortedMarkListsByType = this.sortListsByType(
          responseBody.allMarkListsOfUser
        );
        this.setState({
          savedListsCandidates: sortedMarkListsByType.candidateMarkLists,
          savedListsCompanies: sortedMarkListsByType.companyMarkLists,
          savedListsProjects: sortedMarkListsByType.projectMarkLists
          // isSerialMailDrawerOpen: '5c6aa783234e7b5e8893617b'
        });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  handleDeleteListAPICall = () => {
    this.setState(prevState => ({
      confirmationModalListDeletion: {
        ...prevState.confirmationModalListDeletion,
        isOpen: false
      }
    }));
    const { listId } = this.state.confirmationModalListDeletion;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.deleteMarkListURL;
    const request = { listId };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),
      timeout: CONFIG.ajaxTimeout
    })
      // .done((responseBody, status) => {
      .done(() => {
        // console.log('API /deleteMarkList response', responseBody, status);
        this.props.handleSnackbarOpen(
          TEXT.markListsPage.snackBar.success.deleteMarkList
        );
        this.loadPopulatedMarkListsAPICall();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleRemoveItemFromListAPICall = () => {
    this.setState(prevState => ({
      confirmationModalItemRemoval: {
        ...prevState.confirmationModalItemRemoval,
        isOpen: false
      }
    }));
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.removeItemFromMarkListURL;
    const { listId, itemId } = this.state.confirmationModalItemRemoval;
    const request = { listId, itemId };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(
          'API /removeItemFromMarkList response',
          responseBody,
          status
        );
        switch (
          this.state.confirmationModalItemRemoval.selectedForDeletionItemType
        ) {
          case 'candidate':
            this.props.handleSnackbarOpen(
              TEXT.markListsPage.snackBar.success.removeItemFromMarkList
            );
            break;
          case 'company':
            this.props.handleSnackbarOpen(
              TEXT.markListsPage.snackBar.success.removeCompanyFromMarkList
            );
            break;
          default:
            this.props.handleSnackbarOpen(
              TEXT.markListsPage.snackBar.success.genericText
            );
        }
        // this.props.handleSnackbarOpen(
        //   TEXT.markListsPage.snackBar.success
        // );
        this.loadPopulatedMarkListsAPICall();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleCloseConfirmationModal = () => {
    this.setState(prevState => ({
      confirmationModalListDeletion: {
        ...prevState.confirmationModalListDeletion,
        isOpen: false
      }
    }));
  };

  handleCloseConfirmationModalForRemovalRequest = () => {
    this.setState(prevState => ({
      confirmationModalItemRemoval: {
        ...prevState.confirmationModalItemRemoval,
        isOpen: false
      }
    }));
  };

  handleDeleteList = listId => {
    console.log('Delete list', listId);
    this.setState({
      confirmationModalListDeletion: {
        isOpen: true,
        listId
      }
    });
  };

  handleRemoveItemFromList = (listId, itemId, itemType) => {
    console.log(`Request removal of candidate: ${itemId} from list: ${listId}`);
    this.setState({
      confirmationModalItemRemoval: {
        isOpen: true,
        listId,
        itemId,
        selectedForDeletionItemType: itemType
      }
    });
  };

  renderMarkListItems(candidateMarks, companyMarks, projectMarks, listId) {
    if (
      candidateMarks.length === 0 &&
      companyMarks.length === 0 &&
      projectMarks.length === 0
    )
      return (
        <div className="marklist-emptyList">{TEXT.markListsPage.emptyList}</div>
      );
    const listItems = [];
    candidateMarks.forEach(candidateMark => {
      listItems.push(
        // <ListItem key={candidateMark.candidateMarkId}>
        <div
          className="marklist-listitem listItem"
          key={candidateMark.candidateMarkId}
        >
          <div className="capitalize-words row headline">
            <div className="col-12 headline-container">
              {`${candidateMark.lastName ? `${candidateMark.lastName}, ` : ''}${
                candidateMark.firstName ? `${candidateMark.firstName} - ` : ''
              }${
                candidateMark.currentJob
                  ? candidateMark.currentJob
                  : TEXT.commonTerms.NA
              }`}
            </div>
          </div>
          <div className="row details">
            <div className="col-5 matchPercentage">
              <div className="label">{`${
                TEXT.resultPage.matchPercentage
              }:`}</div>
              {UTILS.calcMatchStars(candidateMark.matchPercentage)}
            </div>

            <div className="col-5 willingnessToChange">
              <WillingnessToChangeCircle
                willingnessToChange={candidateMark.willingnessToChange}
              />
            </div>
            <div className="col-2 marklist-deleteItem">
              <Button
                onClick={() =>
                  this.handleRemoveItemFromList(
                    listId,
                    candidateMark.candidateMarkId,
                    itemTypes.candidateItem
                  )
                }
                color="secondary"
                className="deleteListButton"
              >
                <i className="material-icons pr-1">delete_outline</i>
              </Button>
            </div>
          </div>
        </div>
        // </ListItem>
      );
    });
    companyMarks.forEach(companyMark => {
      listItems.push(
        <div
          className="marklist-listitem listItem"
          key={companyMark.companyMarkId}
        >
          <div className="capitalize-words row headline">
            <div className="col-10 headline-container">
              {companyMark.companyName}
            </div>
            <div className="col-2 marklist-deleteItem">
              <Button
                onClick={() =>
                  this.handleRemoveItemFromList(
                    listId,
                    companyMark.companyMarkId,
                    itemTypes.companyItem
                  )
                }
                color="secondary"
                className="deleteListButton"
              >
                <i className="material-icons pr-1">delete_outline</i>
              </Button>
            </div>
          </div>
        </div>
      );
    });
    projectMarks.forEach(projectMark => {
      listItems.push(
        <div
          className="marklist-listitem listItem"
          key={projectMark.projectMarkId}
        >
          <div className="capitalize-words row headline">
            <div className="col-12 headline-container">
              {projectMark.companyName}
            </div>
            <div className="col-6 headline-container">{projectMark.title}</div>
            <div className="col-4 headline-container">
              {projectMark.projectNumber}
            </div>
            <div className="col-2 marklist-deleteItem">
              <Button
                onClick={() =>
                  this.handleRemoveItemFromList(
                    listId,
                    projectMark.projectMarkId,
                    itemTypes.projectItem
                  )
                }
                color="secondary"
                className="deleteListButton"
              >
                <i className="material-icons pr-1">delete_outline</i>
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return listItems;
  }

  renderDetailsButton(markListType, listId) {
    let button;
    switch (markListType) {
      case 'savedListsCandidates':
        button = (
          <Button
            onClick={() => {
              this.props.liftUpStateToApp({
                searchArray: [listId],
                resultsCalledFrom: 'markLists'
              });
            }}
            component={Link}
            to="/results"
            color="secondary"
            className="deleteListButton"
            style={{ fontSize: '13px' }}
            variant="contained"
            fullWidth
          >
            <i className="material-icons pr-1">info</i>
            {TEXT.markListsPage.candidateDetailsButton}
          </Button>
          // <Button
          //   onClick={() => {
          //     this.props.liftUpStateToApp({
          //       searchArray: [listId],
          //       resultsCalledFrom: 'markLists'
          //     });
          //   }}
          //   component={Link}
          //   to="/results"
          //   color="secondary"
          //   className="deleteListButton"
          //   style={{ fontSize: '13px' }}
          //   variant="contained"
          //   fullWidth
          // >
          //   <i className="material-icons pr-1">info</i>
          //   {TEXT.markListsPage.candidateDetailsButton}
          // </Button>
        );
        break;
      case 'savedListsCompanies':
        button = (
          <Button
            onClick={() => {
              this.props.liftUpStateToApp({
                companyMarkListId: listId
              });
            }}
            component={Link}
            to="/companies"
            color="secondary"
            className="deleteListButton"
            style={{ fontSize: '13px' }}
            variant="contained"
          >
            <i className="material-icons pr-1">info</i>
            {TEXT.markListsPage.companyDetailsButton}
          </Button>
        );
        break;
      case 'savedListsProjects':
        button = (
          <Button
            onClick={() => {
              this.props.liftUpStateToApp({
                projectMarkListId: listId
              });
            }}
            component={Link}
            to="/projects"
            color="secondary"
            className="deleteListButton"
            style={{ fontSize: '13px' }}
            variant="contained"
          >
            <i className="material-icons pr-1">info</i>
            {TEXT.markListsPage.projectDetailsButton}
          </Button>
        );
        break;
      default:
    }
    return button;
  }

  renderButtonArea(markListType, listId) {
    return (
      <div className="row text-left pb-3">
        <div className="col-3 text-center">
          {this.renderDetailsButton(markListType, listId)}
        </div>
        <div className="col-3 text-center">
          <Button
            id={listId}
            key={listId}
            onClick={() => this.handleDeleteList(listId)}
            color="secondary"
            className="deleteListButton"
            style={{ fontSize: '13px' }}
          >
            <i className="material-icons pr-1">delete_outline</i>
            {TEXT.markListsPage.deleteListButton}
          </Button>
        </div>
      </div>
    );
  }

  renderMarkLists(markListType) {
    let markListsForRender = [];
    if (markListType === 'savedListsCandidates') {
      markListsForRender = this.state.savedListsCandidates;
    } else if (markListType === 'savedListsCompanies') {
      markListsForRender = this.state.savedListsCompanies;
    } else if (markListType === 'savedListsProjects') {
      markListsForRender = this.state.savedListsProjects;
    }
    const sortedLists = markListsForRender;

    return (
      <div>
        {sortedLists.length > 0 ? (
          <div>
            {sortedLists.map(list => {
              const {
                name: listName,
                markListId: listId,
                candidateMarks,
                companyMarks,
                projectMarks
              } = list;
              return (
                <ExpansionPanel key={listId}>
                  <ExpansionPanelSummary
                    expandIcon={<i className="material-icons">expand_more</i>}
                  >
                    {listName}
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className="ExpansionPanelClass">
                    <div className="markListsPage-listContent-container pt-0">
                      <div className="container markListsPage-listContent-button mt-0">
                        {this.renderButtonArea(markListType, listId)}
                        <div className="row">
                          <div className="col-12">
                            {this.renderMarkListItems(
                              candidateMarks,
                              companyMarks,
                              projectMarks,
                              listId
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </div>
        ) : (
          <div className="marklist-noListsFound">
            {TEXT.markListsPage.noListsExisting}
          </div>
        )}
      </div>
    );
  }

  renderListCategories() {
    const result = [];
    const categories = TEXT.markListsPage.categoryList;
    categories.forEach(listType => {
      const key = Object.keys(listType)[0];
      const value = listType[key];
      if (this.state[key] !== undefined) {
        if (this.state[key].length > 0) {
          result.push(
            <div className="p-3 mb-3" key={`list${key}`}>
              <Typography variant="h6" component="h6" className="pb-3">
                {value}
              </Typography>
              {this.renderMarkLists(key)}
            </div>
          );
        }
      }
    });
    if (result.length === 0)
      result.push(
        <div className="marklist-emptyList" key="no-list-available">
          {TEXT.markListsPage.noListsAvailable}
        </div>
      );
    return result;
  }

  genereateConnfirmationMalText(itemType) {
    // console.log('______itemType', itemType);
    let result = '';
    switch (itemType) {
      case 'candidate':
        result = TEXT.markListsPage.removeItemConfirmationModul;
        break;
      case 'company':
        result = TEXT.markListsPage.removeCompanyConfirmationModul;
        break;
      case 'project':
        result = TEXT.markListsPage.removeProjectConfirmationModul;
        break;
      default:
        break;
    }
    return result;
  }

  render() {
    return (
      <div className="markListsPage h-100vh">
        <div className="markListsPage-listOverview-container">
          {this.renderListCategories()}
          <ConfirmationModal
            key="confirmation-list-deletion"
            handleClose={this.handleCloseConfirmationModal}
            open={this.state.confirmationModalListDeletion.isOpen}
            buttonPrimaryAction={this.handleDeleteListAPICall}
            headlineText={TEXT.markListsPage.deletionConfirmationModul.headline}
            decriptionText={
              TEXT.markListsPage.deletionConfirmationModul.description
            }
          />
          <ConfirmationModal
            key="confirmation-item-removal-from-list"
            handleClose={this.handleCloseConfirmationModalForRemovalRequest}
            open={this.state.confirmationModalItemRemoval.isOpen}
            buttonPrimaryAction={this.handleRemoveItemFromListAPICall}
            headlineText={
              this.genereateConnfirmationMalText(
                this.state.confirmationModalItemRemoval
                  .selectedForDeletionItemType
              ).headline
            }
            decriptionText={
              this.genereateConnfirmationMalText(
                this.state.confirmationModalItemRemoval
                  .selectedForDeletionItemType
              ).description
            }
            buttonPrimaryText={
              this.genereateConnfirmationMalText(
                this.state.confirmationModalItemRemoval
                  .selectedForDeletionItemType
              ).buttonPrimaryText
            }
            // itemType={this.state.confirmationModalItemRemoval.sel}
          />
        </div>
      </div>
    );
  }
}
MarkLists.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
  // user: PropTypes.object.isRequired
};
export default withUser(MarkLists);
