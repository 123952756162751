import * as React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Button, CircularProgress, Container, Divider, Typography, IconButton, Tooltip } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import ResponsivePaper from '../generic_components/ResponsivePaper';
import ProjectStatus from './profile/ProjectStatus';
import useLoading from '../utils/useLoading';
import { setURLParam } from '../utils/utilFunctions';
import DrawerWrapper from '../generic_components/DrawerWrapper';

import { getProjectCandidateList, getProjectById, updateProject } from '../api/projects';

function SingleProjectSummaryDrawer({ project, onClose, open, updateSelectedProject, history }) {
  const [candidateList, setCandidateList] = React.useState();
  const [loading, wrapAsyncLoading] = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const projectReleaseUpdate = async () => {
    try {
      let updatedProject = await getProjectById(project.id);

      await updateProject(project.id, { status: 'In Bearbeitung' });
      updatedProject = await getProjectById(project.id);

      updateSelectedProject(updatedProject);
      history.push('/myprojects');
      setURLParam(history, 'pid', project.id);
      onClose();
    } catch (err) {
      if (err.status === 409) {
        enqueueSnackbar('http status 409 error');
      } else {
        throw err;
      }
    }
  };

  const loadCandidateList = async () => {
    setCandidateList((await getProjectCandidateList(project.id)).candidates);
  };

  React.useEffect(
    wrapAsyncLoading(async () => {
      if (open === true) {
        await loadCandidateList();
      }
    }),
    [open]
  );
  function projectIsReleasedStatus() {
    return project.status === 'In Bearbeitung';
  }

  function checkIfDisabled() {
    if (projectIsReleasedStatus()) return !!(candidateList && candidateList.filter(candidate => candidate.status === 'added').length < 1);
    return !!(candidateList && candidateList.length < 10);
  }

  function renderHeadline() {
    return (
      <>
        <Typography variant="h6" component="h6">
          {`Status: ${project.status}`}
          <Tooltip title={`Zum Auftrag '${project.title}'`}>
            <IconButton
              color="primary"
              onClick={() => {
                history.push('/myprojects');
                setURLParam(history, 'pid', project.id);
                onClose();
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </>
    );
  }

  function renderReleaseProjectButton() {
    return (
      <Box mt={3}>
        <Typography component="div" align="right">
          <Tooltip title="Bitte fügen Sie mindestens 10 Kandidaten zu Ihrem Auftrag hinzu" placement="bottom">
            <span>
              {/* Workaround for tooltips on disabled buttons  */}
              <Button
                variant="contained"
                color="primary"
                disabled={checkIfDisabled()}
                onClick={projectReleaseUpdate}
                style={{ marginLeft: '20px' }}
              >
                Kandidaten freigeben
              </Button>
            </span>
          </Tooltip>
        </Typography>
      </Box>
    );
  }

  return (
    <DrawerWrapper title={project.title} open={open} onClose={onClose}>
      <Container fixed>
        <ResponsivePaper className="my-3">
          {renderHeadline()}

          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <ProjectStatus projectId={project.id} candidateList={candidateList} showChart onUpdate={loadCandidateList} />
          )}

          <Box mt={1} mb={1}>
            <Divider />
          </Box>

          {renderReleaseProjectButton()}
        </ResponsivePaper>
      </Container>
    </DrawerWrapper>
  );
}

SingleProjectSummaryDrawer.propTypes = {
  history: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updateSelectedProject: PropTypes.func.isRequired
};

export default withRouter(SingleProjectSummaryDrawer);
