import { FormControl, InputLabel, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import * as React from 'react';
import PropTypes from 'prop-types';

const CheckBoxField = ({ readOnly, value, onChange, optionList, label }) => {
  if (readOnly) {
    return (
      <TextField
        label={label}
        required
        value={value || ''}
        InputProps={{ readOnly: true, disableUnderline: true }}
        InputLabelProps={{
          shrink: true,
          focused: false
        }}
      />
    );
  }
  return (
    <FormControl fullWidth className="flex-row">
      <InputLabel shrink>{label}</InputLabel>
      {optionList.map(option => (
        <FormControlLabel
          key={option}
          style={{ marginTop: 8, marginBottom: -8 }}
          control={
            <Checkbox
              checked={value.includes(option)}
              onChange={() => onChange(value.includes(option) ? value.filter(o => o !== option) : [...value, option])}
              value={option}
              className=""
            />
          }
          label={option}
        />
      ))}
    </FormControl>
  );
};

CheckBoxField.propTypes = {
  readOnly: PropTypes.bool,
  value: PropTypes.array,
  optionList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

CheckBoxField.defaultProps = {
  readOnly: false,
  value: []
};

export default React.memo(CheckBoxField);
