import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Container, Grid, Typography } from '@material-ui/core';

import { getNotificationList } from '../api/notifications';
import useLoading from '../utils/useLoading';
import NotificationList from '../components/notification_components/NotificationList';
import NotificationCategory from '../components/notification_components/NotificationCategory';
import NotificationItem from '../components/notification_components/NotificationItem';

import DialogWrapper from '../generic_components/DialogWrapper';
import CandidateDetails from '../components/profile/CandidateDetails';
import * as UTILS from '../utils/utilFunctions';

function getYesterdayDate() {
  return new Date(new Date().setDate(new Date().getDate() - 1));
}

function getLastWeekDate() {
  return new Date(new Date().setDate(new Date().getDate() - 7));
}

export default function NotificationPage({ handleNotificationCountUpdate }) {
  const [notificationList, setNotificationList] = React.useState();
  const [loading, wrapAsyncLoading] = useLoading(true);
  const [selectedCandidateId, setSelectedCandidateId] = React.useState();
  const [selectedProjectId, setSelectedProjectId] = React.useState();

  const waitingForUpdate = (
    <Grid item xs={12}>
      <Box p={6}>
        <Typography align="center" component="div">
          <CircularProgress color="primary" size={140} thickness={2.2} />
        </Typography>
      </Box>
    </Grid>
  );

  React.useEffect(
    wrapAsyncLoading(async () => {
      const apiResponse = await getNotificationList();
      setNotificationList(apiResponse.notificationList);
      document.title = `Benachrichtigungen - ${UTILS.getTitle()}`;
    }),
    []
  );

  const notificationCategoryList = [
    {
      title: 'Letzte 24 Stunden',
      timeSpan: 'short',
      timeSpanFilter: notification => new Date(notification.triggerDate) > getYesterdayDate()
    },
    {
      title: 'Letzte 7 Tage',
      timeSpan: 'mid',
      timeSpanFilter: notification =>
        new Date(notification.triggerDate) < getYesterdayDate() && new Date(notification.triggerDate) > getLastWeekDate()
    },
    {
      title: 'Älter als 7 Tage',
      timeSpan: 'overdue',
      timeSpanFilter: notification => new Date(notification.triggerDate) < getLastWeekDate()
    }
  ];

  function renderNotificationCategoryList() {
    return notificationCategoryList.map(category => {
      const filteredList = notificationList.filter(category.timeSpanFilter);
      return (
        <NotificationCategory key={category.timeSpan} title={category.title}>
          <NotificationList>
            {filteredList.length > 0 ? (
              filteredList.map(entry => (
                <NotificationItem
                  key={entry.id}
                  title="Ein Dossier wurde bereitgestellt"
                  description={`für den Auftrag "${entry.notificationObject.candidateDossierReleased.project.projectName}"`}
                  isViewed={entry.isRead}
                  dueDate={new Date(entry.triggerDate)}
                  notificationId={entry.id}
                  projectId={entry.notificationObject.candidateDossierReleased.project.id}
                  showCandidateDetails={() => {
                    setSelectedCandidateId(entry.notificationObject.candidateDossierReleased.candidate);
                    setSelectedProjectId(entry.notificationObject.candidateDossierReleased.project.id);
                  }}
                  handleNotificationCountUpdate={handleNotificationCountUpdate}
                />
              ))
            ) : (
              <Box p={2}>
                <Typography variant="subtitle2" component="div" align="center">
                  Keine Benachrichtigungen vorhanden
                </Typography>
              </Box>
            )}
          </NotificationList>
        </NotificationCategory>
      );
    });
  }

  return (
    <>
      <Container maxWidth="lg">
        <Box p={6}>
          <Grid container spacing={1}>
            {loading ? waitingForUpdate : renderNotificationCategoryList()}
          </Grid>
        </Box>
      </Container>
      <DialogWrapper
        dialogTitle="Kandidaten-Details"
        open={!!selectedCandidateId && !!selectedProjectId}
        cancelButtonName="Schließen"
        maxWidth="md"
        handleClose={() => setSelectedCandidateId(undefined)}
      >
        <CandidateDetails showRatings={false} projectId={selectedProjectId} candidateId={selectedCandidateId} />
      </DialogWrapper>
    </>
  );
}

NotificationPage.propTypes = {
  handleNotificationCountUpdate: PropTypes.func.isRequired
};
