// Important: no !important in css
const theme = {
  matchflix: {
    name: 'Match-flix',
    login: {
      typography: {
        useNextVariants: true
      },
      palette: {
        type: 'dark',
        primary: {
          main: '#0079b0',
          light: '#74c3e6',
          contrastText: '#b3b3b3'
        }
      }
    },
    app: {
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#39a9dc',
          light: '#74c3e6',
          contrastText: '#fff'
        },
        secondary: { main: '#00a69f' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS

            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    logo: '/img/logo_header3.png',
    loginLogo: '/img/mf_logo.png',
    loginBackground: {
      background: '#485258'
    },
    loginPaper: {
      background: '#000'
    },
    appBar: {
      background: '#39a9dc'
    }
  },
  senator: {
    name: 'Senator-Partners',
    login: {
      palette: {
        primary: {
          main: '#0079b0',
          light: '#0079b0',
          contrastText: '#666'
        }
      }
    },
    app: {
      palette: {
        primary: {
          main: '#587daf',
          light: '#89a3c6',
          contrastText: '#4b575f'
        },
        secondary: { main: '#00a69f' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS

            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    logo: '/img/senator_logo.png',
    loginLogo: '/img/senator_logo.png',
    loginBackground: {
      background: '#747e86'
    },
    loginPaper: {
      background: '#FFF'
    },
    appBar: {
      background: '#fff'
    }
  },
  personaltotal: {
    name: 'Personal-Total',
    login: {
      palette: {
        type: 'dark',
        primary: {
          main: '#962225',
          light: '#fff',
          contrastText: '#fff'
        }
      }
    },
    app: {
      palette: {
        primary: {
          main: '#962225',
          light: '#b46365',
          contrastText: '#fff'
        },
        secondary: { main: '#00a69f' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS
            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    logo: '/img/pt_logo.png',
    loginLogo: '/img/pt_logo.png',
    loginBackground: {
      background: '#5f6467'
    },
    appBar: {
      background: '#962225'
    },
    loginPaper: {
      background: '#962225'
    }
  }
};
module.exports = theme;
