import { performGet } from './apiUtils';

export async function getNotificationList() {
  return performGet('/matchflix/notifications');
}

export async function getNotificationCount() {
  return performGet('/matchflix/notifications/count');
}

export async function markNotificationAsViewed(notificationId) {
  return performGet(`/matchflix/notifications/${notificationId}/markAsViewed`);
}
