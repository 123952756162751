import * as React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepButton, Button } from '@material-ui/core';
import IvaGuidance from './IvaGuidance';
import useLoading from '../../utils/useLoading';

const ProjectDetailsStepper = ({ children: steps, flatten, onSave }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxStep, setMaxStep] = React.useState(0);
  const [saving, wrapAsyncSaving] = useLoading();
  const doSave = wrapAsyncSaving(onSave);

  const currentStep = steps[activeStep];
  if (flatten) {
    return (
      <form
        onSubmit={ev => {
          ev.preventDefault();
          if (!steps.find(step => step.props.onValidate && !step.props.onValidate())) {
            doSave();
          }
        }}
      >
        {steps.map(step => (
          <div key={step.props.title}>
            <fieldset className="mb-4">
              <legend>{step.props.title}</legend>
              {step}
            </fieldset>
          </div>
        ))}
        <div className="text-right mt-3">
          <Button variant="contained" color="primary" type="submit" disabled={saving}>
            Speichern
          </Button>
        </div>
      </form>
    );
  }
  return (
    <>
      <Stepper nonLinear alternativeLabel activeStep={activeStep}>
        {steps.map(({ props: { title } }, index) => (
          <Step key={title} title={index > activeStep ? 'Navigation ist nur rückwärts möglich' : ''}>
            <StepButton
              onClick={() => setActiveStep(index)}
              completed={maxStep > index && index !== activeStep}
              disabled={index > activeStep}
            >
              {title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <IvaGuidance>{currentStep.props.guidance}</IvaGuidance>
      <form
        onSubmit={ev => {
          ev.preventDefault();
          if (currentStep.props.onValidate && !currentStep.props.onValidate()) {
            return;
          }
          if (activeStep === steps.length - 1) {
            doSave();
          } else {
            setMaxStep(activeStep + 1);
            setActiveStep(activeStep + 1);
          }
        }}
      >
        {currentStep}
        <div className="text-right mt-3">
          {activeStep > 0 && (
            <Button
              color="primary"
              onClick={ev => {
                ev.preventDefault();
                setActiveStep(activeStep - 1);
              }}
            >
              Zurück
            </Button>
          )}
          <Button type="submit" variant="contained" color="primary" className="ml-2" disabled={saving}>
            {activeStep === steps.length - 1 ? 'Speichern' : 'Weiter'}
          </Button>
        </div>
      </form>
    </>
  );
};

ProjectDetailsStepper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  flatten: PropTypes.bool,
  onSave: PropTypes.func.isRequired
};

ProjectDetailsStepper.defaultProps = {
  flatten: false
};

export default ProjectDetailsStepper;
