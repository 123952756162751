import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import {
  Drafts as DraftsIcon,
  Mail as MailIcon,
  Notifications as NotificationIcon,
  OpenInNew as OpenInNewIcon,
  HowToReg as HowToRegIcon
} from '@material-ui/icons';

import { formatDateToDELocaleTime, setURLParam } from '../../utils/utilFunctions';
import { markNotificationAsViewed } from '../../api/notifications';

function NotificationItem({
  title,
  description,
  isViewed,
  dueDate,
  notificationId,
  history,
  projectId,
  showCandidateDetails,
  handleNotificationCountUpdate
}) {
  const [mailIconLoading, setMailIconLoading] = React.useState(false);
  const [localIsViewed, setLocalIsViewed] = React.useState(isViewed);

  const formatTitle = (
    <Typography component="span">
      <Typography variant="subtitle2" component="span">
        {title}
      </Typography>{' '}
      <Typography variant="body2" component="span">
        {description}
      </Typography>
    </Typography>
  );

  function markAsViewed() {
    setMailIconLoading(true);
    markNotificationAsViewed(notificationId).then(responseApi => {
      setMailIconLoading(false);
      handleNotificationCountUpdate(responseApi.notificationCount);
    });
  }

  function renderMarkAsViewedButton() {
    return mailIconLoading ? (
      <IconButton>
        <CircularProgress size={30} />{' '}
      </IconButton>
    ) : (
      <Tooltip title="Benachrichtigung als gelesen markieren" placement="top">
        <IconButton
          aria-label="mark_unread"
          disabled={localIsViewed}
          color="secondary"
          onClick={
            localIsViewed
              ? undefined
              : () => {
                  markAsViewed();
                  setLocalIsViewed(true);
                }
          }
        >
          {localIsViewed ? <DraftsIcon /> : <MailIcon />}
        </IconButton>
      </Tooltip>
    );
  }

  function renderRedirectToMyProjectsButton() {
    return (
      <Tooltip title="Projekt in 'Meine Aufträge' betrachten" placement="top">
        <IconButton
          color="secondary"
          onClick={() => {
            if (!localIsViewed) {
              markAsViewed();
            }
            history.push('/myprojects');
            setURLParam(history, 'pid', projectId);
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      </Tooltip>
    );
  }

  function renderShowCandidateDetailsButton() {
    return (
      <Tooltip title="Kandidaten Details Anzeigen" placement="top">
        <IconButton
          color="secondary"
          onClick={() => {
            if (!localIsViewed) {
              markAsViewed();
              setLocalIsViewed(true);
            }
            showCandidateDetails();
          }}
        >
          <HowToRegIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <NotificationIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={formatTitle} secondary={formatDateToDELocaleTime(new Date(dueDate))} />
      <ListItemSecondaryAction>
        {renderShowCandidateDetailsButton()}
        {renderRedirectToMyProjectsButton()}
        {renderMarkAsViewedButton()}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isViewed: PropTypes.bool.isRequired,
  dueDate: PropTypes.instanceOf(Date).isRequired,
  notificationId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  showCandidateDetails: PropTypes.func.isRequired,
  handleNotificationCountUpdate: PropTypes.func.isRequired
};

export default withRouter(NotificationItem);
