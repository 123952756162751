import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IconButton, MenuItem, Menu } from '@material-ui/core';

class MobileOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  exitMenuOnClick(handler) {
    handler();
    this.setState({ anchorEl: null });
  }

  render() {
    return (
      <>
        <IconButton
          aria-owns={this.state.anchorEl ? 'menu-mobile-options' : null}
          aria-haspopup="true"
          onClick={event => {
            this.setState({
              anchorEl: event.currentTarget
            });
          }}
          color="inherit"
        >
          <i className="material-icons">assessment</i>
        </IconButton>
        <Menu
          id="menu-mobile-options"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.setState({ anchorEl: null });
          }}
        >
          {this.props.isInPageContext(['/search']) && (
            <MenuItem
              onClick={() => {
                this.exitMenuOnClick(this.props.resetSearch);
              }}
            >
              <i className="material-icons">autorenew</i>
              &nbsp; Neue suche
            </MenuItem>
          )}
          {this.props.isInPageContext(['/results']) && (
            <MenuItem
              component={Link}
              to="/search"
              onClick={() => {
                this.exitMenuOnClick(this.props.setMarkedCount);
              }}
            >
              <i className="material-icons">search</i>
              &nbsp; Suche anpassen
            </MenuItem>
          )}
          {this.props.isInPageContext(['/results']) && (
            <MenuItem
              onClick={() => {
                this.exitMenuOnClick(this.props.handleSaveSearchBoxOpen);
              }}
            >
              <i className="material-icons">save</i>
              &nbsp; Suche speichern
            </MenuItem>
          )}
          {this.props.isInPageContext(['/search', '/results']) && (
            <MenuItem
              onClick={() => {
                this.exitMenuOnClick(this.props.handleLoadSearchBoxOpen);
              }}
            >
              <i className="material-icons">open_in_browser</i>
              &nbsp; Suche laden
            </MenuItem>
          )}
        </Menu>
      </>
    );
  }
}

MobileOptions.propTypes = {
  isInPageContext: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  handleLoadSearchBoxOpen: PropTypes.func.isRequired,
  handleSaveSearchBoxOpen: PropTypes.func.isRequired,
  setMarkedCount: PropTypes.func.isRequired
};

export default MobileOptions;
