import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material ui
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash/debounce';

import * as UTILS from '../utils/utilFunctions';
import { geoLocationLookUp } from '../api/geoLocation';

class PostalCodeAutosuggest2 extends PureComponent {
  handleSuggestionsFetchRequested = debounce(({ value }) => {
    this.getSuggestions(value);
  }, 300); // 500

  constructor(props) {
    super(props);
    this.state = { suggestions: [], searchValue: '', focused: false };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getSuggestionValue = suggestion =>
    suggestion ? `${suggestion.postalCode} -- ${suggestion.city} -- ${this.countryNameMap(suggestion.country)}` : '';

  countryNameMap(country) {
    switch (country) {
      case 'germany':
        return 'Deutschland';
      case 'switzerland':
        return 'Schweiz';
      case 'austria':
        return 'Österreich';
      default:
        return country;
    }
  }

  getSuggestions = value => {
    geoLocationLookUp(value)
      .then(response => {
        this.setState({
          suggestions: response
        });
      })
      .catch(this.errorHandling);
  };

  renderInput = inputProps => {
    const { ref, type, className, ...props } = inputProps || {};
    let errorProps = {};
    if (this.state.searchValue && !this.state.focused) {
      errorProps = {
        error: true,
        helperText: 'Keine gültige Eingabe'
      };
    }
    return (
      <TextField
        required={this.props.required}
        fullWidth
        label={this.props.label}
        placeholder={this.props.placeholder}
        inputRef={ref}
        {...props}
        {...errorProps}
        InputProps={
          this.props.readOnly
            ? {
                readOnly: true,
                disableUnderline: true
              }
            : undefined
        }
        InputLabelProps={{
          shrink: this.props.value || this.props.readOnly ? true : undefined,
          focused: this.props.readOnly ? false : undefined
        }}
      />
    );
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(this.getSuggestionValue(suggestion), query);
    const parts = parse(this.getSuggestionValue(suggestion), matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children }) => (
    <Paper className="suggestionBOX" {...containerProps}>
      {children}
    </Paper>
  );

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <Autosuggest
          renderInputComponent={this.renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={() =>
            this.setState({
              suggestions: []
            })
          }
          onSuggestionSelected={(e, { suggestion }) => {
            this.setState({ searchValue: '' });
            this.props.onChange(suggestion);
          }}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          focusInputOnSuggestionClick={false}
          inputProps={{
            onChange: (e, { newValue }) => this.setState({ searchValue: newValue }),
            onFocus: () => {
              if (!this.props.readOnly) this.setState({ searchValue: '', focused: true });
            },
            onBlur: () => this.setState({ focused: false }),
            value: this.state.focused ? this.state.searchValue : this.getSuggestionValue(this.props.value)
          }}
        />
      </div>
    );
  }
}

PostalCodeAutosuggest2.defaultProps = {
  value: undefined,
  label: undefined,
  required: false,
  readOnly: false
};

PostalCodeAutosuggest2.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool
};

export default PostalCodeAutosuggest2;
