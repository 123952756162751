import { readToken } from '../utils/tokenStorage';
import { baseUrl } from '../constants';

class HttpError extends Error {
  constructor(message, status, url) {
    super(message);
    this.status = status;
    this.url = url;
  }
}

function doFetch(relativeUrl, payload, method) {
  const init = optionsForPayload(payload);
  const fetchInit = {
    ...init,
    method: init.method || method,
    headers: { ...(init || {}).headers, 'x-auth': readToken() }
  };
  return fetch(`${baseUrl}${relativeUrl}`, fetchInit).then(res => {
    if (!res.ok) {
      if ((res.headers.get('content-type') || '').includes('application/json')) {
        return res.json().then(body => {
          throw new HttpError(body.error || body.message || res.statusText, res.status, res.url);
        });
      }
      throw new HttpError(res.statusText, res.status, res.url);
    }
    return res.text().then(data => (data ? JSON.parse(data) : undefined));
  });
}

function optionsForPayload(payload) {
  if (!payload) {
    return {};
  }
  if (payload instanceof FormData) {
    return { body: payload };
  }
  if (payload instanceof File || Array.isArray(payload)) {
    return optionsForFile(payload);
  }
  return optionsForJson(payload);
}

function optionsForJson(payload) {
  return {
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(payload)
  };
}

function optionsForFile(payload) {
  const formData = new FormData();
  if (Array.isArray(payload)) {
    payload.forEach((file, idx) => formData.append(`file-${idx}`, file));
  } else {
    formData.append('file-0', payload);
  }
  return {
    body: formData
  };
}

export function performGet(url) {
  return doFetch(url);
}

export function performPost(url, payload) {
  return doFetch(url, payload, 'POST');
}

export function performPut(url, payload) {
  return doFetch(url, payload, 'PUT');
}

export function performPatch(url, payload) {
  return doFetch(url, payload, 'PATCH');
}

export function performDelete(url, payload) {
  return doFetch(url, payload, 'DELETE');
}
